import styled from "styled-components";

export const HeaderContainer = styled.div`
  .promotionTab {
    display: none;
  }
  .mob-menu-icon {
    display: none;

    @media (max-width: 991px) {
      display: block;
    }
  }
  @media (max-width: 991px) {
    .logo-header {
      margin-left: 0px;
    }
    .promotionTab {
      display: block;
    }
    .auth-btn {
      padding: 5px 20px;
    }
  }
  @media (max-width: 767px) {
    .auth-btn {
      padding: 4px 20px;
      font-size: 10px;
      line-height: 20px;
    }
  }
  @media (max-width: 624px) {
    .web-btn {
      display: none;
    }
  }
`;

export const SidebarContainer = styled.div`
  .sidebar-toggle-btn {
    display: block;

    @media (max-width: 991px) {
      display: none;
    }
  }
  .sidebar-wrap {
    @media (max-width: 991px) {
      transform: translate(0);
      width: 300px;
      display: block;
      &.sidebar-close {
        display: none;
      }
    }
  }
`;
export const PromotionWrapper = styled.div`
  img {
    width: 100%;
  }

  .content-promo:nth-child(odd) h3 {
    float: right;
    text-align: right;
  }

  .content-promo :nth-child(odd) p {
    display: table;
    width: 100%;
    text-align: right;
  }
  && {
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  p {
    color: #cecece;
    display: table;
    width: 100%;
  }

  h6.promotion_info {
    color: #cecece;
    display: table;
    width: 100%;
    font-size: 20px;
    margin: 0;
    padding: 1rem;
  }
  .static-title {
    font-size: 48px;
    font-weight: bold;
    padding: 34px 0px 10px 0px;
    color: #fff;
    text-align: center;
    text-transform: capitalize;
  }
  .about-div-img {
    // height: 250px;
    width: 350px;
    margin: 0 1vw;
  }
`;
