import * as Actions from "../actions/actionTypes";

export const initialState = {
  bannerImages: [],
  loadingBanners: true,
  findMore: false,
  selectedPromo: {},
};

const promotionReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_BANNER: {
      return {
        ...state,
        bannerImages: action.payload,
        loadingBanners: false,
      };
    }

    case Actions.SET_SELECTED_PROMOTION: {
      return {
        ...state,
        selectedPromo: action.payload,
      };
    }

    case Actions.CLEAR_SELECTED_PROMOTION: {
      return {
        ...state,
        selectedPromo: {},
      };
    }

    default:
      return state;
  }
};

export default promotionReducer;
