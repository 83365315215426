import React, { useEffect } from "react";
import "../assets/css/index.css";
import "../assets/css/responsive.css";
import { useDispatch, useSelector } from "react-redux";
import Auth from "../store/Auth";
import AdminRoutes from "./AdminRoutes";
import UserRoutes from "./UserRoutes";
import * as Actions from "store/actions/actionTypes/user";
import ChatModule from "Desktop/HelpSection/ChatModule";

function DesktopRoutes(props) {
  const slug = useSelector((state) => state.general.slug);
  const admin = useSelector((state) => state.general.admin);
  const checkFromLocalStorage = localStorage.getItem("slug");
  const adminName = localStorage.getItem("adminName");
  const slugCases = checkFromLocalStorage || slug || admin || adminName;
  const path = window.location.pathname;
  const dispatch = useDispatch();

  let authed =
    localStorage.getItem("jwt_access_token") ||
    sessionStorage.getItem("jwt_access_token")
      ? true
      : false;
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const isChatConnected = useSelector((state) => state.user.isChatConnected);

  useEffect(() => {
    const pathList = path.split("/");
    const status = pathList.includes("off-market");
    dispatch({ type: Actions.SET_HOME_ACTIVE, status: status });
  }, [path]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const affiliateCode = params?.get("affiliate_code");
    if (affiliateCode) {
      localStorage.setItem("affiliateCode", affiliateCode);
    }
  }, []);

  return (
    <>
      <Auth>
        {slugCases ? <AdminRoutes slug={slugCases} /> : <UserRoutes />}
        
        {(authed || isLoggedIn) ? <ChatModule /> : isChatConnected ?  <ChatModule /> : null}
      </Auth>

    </>
  );
}

export default DesktopRoutes;
