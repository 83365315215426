import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeOffmarketCreditModal,
  closeOffmarketDepositModal,
  getOffMarketGames,
  offmarketDeposit,
  hasGameApiOffmarketDeposit,
  offmarketDepositeStatusChecker,
  offmarketWithdrawal,
  openOffmarketChat,
  openOffmarketCreditModal,
  setSubmitTransactionStatus,
  submitTransaction,
} from "store/actions/offmarket.actions";
import { CircularProgress } from "@material-ui/core";
import Util from "helper/Util";
import { toastr } from "react-redux-toastr";
import OffmarketModal from "./OffmarketModal";
import ConfirmationPopup from "./ConfirmationPopup";
import {
  getAdsAndSponsers,
  getCasinoBanners,
} from "store/actions/general.actions";
// import Advertisements from "Desktop/Advertisements/index";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Translate } from "localization/index";
import styled from "styled-components";
import { PlayButton } from "icons/index";
import axios from "axios";
import { apiConfig } from "../../config";



const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 0;
  margin: 5rem auto;
  max-width:65%;
  @media (max-width: 968px) {
    flex-direction: column;
  }
`

const StepItemWrapper = styled.div`
  margin: 0;
  padding: 0;
  position: relative;
  ::before{
    position: absolute;
    content: '${props => props.label}';
    color: #FFF;
    font-size: 24px;
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    bottom: -4rem;
    width: 25rem;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
  }
  ::after{
    position: absolute;
    content: '${props => props.desc}';
    color: #FFF;
    font-size: 16px;
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    bottom: -8.5rem;

    width: 21rem;
    left: 50%;
    text-align: center;
    opacity: 0.8;
    transform: translateX(-50%);
  }

  @media (max-width: 968px) {
  ::after{
    bottom: -8rem;
  }

  }
  @media screen and (min-width: 1700px) {
  ::after{
    width: 16rem;
  }
}
`

const ConnectorLine = styled.div`
  height: 2px;
  width: 100%;
  background: var(--yellow);
  filter: drop-shadow(0px 0px 8px rgba(237, 201, 75, 0.25));
  @media (max-width: 968px) {
    width: 2px;
    height: 6rem;
    margin-top: 9rem;
  }
`

const GlowRing = styled.div`
  height: 96px;
  width: 96px;
  flex-shrink: 0;
  border-radius: 50%;
  border: 2px solid var(--yellow);
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0px 0px 8px 6px rgba(237, 201, 75, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  margin:0;
`

const Circle = styled.div`
width: 72px;
height: 72px;
flex-shrink: 0;
border-radius: 50%;
background: radial-gradient(81.13% 81.13% at 50% 50%, #EDC94B 0%, #F45A31 100%);
display: flex;
justify-content: center;
align-items: center;
`
 const StepNumber = styled.h1`
 color: var(--brown);
 font-size: 48px;
 font-style: normal;
 font-weight: 700;
 line-height: 0;
 margin: 0;
`

const TitleTextWrapper = styled.div`
  max-width: 90%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  gap: 1rem;
  align-items: center;
  @media screen and (min-width: 1600px){
    max-width: 1440px;
  }
  `

const GetStartedSteps = [
  {step:1, label: `${Translate.GetStartStep1}`, desc: `${Translate.GetStartStep1desc}`},
  {step:2, label: `${Translate.GetStartStep2}`, desc: `${Translate.GetStartStep2desc}`},
  {step:3, label: `${Translate.GetStartStep3}`, desc: `${Translate.GetStartStep3desc}`},
]

const OffMarket = () => {
  const { isOffmarketDepositCompleted } = useSelector((state) => state.offmarket);
  const { offMarketGames, loadingGames, loading, openOffmarketModal, offmarketModalType } = useSelector((state) => state.offmarket);
  const userData = useSelector((state) => state.user.data);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const {isChatConnected} = useSelector((state) => state.user);
  const { offmarketBanners, loadingBanners, promoBanners } = useSelector(
    (state) => state.general
  );
  const banners = useSelector((state) => state.general.advertisements);

  let [username, setUsername] = useState();
  const [amount, setAmount] = useState();
  const [gameDetails, setGameDetails] = useState({
    name: "",
    code: "",
    use_game_api: false,
  });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [error, setError] = useState(false);
  const playerGamesIds = getPlayerGamesWithId();
  const dispatch = useDispatch();
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnFocus: true,
  };
  useEffect(() => {
    // dispatch(getAdsAndSponsers());

    dispatch(getCasinoBanners());
    if (!offMarketGames.length) dispatch(getOffMarketGames());
    if (isLoggedIn) setUserTimeZone();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setUserTimeZone = () => {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.setUserTimeZone, {
          timezone: timezone,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  function getPlayerGamesWithId() {
    const playerGames = userData?.user_games;
    const playerGameIds = playerGames?.map((game) => game.game__code);
    return playerGameIds;
  }

  const handleGameClick = (gameName, gameCode, use_game_api, btnType) => {
    if (isChatConnected || !Util.isLoggedIn()) {
      setGameDetails({ name: gameName, code: gameCode, use_game_api: use_game_api });
      dispatch(openOffmarketCreditModal(btnType));
    } else {
      toastr.error("Please wait until chat connection is established with the staff.");
    }
  };

  const handleTransactionSuccess = () => {
    setAmount();
  };

  const handleSignup = () => {
    username = username.trim();
    if (!Util.isLoggedIn()) {
      toastr.error("", "Please login first.");
      return;
    }
    if (!username) {
      toastr.error("", "Please enter a valid username.");
      return;
    }
    if (username && Util.isLoggedIn()) {
      dispatch(
        openOffmarketChat({
          message: `<span>Hi There, I would request to create a new account with <br><br> Username: <b>${username}</b> <br> For Game: <b>${gameDetails.name}</b></span>`,
          chatGameObj: {
            username: username,
            game_code: gameDetails.code,
          },
        })
      );
      dispatch(closeOffmarketCreditModal());
      setUsername("");
      toastr.success(
        "",
        "We have given your query to our representative, he/she will process your request."
      );
    }
  };

  const handleConfirmationPopup = () => {
    if (loading) dispatch(setSubmitTransactionStatus());
    setShowConfirmation(!showConfirmation);
  };

  const handleCredits = (e) => {
    e.preventDefault();
    if (!amount || amount <= 0) {
      toastr.error("", "Please enter a valid amount.");
      return;
    } else {
      dispatch(submitTransaction());
      handleConfirmationPopup();
    }
  };



  const [offmarketDepositProcessStarted, setOffmarketDepositProcessStarted] = useState(false);
  useEffect(() => {
    let intervalId;
    const fetchData = async () => {
      dispatch(offmarketDepositeStatusChecker(gameDetails.code, setOffmarketDepositProcessStarted));
      // console.log("hello i entered succesfully")
    };
    
    if (offmarketDepositProcessStarted && !isOffmarketDepositCompleted) {
      intervalId = setInterval(fetchData, 4000);
      // console.log(isOffmarketDepositCompleted, "isOffmarketDepositCompleted, inside useeffect")
      // console.log(offmarketDepositProcessStarted, "offmarketDepositProcessStarted, inside useeffect")
    }

    return () => {
      clearInterval(intervalId);
      // setOffmarketDepositProcessStarted(false); 
    };
  }, [dispatch, offmarketDepositProcessStarted, isOffmarketDepositCompleted, gameDetails]);



  const GameRedeemSendMessage = (amount, game_name) => {
    dispatch(openOffmarketChat({
      message: `
      <span>
        Hey there! I'd like to request a redeem for <b>${game_name}</b>.
        </br>
        Amount: <b>$${amount}</b>
      </span>`,
      simpleUserQuery: true,
    }));
  }


  const handleProceed = () => {
    if (offmarketModalType === "Deposit"){ //game recharge
      if(gameDetails.use_game_api){ //if game has it's own api
        dispatch(
          hasGameApiOffmarketDeposit(
            Number(amount),
            gameDetails.code,
            handleTransactionSuccess,
          )
        );
      }else{ // game that doesnt has it's api
        dispatch(
          offmarketDeposit(
            Number(amount),
            gameDetails.code,
            handleTransactionSuccess,
            setOffmarketDepositProcessStarted
          )
        );
        // checkDepositStatus(gameDetails.code);   
      }
    }
    else{ //game redeem
      dispatch(
        offmarketWithdrawal(
          Number(amount),
          gameDetails.code,
          handleTransactionSuccess,
        )
      );
      GameRedeemSendMessage(Number(amount), gameDetails.name);
    }
    setShowConfirmation(false);
  };


  const checkDepositStatus = (game_id) => {
    setTimeout(() => {
      dispatch(offmarketDepositeStatusChecker(game_id));
      if (!isOffmarketDepositCompleted) {
        checkDepositStatus(game_id); 
      }
    }, 2000);
  };

  



  const handleModal = () => {
    setAmount();
    setUsername("");
    dispatch(closeOffmarketCreditModal());
    dispatch(closeOffmarketDepositModal());
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "username":
        if (value.length <= 2) setError("Please enter a valid username.");
        else setError(false);
        setUsername(value);
        break;
      case "amount":
        if (value.length <= 5) setAmount(value);
        break;
      default:
        break;
    }
  };

  // logic to check if the user is sigined in at least in one games. same logic is used in my games section
  // const userSignedInAtLestOneGame = (playerGamesIds && playerGamesIds.length > 0 && offMarketGames.some(game => playerGamesIds.includes(game.code)))

  return (
    <>
      <div className="main-page-wrapper">
        <section className="home-main-banner-section">
          <div className="home-main-banner-wrap">
            <div className="banner-img-box">
              {loadingBanners ? (
                <div className="d-flex justify-content-center align-items-center p-5">
                  <CircularProgress
                    size={"40px"}
                    style={{ color: "#D70101" }}
                  />
                </div>
              ) : (
                <Slider {...settings}>
                  {offmarketBanners?.map((banner, index) => {
                    return (
                      <div key={index}>
                        <img
                          alt="home-banner"
                          src={banner.url}
                          loading="lazy"
                          width="100%"
                        />
                      </div>
                    );
                  })}
                </Slider>
              )}
            </div>
          </div>
        </section>

        {promoBanners && (
        <section className="promotion-banner-section">
          <div className="promotion-banner-wrapper">
            <div className="promotion-cards">
            {promoBanners?.map((banner, index)=> {
              return (
                <>
                {banner.url === null ? 
                (
                  <div key={index} className="promotion-card">
                  </div>
                ) : (
                  <a key={index} href="#">
                    <div className="promotion-card">
                        <img
                          src={banner.url}

                          style={{width: "100%", height: "100%"}}
                          alt="Game Img"
                          />
                      </div>
                  </a>)
                }
                </>
              )
            })}
            </div>
          </div>
        </section>

        )}



        {/* My games section */}
        {(playerGamesIds && playerGamesIds.length > 0 && offMarketGames.some(game => playerGamesIds.includes(game.code)))
        &&
        <section className="games-card-section">
          <TitleTextWrapper>
            <img src="/img1/star.png" alt="crown icon" width={40} height={40} />
            <h2>{Translate.myGames}</h2>
          </TitleTextWrapper>
          <div className="games-card-wrap">
            <div className="games-card-container">
              {loadingGames ? (
                <div className="offmarket-progress">
                  {" "}
                  <CircularProgress
                    size={"40px"}
                    style={{ color: "#D70101" }}
                  />
                </div>
              ) : offMarketGames.length > 0 ? (
                <div className="offmarket-game-row">
                  {offMarketGames.map((game, index) => {
                    const isGameAlreadySignedUp = playerGamesIds?.includes(
                      game.code
                    );
                    const playerGames = userData?.user_games;
                    const username = playerGames?.find(
                      (item) => item.game__code === game.code
                    )?.username;
                    const use_game_api = playerGames?.find(
                      (item) => item.game__code === game.code
                    )?.game__use_game_api;

                    return (
                      <>
                      {isGameAlreadySignedUp && (
                        <div className="game-card-box" key={index}>
                          <div className="game-img-box">
                            <img
                              src={game.url}
                              className="d-block"
                              alt="Game Img"
                            />
                          </div>
                          <div className="game-overlay-box">
                            <div className="d-flex flex-column gap-2 overlay-content-box" style={{height: "100%", justifyContent: "end"}}>
                              <p style={{fontSize:"20px", fontWeight: "500", zIndex: "10", textAlign: "center", lineHeight:"0.7" }}>{game.title}</p>
                              <p className="offmarket-username">
                                <span>@</span>
                                {username}{" "}
                              </p>
                              <button
                                className="primary-gradientBg-btn"
                                style={{fontSize:"16px"}}
                                onClick={() =>
                                  handleGameClick(
                                    game.title,
                                    game.id,
                                    use_game_api,
                                    "Deposit"
                                  )
                                }
                              >
                                {/* Using the term Deposite as Recharge for the player side view only */}
                                Recharge 
                              </button>
                              
                              <button
                                className="secondary-btn"
                                onClick={() =>
                                  handleGameClick(
                                    game.title,
                                    game.id,
                                    use_game_api,
                                    "Withdraw"
                                  )
                                }
                              >
                                {/* Using the term Withdraw as Redeem for the player side view only */}
                                Redeem
                              </button>
                            </div>

                            <a href={game.download_url} target="_blank">
                              <img
                                className="offmarket-game-download"
                                style={{color: "#fff", width: 16, height: 16}}
                                src="/assets/icons/link-arrow.svg"
                                alt="download-game"
                              />
                            </a>
                          </div>
                        </div>
                    )}
                    </>
                    );
                  })}
                </div>
              ) : (
                <div className="d-flex justify-content-center mt-2">
                  {" "}
                  <p className="no-offmarket-games">
                    {" "}
                    No Games Available!{" "}
                  </p>{" "}
                </div>
              )}
            </div>
          </div>
        </section>        
        }


        {/* Featured games section */}
        {offMarketGames.length > 0 &&
          <section className="games-card-section">
            <TitleTextWrapper>
              <img src="/img1/Crown.png" alt="crown icon" width={40} height={40} />
              <h2>{Translate.featuredGames}</h2>
            </TitleTextWrapper>
            <div className="games-card-wrap">
              <div className="games-card-container">
                {loadingGames ? (
                  <div className="offmarket-progress">
                    {" "}
                    <CircularProgress
                      size={"40px"}
                      style={{ color: "#D70101" }}
                    />
                  </div>
                ) :

                // offMarketGames.length > 0 ?
                
                (
                  <div className="offmarket-game-row">
                    {offMarketGames.map((game, index) => {
                      const isGameAlreadySignedUp = playerGamesIds?.includes(
                        game.code
                      );
                      const playerGames = userData?.user_games;
                      const username = playerGames?.find(
                        (item) => item.game__code === game.code
                      )?.username;
                      return (
                        <>
                          {!isGameAlreadySignedUp && (
                          <div className="game-card-box" key={index}>
                            <div className="game-img-box">
                              <img
                                src={game.url}
                                className="d-block"
                                alt="Game Img"
                              />
                            </div>
                            <div className="game-overlay-box">
                              <div>
                                <button
                                  className="game-btn"
                                  onClick={() =>
                                    handleGameClick(
                                      game.title,
                                      game.code,
                                      null,
                                      "SignUp"
                                      )
                                    }
                                >
                                  <span style={{width: 65, height:65, display:"inline-block"}}><PlayButton/></span>
                                  <p style={{fontSize:"20px", fontWeight: "500", zIndex: "10" }}>{game.title}</p>
                                </button>
                              </div>
                            </div>
                          </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                )
                // : (
                //   <div className="d-flex justify-content-center mt-2">
                //     {" "}
                //     <p className="no-offmarket-games">
                //       {" "}
                //       No Games Available!{" "}
                //     </p>{" "}
                //   </div>
                // )
                }
              </div>
            </div>

            {/* <div className="games-card-wrap">
              <Advertisements ads={banners} />
            </div> */}
          </section>
        }

        <hr className="divider"/>



        {/* How to get started section */}
        <section className="get-started-section">
          <div className="header-container">
            <h1 className="title-text">{Translate.HowToStart}</h1>
          </div>

          <StepsContainer>
            {GetStartedSteps.map((item, index)=>{
              return (
                <>
                    <StepItemWrapper key={index} label={item.label} desc={item.desc}>
                      <GlowRing >
                        <Circle>
                          <StepNumber>{item.step}</StepNumber>
                        </Circle>
                      </GlowRing>
                    </StepItemWrapper>
                  {index !== GetStartedSteps.length - 1 && <ConnectorLine />}
                </>
              )
            })}
          </StepsContainer>
        </section>
      </div>

      {openOffmarketModal && (
        <OffmarketModal
          open={openOffmarketModal}
          close={handleModal}
          fieldName={offmarketModalType === "SignUp" ? "username" : "amount"}
          fieldLabel={`Please enter desired ${
            offmarketModalType === "SignUp"
              ? `username for game - ${gameDetails.name}`
              : "amount"
          }`}
          fieldPlaceholder={offmarketModalType === "SignUp" ? "Username" : "Amount"}
          fieldType={offmarketModalType === "SignUp" ? "text" : "number"}
          fieldValue={offmarketModalType === "SignUp" ? username : amount}
          handleChange={handleChange}
          handleSubmit={offmarketModalType === "SignUp" ? handleSignup : handleCredits}
          error={error}
          amount={offmarketModalType === "Deposit" ? Number(amount) : null}
          gameDetails={offmarketModalType == 'Deposit' ? gameDetails : null}
        />
      )}

      {showConfirmation && (
        <ConfirmationPopup
          open={showConfirmation}
          handleClose={handleConfirmationPopup}
          type={offmarketModalType}
          amount={amount}
          handleProceed={handleProceed}
        />
      )}
    </>
  );
};

export default OffMarket;
