import axios from "axios";
import { apiConfig } from "config/api.config";

class offmarketService {
  getOffMarketGames = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.routes.getOffMarketGames, {
          params: {
            whitelabel_admin_uuid: localStorage.getItem(
              "whitelabel_admin_uuid"
            ),
            agent_name: localStorage.getItem("slug"),
            search: "",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  playerOffMarketGames = (query) => {
    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.routes.playerOffMarketGames, {
          params: {
            search: query,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  offmarketDeposit = (amount, game_id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.offmarketDeposit, {
          amount,
          game_id,
          whitelabel_admin_uuid: localStorage.getItem("whitelabel_admin_uuid"),
          agent_name: localStorage.getItem("slug"),
        })
        .then((response) => {
          const { txn_id } = response.data;
          localStorage.setItem('txn_id', txn_id); // Store txn_id in local storage
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  hasGameApiOffmarketDeposit = (amount, game_id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.hasGameApiOffmarketDeposit, {
          amount,
          game_id,
          whitelabel_admin_uuid: localStorage.getItem("whitelabel_admin_uuid"),
          agent_name: localStorage.getItem("slug"),
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  offmarketDepositeStatusChecker = (game_id) => {
    const txn_id = localStorage.getItem('txn_id'); // Retrieve txn_id from local storage
    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.routes.offmarketDepositeStatusChecker, {
          params: {
            game_id,
            txn_id
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  offmarketWithdrawal = (amount, game_id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.offmarketWithdrawal, {
          amount,
          game_id,
          whitelabel_admin_uuid: localStorage.getItem("whitelabel_admin_uuid"),
          agent_name: localStorage.getItem("slug"),
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
const instance = new offmarketService();

export default instance;
