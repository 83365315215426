export const SET_REDIRECTED_LINK_IFRAME_STATE = "[APP]SET_REDIRECTED_LINK_IFRAME_STATE";
export const SET_TO_REDIRECT_LINK_VALUE = "[APP]SET_TO_REDIRECT_LINK_VALUE";
export const SET_LOADING = "[APP] SET LOADING";
export const SET_LANGUAGE = "[APP] SET LANGUAGE";
export const SET_COUNTRY_CODE = "[APP] SET COUNTRY CODE";
export const COMING_SOON = "[APP] COMING SOON";
export const GET_TRANSLATION = "[APP] GET TRANSLATION";
export const SET_TRANSLATION = "[APP] SET TRANSLATION";
export const SET_STATS_DATA = "[APP] SET_STATS_DATA";
export const SET_BANNERS = "[APP] SET BANNERS";
export const SET_CURRENCIES = "[APP] SET CURRENCIES";
export const SET_OFFMARKET_BANNERS = "[APP] SET_OFFMARKET_BANNERS";
export const SET_TENET_STATUS = "[APP] SET_TENET_STATUS";
export const SET_CMS_LINKS = "[APP] SET CMS LINKS";
export const SET_FOOTER_DATA = "[APP] SET FOOTER DATA";
export const SET_LINKS = "[APP] SET LINKS";
export const SET_STAFF = "[APP] SET STAFF";
export const SET_ADS = "[APP] SET ADS";
export const COUNTDOWN_ENDS = "[APP] COUNTDOWN ENDS";
export const SET_ALCHEMY_FIAT_OPTIONS = "[APP] SET_ALCHEMY_FIAT_OPTIONS";
export const SET_ALCHEMY_CRYPTO_OPTIONS = "[APP] SET_ALCHEMY_CRYPTO_OPTIONS";
export const SET_SLUG = "[APP] SET_SLUG";
export const SET_ADMIN = "[APP] SET_ADMIN";
export const CLEAR_SLUG = "[APP] CLEAR_SLUG";
