import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getCmsLinks,
  getFooterData,
  getSocialMediaLinks,
} from "store/actions/general.actions";
import { Translate } from "localization/index";
import { createMarkup } from "utils/helper";

const Footer = (props) => {
  const { isDrawerOpen } = props;
  const { cmsLinks, footerData, mediaLinks } = useSelector(
    (state) => state.general
  );
  const history = useHistory();
  const agent =
    localStorage.getItem("slug") || localStorage.getItem("adminName");
  const dispatch = useDispatch();
  const redirectToPage = (slug) => {
    history.push(`/${agent}/page/` + slug);
  };
  const domain = "https://main.nexs.io";

  useEffect(() => {
    if (!cmsLinks?.length) dispatch(getCmsLinks());
    if (!mediaLinks?.length) dispatch(getSocialMediaLinks());
    if (!Object.values(footerData)?.length) dispatch(getFooterData());
  }, [dispatch]);

  // const formatSlugName = (slugName) => {
  //   debugger
  //   return slugName?.replaceAll("_", " ").toUpperCase();
  // };

  const formatSlugName = (slugName) => {
    if (!slugName) return ''; // or return a default value

    return slugName.replace(/_/g, ' ').toUpperCase();
  };

  return (
    <>
      <footer
        className={`footer-section ${!isDrawerOpen ? "sidebar-close" : ""}`}
      >
        <div className="footer-wrap">
          <div className="row footer-row">
            
            <div className="text-column">
              <div className="footer-left d-md-flex align-items-center">
                <div className="footer-about">
                  <p>
                    <span
                      className="footer-yellow-text"
                      dangerouslySetInnerHTML={createMarkup(
                        footerData?.footer_description || "The Top Secret & Highly Classified Crypto Casino."
                      )}
                    />
                  </p>
                  <p className="mt-3 d-none">
                    <a href="https://support.nexs.io/players/" target="_blank">
                    <button className="btn" style={{backgroundColor: "#222126", color: "#EDC94B"}}>
                      Get Help
                    </button>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            
            {/* <div className="custom-line custom-line-1"></div> */}
            <div className="link-column" style={{gridColumnStart: 'span 2', maxWidth: "100%"}}>
                <ul className="footer-link" style={{display: "grid", gridTemplateColumns: "1fr 1fr"}}>
                  {cmsLinks?.map((item) => {
                    return (
                      <li className="link-item" key={item.slug}>
                        <a
                          className="link-text"
                          href="javascript:void(0);"
                          onClick={() => redirectToPage(item.slug)}
                        >
                          {Translate[item.slug]
                            ? Translate[item.slug]
                            : formatSlugName(item.slug)}
                        </a>
                      </li>
                    );
                  })}
                </ul>
            </div>
            {/* <div className="custom-line custom-line-2"></div> */}
            <div className="social-column">
              <div className="social-box">
                <p>{Translate.contactUs}</p>

                <div className="social-media-box">
                  <div className="social-media-icon-box">
                    {mediaLinks?.map((media, index) => {
                      return (
                        <a
                          href={media.link}
                          className="social-media-icon"
                          target="_blank"
                          key={index}
                        >
                          <img
                            src={`${domain}/media/${media.image}`}
                            alt="social"
                          />
                        </a>
                      );
                    })}
                  </div>
                </div>

                <p className="contact-text">
                  Email:{" "}
                  <a
                    href={`mailto:${footerData?.contact}`}
                    className="contact-span"
                  >
                    {footerData?.contact}
                  </a>
                </p>

                <p className="contact-text">
                  Telegram:{" "}
                  <a
                    href={`https://t.me/${footerData?.telegram}`}
                    target="_blank"
                    className="contact-span"
                  >
                    {footerData?.telegram}
                  </a>
                </p>
              </div>
              {/* <hr
                style={{
                  backgroundColor: "rgba(157, 157, 157, 0.40)",
                  opacity: "1",
                  margin: "0.875rem 0",
                }}
              /> */}
            </div>
          </div>
        </div>
      </footer>
      <div className="copyright-section">
        <div className="copyright-wrap">
          <p
            className="text"
            dangerouslySetInnerHTML={createMarkup(
              footerData?.copyright_description || ""
            )}
          />
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    isDrawerOpen: state.user.isDrawerOpen,
  };
};

export default connect(mapStateToProps, null)(Footer);
