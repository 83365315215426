import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

export const PageNotFound = () => {
  const path = window.location.pathname;
  const history = useHistory();
  const slug =
    localStorage.getItem("slug") || localStorage.getItem("adminName");

  useEffect(() => {
    if (path === `/${slug}` || path === `/${slug}/`) {
      history.push(`/${slug}/off-market`);
    }
  }, [path]);

  return (
    <div>
      <section className="error-section">
        <div className="sky-container" id="sky">
          <div className="sky-wrap">
            <div className="content-box">
              <h1 className="title-text">404</h1>
              <h2 className="subtitle-text">Oops! Page not found</h2>
              <p className="pera-text">
                The page you are looking for might be in another galaxy.
              </p>
              <div className="astronaut-img-box">
                <img src="/assets/img/astronaut.png" alt="Astronaut" />
              </div>
              <p className="pera-text">
                Don't worry, you can always{" "}
                <a href={`/${slug}/off-market`} className="link-text">
                  go back home
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
