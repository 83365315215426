import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import DesktopHeader from "../Desktop/Common/Header";
import Footer from "../Desktop/Common/Footer";

function PrivateRoute({ component: Component, ...rest }) {
  let authed =
    localStorage.getItem("jwt_access_token") ||
    sessionStorage.getItem("jwt_access_token")
      ? true
      : false;
  const [drawer, setDrawer] = useState(false);
  const [isTopMessageBoxOpen, setIsTopMessageBoxOpen] = useState(true);

  const handleToggleTopMessageBox = () => {
    setIsTopMessageBoxOpen(false);
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        authed ? (
          <>
            <DesktopHeader {...props} setDrawer={setDrawer} toggleTopMessageBox={handleToggleTopMessageBox} isTopMessageBoxOpen={isTopMessageBoxOpen} />

              <div className="main-wrap">
                <Component {...props} extraMarketChild={rest.extraMarketChild} />
              </div>

            <Footer {...props} />
          </>
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
}

export default PrivateRoute;
