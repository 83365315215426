import FullPageRoute from 'helper/FullPageRoute';
import PrivateRoute from 'helper/PrivateRoute';
import PublicRoute from 'helper/PublicRoute';
import { PageNotFound } from 'Desktop/Common/PageNotFound';
import React, { lazy } from 'react';
const NewPasswordForm = lazy(() => import(/* webpackChunkName: "SignUp" */ 'Desktop/ForgotPassword/NewPasswordForm'));
const MyAffiliates = lazy(() => import(/* webpackChunkName: "MyAffiliates" */'../Desktop/Affiliates/MyAffiliates'));
const ChatSupport = lazy(() => import(/* webpackChunkName: "ChatSupport" */'../Desktop/MyAccount/ChatSupport'));
const OffMarket = lazy(() => import(/* webpackChunkName: "OffMarket" */'../Desktop/OffMarket/index'));
const MyAccount = lazy(() => import (/* webpackChunkName: "MyAccount" */'../Desktop/MyAccount'));
const Transactions = lazy(() => import (/* webpackChunkName: "Transactions" */'../Desktop/Transactions'));
const Promotion = lazy (()=> import(/* webpackChunkName: "Promotion" */'../Desktop/Promotion'));
const NewDepositModel = lazy(()=> import(/* webpackChunkName: "NewDepositModel" */'Desktop/MyAccount/NewDepositModel'));
const NewWithDrawModel = lazy(()=> import(/* webpackChunkName: "NewWithDrawModel" */'Desktop/MyAccount/NewWithDrawModel'));
const NewLandingPage = lazy(() => import(/* webpackChunkName: "NewLandingPage" */'Desktop/Common/NewLandingPage/NewLandingPage'));

const RouteWrapper = ({ type, path, component, ...props }) => {
    switch (type) {
      case 'full-page':
        return <FullPageRoute exact path={path} component={component} {...props} />;
      case 'private':
        return <PrivateRoute exact path={path} component={component} {...props} />;
      default:
        return <PublicRoute exact path={path} component={component} {...props} />;
    }
};

const UserRoutes = () => {
    const routes = [
        { path: '/', component: NewLandingPage, type: 'full-page' },
        { path: '/off-market', component: OffMarket, type: 'public' },
        { path: '/reset-password/:userId/:token', component: NewPasswordForm, type: 'public', headerClass: 'header_white', title: 'forgot-password' },
        { path: '/my-account/transactions', component: Transactions, type: 'private', headerClass: 'header_white', title: 'transaction' },
        { path: '/my-account', component: MyAccount, type: 'private', headerClass: 'header_white', title: 'myAccount' },
        { path: '/my-account/chat-support', component: ChatSupport, type: 'private', headerClass: 'header_white', title: 'chatSupport' },
        { path: '/deposit', component: NewDepositModel, type: 'private', headerClass: 'header_white', title: 'deposit' },
        { path: '/withdrawl', component: NewWithDrawModel, type: 'private', headerClass: 'header_white', title: 'withdrawl' },
        { path: '/affiliates', component: MyAffiliates, type: 'private', headerClass: 'header_white', title: 'myAffiliates' },
        { path: '/promotion', component: Promotion, type: 'public', headerClass: 'header_white', title: 'promotion' },
        { path: '/not-found', component: PageNotFound, type: 'full-page' },

    ];
  return (
      <div>
          {routes.map((route, index) => (
              <RouteWrapper key={index} {...route} />
          ))}
      </div>
  );
};

export default UserRoutes;