import * as Actions from "./actionTypes";
import offmarketService from "../../services/offmarketService";
import { toastr } from "react-redux-toastr";
import { getUser } from "./user.actions";

export const getOffMarketData = () => {
  return (dispatch) => {
    dispatch({ type: Actions.GET_OFF_MARKET_DATA });
  };
};

export const openOffmarketChat = (payload) => {
  return (dispatch) => {
    dispatch({
      type: Actions.OPEN_OFFMARKET_CHAT,
      message: payload?.message,
      typeWithdrawRequest: payload?.typeWithdrawRequest,
      simpleUserQuery: payload?.simpleUserQuery,
      chatGameObj: payload?.chatGameObj,
      cashappObj: payload?.cashappObj
    });
  };
};

export const closeOffmarketChat = () => {
  return (dispatch) => {
    dispatch({ type: Actions.CLOSE_OFFMARKET_CHAT });
  };
};

export const openOffmarketCreditModal = (modal_type) => {
  return (dispatch) => {
    dispatch({ type: Actions.OPEN_OFFMARKET_MODAL, modal_type});
  };
};

export const closeOffmarketCreditModal = () => {
  return (dispatch) => {
    dispatch({ type: Actions.CLOSE_OFFMARKET_MODAL });
  };
};

export const submitTransaction = () => {
  return (dispatch) => {
    dispatch({ type: Actions.SUBMIT_TRANSACTION });
  };
};

export const setSubmitTransactionStatus = () => {
  return (dispatch) => {
    dispatch({ type: Actions.SET_SUBMIT_TRANSACTION_STATUS });
  };
};

export const getOffMarketGames = () => {
  return (dispatch) => {
    dispatch(getOffMarketData());
    offmarketService
      .getOffMarketGames()
      .then((data) => {
        dispatch({ type: Actions.GET_OFF_MARKET_GAMES, games: data });
      })
      .catch((err) => {
        console.error(err);
        toastr.error("Failed to load games!!");
      });
  };
};

export const playerOffMarketGames = (query) => {
  return (dispatch) => {
    dispatch(getOffMarketData());
    offmarketService
      .playerOffMarketGames(query)
      .then((data) => {
        dispatch({ type: Actions.GET_OFF_MARKET_GAMES, payload: data });
      })
      .catch((err) => {
        console.error(err);
        toastr.error("Failed to load games!!");
      });
  };
};

export const offmarketDeposit = (amount, id, handleTransactionSuccess, setOffmarketDepositProcessStarted) => {
  return (dispatch) => {
    offmarketService
      .offmarketDeposit(amount, id)
      .then((data) => {
        // toastr.success(data.message);
        setTimeout(() => {
          dispatch(setSubmitTransactionStatus());
        }, 6000);
        setOffmarketDepositProcessStarted(true);
        // dispatch(closeOffmarketCreditModal());
        // handleTransactionSuccess();
        // dispatch(getUser());
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        dispatch(setSubmitTransactionStatus());
        handleTransactionSuccess();
        dispatch(closeOffmarketCreditModal());
      });
  };
};

export const hasGameApiOffmarketDeposit = (amount, id, handleTransactionSuccess) => {
  return (dispatch) => {
    offmarketService
      .hasGameApiOffmarketDeposit(amount, id)
      .then((data) => {
        dispatch(getUser()); 
        toastr.success(data.message);
        handleTransactionSuccess();
        dispatch(setSubmitTransactionStatus());
        dispatch(closeOffmarketCreditModal());
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        dispatch(setSubmitTransactionStatus());
        handleTransactionSuccess();
        dispatch(closeOffmarketCreditModal());
      });
  };
};

export const closeOffmarketDepositModal = () => {
  return (dispatch) => {
    dispatch({type: Actions.OFFMARKET_DEPOSIT_RESET});
  }
}

export const offmarketDepositeStatusChecker = (game_id,setOffmarketDepositProcessStarted) => {
  return (dispatch) => {
    offmarketService
      .offmarketDepositeStatusChecker(game_id)
      .then((data) => {
        dispatch({type: Actions.IS_OFFMARKET_DEPOSIT_RUNNING, payload: data});
        if(data.data.status === "Completed"){
          setOffmarketDepositProcessStarted(false);
          dispatch({type: Actions.IS_OFFMARKET_DEPOSIT_COMPLETED, payload: true});
          dispatch(getUser());  
        }
        
        if(data.data.status === "Failed"){
          setOffmarketDepositProcessStarted(false);
          dispatch({type: Actions.IS_OFFMARKET_DEPOSIT_COMPLETED, payload: false});
          dispatch({type: Actions.IS_OFFMARKET_DEPOSIT_FAILED, payload: true});
          toastr.error(data.data.notes);

        }
      })
      .catch((err) => {
        console.error(err);
        setOffmarketDepositProcessStarted(false);
      });
  };
};


export const offmarketWithdrawal = (amount, id, handleTransactionSuccess) => {
  return (dispatch) => {
    offmarketService
      .offmarketWithdrawal(amount, id)
      .then((data) => {
        toastr.success(data.message);
        dispatch(setSubmitTransactionStatus());
        dispatch(closeOffmarketCreditModal());
        handleTransactionSuccess();
        dispatch(getUser());
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        dispatch(setSubmitTransactionStatus());
        handleTransactionSuccess();
        dispatch(closeOffmarketCreditModal());
      });
  };
};
