/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pageInfoRequest } from "../../../store/actions/GeneralPage/GeneralPageAction.js";
import { StaticPageContainer } from "./style";
import PageFooter from "./PageFooter";

const StaticInfoPages = (props) => {
  const extraPageData = useSelector((state) => state.extraPage);

  const dispatch = useDispatch();

  useEffect(() => {
    const { pageslug } = props?.match?.params;
    dispatch(pageInfoRequest(pageslug));
  }, []);

  return (
    <>
      <StaticPageContainer>
        <PageFooter extraPageData={extraPageData} />
      </StaticPageContainer>
    </>
  );
};

export default StaticInfoPages;
