import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Route, BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import ReduxToastr from "react-redux-toastr";
import ScrollToTop from "./helper/ScrollToTop";
const container = document.getElementById("root");

const root = createRoot(container);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop />
      <Route component={App} />
    </BrowserRouter>
    <ReduxToastr
      timeOut={3000}
      newestOnTop={true}
      preventDuplicates
      position="top-right"
      getState={(state) => state.toastr}
      transitionIn="fadeIn"
      transitionOut="fadeOut"
      removeOnHover={false}
      closeOnToastrClick
    />
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
