/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { Grid, IconButton, Tabs, Tab } from "@material-ui/core";
import EndChatDialog from "./EndChatDialog";
import { format } from "date-fns";
import { Translate } from "localization/index";
import ReactHtmlParser from "html-react-parser";
import * as Actions from "./../../../store/actions/actionTypes";
import { getStaffId } from "store/actions/general.actions";
import {
  clearUnreadMsgCount,
  getChatRoomDetails,
} from "store/actions/user.actions";
import { closeOffmarketChat } from "store/actions/offmarket.actions";
import { toastr } from "react-redux-toastr";
import Util from "helper/Util";
import { CircularProgress } from "../../../../node_modules/@material-ui/core/index";
import { tipSentImgUrl } from "config/general.config";
import { object } from "prop-types";

let queueInterval;
let roomInterval;
let isNewMessage = false;

const ChatSupport = (props) => {
  const {
    userQuery,
    setQuery,
     handleMaximizeChat,
    toggleChat,
    maximizeChat
  } = props;

  const chatOpen = useSelector((state) => state.offmarket.isOffmarketChatOpen)
  const {typeWithdrawRequest, cashappObj, chatGameObj, simpleUserQuery, openOffmarketModal} = useSelector((state) => state.offmarket);


  const userId = localStorage.getItem("user_id");
  const { isLoggedIn, isEndChatDialogOpen, isChatConnected } = useSelector(
    (state) => state.user
  );

  const { staffDetails: staff } = useSelector((state) => state.general);
  const { data: userData, chatDetails } = useSelector((state) => state.user);
  const { chatMessages, unreadMessagesDetails } = chatDetails;
  const [loading, setLoading] = useState(false);
  const [isRoomCreated, setIsRoomCreated] = useState(false);
  const [messages, setMessages] = useState([]);
  const [announcementMessages, setAnnouncementMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [showTipFeedback, setShowTip] = useState(false);
  const [chatEnded, setChatEnded] = useState(false);
  const [staffDetails, setStaffDetails] = useState(false);
  const [lightbox, setLightbox] = useState({ open: false, src: "" });
  const [hideSendMessage, setHideSendMessage] = useState(false);
  const [page, setPage] = useState(2);
  const uploadedFile = useRef("");
  const hasMore = useRef(false);
  const messageCount = unreadMessagesDetails?.unread_message_count;
  let chatEndedFromStaff = false;
  const socketRef = useRef(null);
  const communityChatSocketRef = useRef(null);
  const lastMsgRef = useRef(null);
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCreateCommunityRoom = () => {
    if (communityChatSocketRef?.current) communityChatSocketRef.current.close();
    const userId = localStorage.getItem("user_id");
    const agent = localStorage.getItem("slug");
    if (!userId) return null;

    communityChatSocketRef.current = new WebSocket(
      `wss://${process.env.REACT_APP_SOCKET_ENDPOINT}/ws/chatroom/${agent}/?user_id=${userId}`
    );

    communityChatSocketRef.current.onopen = () => {
      console.log("Community WebSocket is opened");
      // dispatch({ type: Actions.SET_CHAT_CONNECTION_STATUS, status: true });
      // setIsRoomCreated(true);
      if (roomInterval) clearInterval(roomInterval);
    };

    communityChatSocketRef.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log('Received chat message:', message);
      const { type, sender_id, is_active, username } = message;
      if (type === "live_status") {
        if (is_active) setStaffDetails({ id: sender_id, username: username });
        else setStaffDetails(is_active);
      }
      isNewMessage = true;
      setAnnouncementMessages((prevMessages) => [...prevMessages, message]);
      handleEndMsgScroll();
    };

    communityChatSocketRef.current.onclose = () => {
      console.log("WebSocket is closed");
    };

    communityChatSocketRef.current.onerror = () => {
      console.log("WebSocket error");
    };
  };

  const handleCreateRoom = () => {
    handleCreateCommunityRoom();
    if (socketRef?.current) socketRef.current.close();
    const userId = localStorage.getItem("user_id");
    if (!userId) return null;

    socketRef.current = new WebSocket(
      `wss://${process.env.REACT_APP_SOCKET_ENDPOINT}/ws/cschat/P${userId}Chat/?player_id=${userId}`
    );

    socketRef.current.onopen = () => {
      console.log("WebSocket is opened");
      dispatch({ type: Actions.SET_CHAT_CONNECTION_STATUS, status: true });
      setIsRoomCreated(true);
      // setIsSocketConnected(true);
      if (roomInterval) clearInterval(roomInterval);
      const signupMessage = {
        type: "offmarket_signup",
        username: chatGameObj?.username,
        game_code: chatGameObj?.game_code,
        sender_id: userId,
        is_player_sender: true,
      };
      const message = {
        type: "join_message",
        message: `${
          localStorage.getItem("username") || userData?.username || "Player"
        } has joined the Chat`,
        sender_id: userId,
        is_player_sender: true,
      };
      const markUnreadMessage = {
        type: "mark_message_as_read",
        sender_id: userId,
        is_player_sender: true,
      };
      const updatingPlayerOnlineStatus = {
        type: "re_arrange",
        sender_id: userId,
        is_player_sender: true,
      };
      
      const startConversationMessage = {
        type: "message",
        message: userQuery,
        sender_id: userId,
        is_player_sender: true,
        is_file: false,
      };
      if (userQuery)
        socketRef.current.send(JSON.stringify(startConversationMessage));
      setQuery("");

      socketRef.current.send(JSON.stringify(message));
      socketRef.current.send(JSON.stringify(markUnreadMessage));
      socketRef.current.send(JSON.stringify(updatingPlayerOnlineStatus));

      if (signupMessage && chatGameObj?.username){
        socketRef.current.send(JSON.stringify(signupMessage));
      }
      dispatch(getStaffId());
      handleScroll();
      setQuery("");
    };

    socketRef.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log('Received chat message:', message);
      const { type, sender_id, is_active, username } = message;
      if (type === "live_status") {
        if (is_active) setStaffDetails({ id: sender_id, username: username });
        else setStaffDetails(is_active);
      }
      isNewMessage = true;
      setMessages((prevMessages) => [...prevMessages, message]);
      handleEndMsgScroll();
    };

    socketRef.current.onclose = () => {
      console.log("WebSocket is closed");
      dispatch({ type: Actions.SET_CHAT_CONNECTION_STATUS, status: false });
      setIsRoomCreated(false);
      // if (isRoomCreated) handleCreateRoom();
    };

    socketRef.current.onerror = () => {
      console.log("WebSocket error");
      dispatch({ type: Actions.SET_CHAT_CONNECTION_STATUS, status: false });
      if (isRoomCreated) handleCreateRoom();
    };
  };

  const checkAutoLocking = () => {
    if (document.hidden){
      console.log("User has switched to another tab or app");
      return;
    }
    else {
      console.log("User returned from another tab or app");
      if (!isChatConnected){
        handleCreateRoom();
      }
    }
  };


  useEffect(()=>{
    if(!openOffmarketModal){
      document.addEventListener("visibilitychange", checkAutoLocking);
      console.log("Visibility eventlistener added")
    } else{
      document.removeEventListener("visibilitychange", checkAutoLocking);
      console.log("Visibility eventlistener REMOVED")
    }
    
    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener("visibilitychange", checkAutoLocking);
    };
  }, [openOffmarketModal])

  

  const sendAddCashAppRequest = () => {
    const addcashappMessage = {
      type: "add_cashapp_request",
      cashappId: cashappObj?.cashappId,
      sender_id: userId,
      is_player_sender: true,
    };
    socketRef.current.send(JSON.stringify(addcashappMessage));
    sendUserQueryMessages();
  };

  const sendOffmarketMessage = () => {
    const gameSignupMessage = {
      type: "offmarket_signup",
      username: chatGameObj?.username,
      game_code: chatGameObj?.game_code,
      sender_id: userId,
      is_player_sender: true,
    };
    socketRef.current.send(JSON.stringify(gameSignupMessage));
    sendUserQueryMessages();
  };


  const sendUserQueryMessages = () =>{
    const sendUserQuery = {
      type: "message",
      message: userQuery,
      sender_id: userId,
      is_player_sender: true,
      is_file: false,
    };
    if (userQuery)
      socketRef.current.send(JSON.stringify(sendUserQuery));

    setQuery("");
  }


  useEffect(() => {
    if (userQuery !== "" && userQuery !== null) {
      if(Object.keys(chatGameObj).length !== 0){
        sendOffmarketMessage();
      }
      else if(Object.keys(cashappObj).length !== 0){
        sendAddCashAppRequest();
      }
      else if(typeWithdrawRequest){
        sendUserQueryMessages();
      }
      else if(simpleUserQuery){
        sendUserQueryMessages();
      }
    }
  }, [userQuery]);

  useEffect(()=>{
    const announcementWindow = document.getElementById("announcement-window");
    announcementWindow && announcementWindow.scrollTo({top: announcementWindow.scrollHeight, behavior: 'smooth'});

  }, [tabValue])

  useEffect(() => {
    if (!chatOpen) {
      const prevMsgLength = parseInt(localStorage.getItem("prev-msg"));
      const currMsgesExTypingMsg = messages?.filter(
        (msg) => msg.type === "message"
      );
      const totalMsgDiff = {
        unread_message_count: currMsgesExTypingMsg.length - prevMsgLength,
      };
      if (totalMsgDiff.unread_message_count > 0 && prevMsgLength)
        dispatch({
          type: Actions.SET_CHAT_CONNECTION_DETAILS,
          detailType: "message_count",
          unreadMessagesDetails: totalMsgDiff,
        });
    }
    if (isNewMessage) {
      handleEndMsgScroll();
      isNewMessage = false;
    } else {
      const chatContainer = document.getElementById("chat-window");
      if (chatContainer)
        chatContainer.scrollTo({ top: 150, behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    if (!chatOpen) {
      const currMsgesExTypingMsg = messages?.filter(
        (msg) => msg.type === "message"
      );
      localStorage.setItem("prev-msg", currMsgesExTypingMsg?.length);
    } else {
      localStorage.removeItem("prev-msg");
      if (messageCount > 0) dispatch(clearUnreadMsgCount());
    }
    dispatch({ type: Actions.RESET_UNREAD_MESSAGES });
  }, [chatOpen]);

  useEffect(() => {
    const totalMessageTillNow =
      chatMessages?.results?.length + messages?.length;
    hasMore.current =
      totalMessageTillNow < chatMessages?.count && chatMessages?.next;
    const prevChatMessages = chatMessages?.results ?? [];
    const messageWithoutId = messages.filter(
      (msg) => msg.type === "message" && !msg.id
    );
    setMessages((prevMessages) => {
      const totalMessages = [...prevChatMessages, ...prevMessages];
      const uniqMessages = totalMessages.filter(
        (item, index, arr) =>
          arr.findIndex((obj) => obj.id === item.id) === index
      );
      uniqMessages.sort((msg1, msg2) => msg1.id - msg2.id);
      return [...uniqMessages, ...messageWithoutId];
    });
    setLoading(false);
  }, [chatMessages]);

  useEffect(() => {
    if (!isLoggedIn) {
      localStorage.setItem("was_chat_open", false);
      clearInterval(roomInterval);
      handleExitChat();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (!isRoomCreated) {
      dispatch(getStaffId());
      roomInterval = setInterval(() => {
        handleCreateRoom();
      }, 3000);
      if (!Object.keys(chatMessages).length)
        dispatch(getChatRoomDetails("recent_messages"));
      if (messageCount > 0) dispatch(clearUnreadMsgCount());
    }

    return () => {
      clearInterval(roomInterval);
      // document.removeEventListener("visibilitychange", checkAutoLocking);
      dispatch({ type: Actions.CLOSE_END_CHAT_DIALOG });
      dispatch({ type: Actions.CLEAR_CHAT_CONNECTION_DETAILS });
    };
  }, []);

  useEffect(() => {
    const chatContainer = document.getElementById("chat-window");
    if (chatContainer) chatContainer.addEventListener("scroll", handleScroll);
    return () => {
      if (chatContainer)
        chatContainer.removeEventListener("scroll", handleScroll);
    };
  }, [page]);

  const handleScroll = () => {
    const chatContainer = document.getElementById("chat-window");
    const scroll = chatContainer.scrollTop;
    if (scroll === 0 && hasMore.current) {
      dispatch(getChatRoomDetails("recent_messages", page));
      setLoading(true);
      setPage(page + 1);
    }
  };

  const sendTipCommentMessage = (amount) => {
    const tipRewardMessage = `<span>Paid <br> <b class='tipmsg-amount'>$${amount}</b> <br> <span class='tipmsg-username'> tip to <b>${staffDetails?.username}</b> <img src='${tipSentImgUrl}' /> </span></span>`;
    const isTip = typeof amount === "number";
    const tipMessage = {
      type: "message",
      message: isTip ? tipRewardMessage : `${amount}`,
      sender_id: userId,
      is_player_sender: true,
      is_tip: true,
      tip_user: staffDetails?.id,
    };
    socketRef.current.send(JSON.stringify(tipMessage));
  };

  const sendMessage = () => {
    if (inputValue) {
      const message = {
        type: "message",
        message: inputValue,
        sender_id: userId,
        is_player_sender: true,
        is_file: false,
      };
      socketRef.current.send(JSON.stringify(message));
      setInputValue("");
    }
  };

  const openLightbox = (url) => {
    setLightbox({ open: true, src: url });
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    const isNumber =
      Number(value) !== 0 && !isNaN(value) && !isNaN(parseFloat(value));
    if (hideSendMessage && !isNumber && isEndChatDialogOpen) {
      dispatch({ type: Actions.CLOSE_END_CHAT_DIALOG });
      setShowTip(!showTipFeedback);
    }
    setHideSendMessage(isNumber);
    setInputValue(value);
  };

  //called while user logged out
  const handleExitChat = () => {
    const userId = localStorage.getItem("user_id");
    const staffId =
      staffDetails?.id || parseInt(localStorage.getItem("staffId") || "null");
    if (isRoomCreated) {
      const message = {
        type: "end_message",
        message: `${userData?.username} has left the chat`,
        sender_id: userId,
        is_player_sender: true,
      };
      socketRef.current.send(JSON.stringify(message));

      const submittingPlayerIsLeaving = {
        type: "remove_chat_from_list",
        sender_id: userId,
        is_player_sender: true,
      };
      socketRef.current.send(JSON.stringify(submittingPlayerIsLeaving));
      socketRef.current.close();
      communityChatSocketRef.current.close();
      socketRef.current.onclose = () => {
        clearInterval(queueInterval);
        clearInterval(roomInterval);
        setChatEnded(true);
        handleEndMsgScroll();
        dispatch({ type: Actions.SET_CHAT_CONNECTION_STATUS, status: false });
        setIsRoomCreated(false);
        // setIsSocketConnected(false);
        staffId
          ? setShowTip(true)
          : dispatch({ type: Actions.CLOSE_END_CHAT_DIALOG });
      };
    } else {
      dispatch({ type: Actions.CLOSE_END_CHAT_DIALOG });
      dispatch(closeOffmarketChat());
      localStorage.setItem("was_chat_open", false);
    }
    clearInterval(roomInterval);
    roomInterval = null;
    setQuery();
  };

  const handleEndMsgScroll = () => {
    if (lastMsgRef.current) {
      lastMsgRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const sendFileMessage = (message) => {
    socketRef.current.send(JSON.stringify(message));
  };

  const sendTypingMessage = () => {
    const message = {
      type: "typing",
      message: `${userData?.username} is typing...`,
      sender_id: userId,
      is_player_sender: true,
    };
    if (socketRef.current?.readyState === 1)
      socketRef.current.send(JSON.stringify(message));
  };

  const isImage = (file_extension) => {
    const imageFileExtensions = ["png", "jpg", "jpeg", "svg", "webp"];
    const fileParts = file_extension?.split(".") || "";
    if (
      imageFileExtensions.includes(file_extension) ||
      imageFileExtensions.includes(fileParts[fileParts?.length - 1])
    )
      return true;
    else return false;
  };

  const isVideo = (file_extension) => {
    if (["mkv", "mp4", "webm", "ogg"].includes(file_extension)) return true;
    else return false;
  };

  const handleOpenMediaFile = (url) => {
    window.open(url, "_blank");
  };

  const openTipCommentBox = () => {
    dispatch(getStaffId());
    if (showTipFeedback) dispatch({ type: Actions.CLOSE_END_CHAT_DIALOG });
    else dispatch({ type: Actions.OPEN_END_CHAT_DIALOG });
    setShowTip(!showTipFeedback);
  };

  const sendMessageOnEnter = (e) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };

  const handleLightboxClick = () => {
    setLightbox({ open: false, src: "" });
  };

  const download = async (url) => {
    const fileType = url.split(".").pop();
    const a = document.createElement("a");
    a.href = await toDataURL(url);
    a.download = `image.${fileType}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const toDataURL = async (url) => {
    return fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      });
  };

  const handleFileUpload = async (e) => {
    const selectedFile = e.target.files[0];
    uploadedFile.current = selectedFile;
    if (!selectedFile) {
      toastr.error("", Translate.message.FILE_REQUIRED);
      return;
    } else {
      const encodedData = await Util.convertToBase64(selectedFile);
      const encodedFile = encodedData.split("base64,")[1];
      const message = {
        type: "message",
        is_file: true,
        file: {
          name: selectedFile?.name,
          type: "png",
          content: encodedFile,
          is_player_sender: true,
          sender_id: userId,
        },
      };
      sendFileMessage(message);
    }
  };

  const extractFileNameFromURL = (url) => {
    const urlParts = url.split("/");
    let fileNameWithParams = urlParts[urlParts.length - 1];
    const queryParamIndex = fileNameWithParams.indexOf("?");
    if (queryParamIndex !== -1) {
      fileNameWithParams = fileNameWithParams.substring(0, queryParamIndex);
    }
    const decodedFileName = decodeURIComponent(fileNameWithParams);
    const underscoreIndex = decodedFileName.indexOf("_");
    const fileName =
      underscoreIndex !== -1
        ? decodedFileName.substring(0, underscoreIndex)
        : decodedFileName;
    return fileName;
  };

  const getMessageTime = (sentTime) => {
    const sentDate = new Date(sentTime);
    const todayDate = new Date();
    const yesterdayDate = new Date();
    yesterdayDate.setDate(todayDate.getDate() - 1);
    const time = format(sentDate, "HH:mm");

    if (todayDate.toLocaleDateString() === sentDate.toLocaleDateString())
      return `Today ${time}`;
    else if (
      yesterdayDate.toLocaleDateString() === sentDate.toLocaleDateString()
    )
      return `Yesterday ${time}`;
    else {
      const dateTime = format(sentDate, "dd/MM/yyyy HH:mm");
      return dateTime;
    }
  };

  return (
    <>
      <div className="deposit-card">
        <div className="chat-header">
          <Grid className="chat-head">
            <img src="/assets/icons/conversation.svg" alt="qna" width="20px" />
            <h3 className="tab-title-text"> Support </h3>

            {!isChatConnected ? (
              <div className="fully-centered">
                <img
                  src="/assets/icons/connecting.gif"
                  alt="connecting"
                  width="30px"
                  style={{mixBlendMode: "multiply"}}
                />
              </div>
            ) : (
              <div className="fully-centered">
                <span
                  className={`${
                    staffDetails?.id ? "active-inner" : "active-red active-inner"
                  }`}
                ></span>
              </div>
            )}
          </Grid>
          {chatOpen && (
            <Grid className="card-icons">
              <img
                src="/assets/icons/minimize.svg"
                className="icon-margin mobile-hidden"
                alt="minimize"
                onClick={toggleChat}
              />
              <img
              className="mobile-hidden"
                src="/assets/icons/maximize.svg"
                alt="maximize"
                onClick={handleMaximizeChat}
              />
              <img
                src="/assets/icons/close.svg"
                alt="close"
                onClick={toggleChat}
              />
            </Grid>
          )}
        </div>
        <div className="player-chat-module">


          {/* {!isChatConnected ? (
            <div className="fully-centered">
              <img
                src="/assets/icons/connecting.gif"
                alt="connecting"
                width="30px"
              />
              <p className="p-0"> Connecting to staff... </p>
            </div>
          ) : (
            <div className="fully-centered">
              <span
                className={`${
                  staffDetails?.id ? "active-inner" : "active-red active-inner"
                }`}
              ></span>
              <p className="p-0">
                {" "}
                {staffDetails?.id
                  ? `You are now connected to ${staffDetails?.username}`
                  // : "No active staff available"}{" "}
                  : "Say hi and we will connect."}{" "}
              </p>
            </div>
          )} */}



        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Chat" />
          <Tab label="📢 Announcement" />
        </Tabs>

        {tabValue === 0 && (
          <>
          {loading && (
            <div className="msg-loader mt-1">
              {" "}
              <CircularProgress
                size={"18px"}
                style={{ color: "#D70101" }}
              />{" "}
            </div>
          )}
          <div className="chat-messages" id="chat-window">
            {messages?.length > 0 &&
              messages?.map((message, index) => {
                if (
                  message.type === "offmarket_signup" ||
                  message.type === "live_status" || message.type === "add_cashapp_request"
                )
                  return false;
                chatEndedFromStaff = message?.type === "end_message";
                if (chatEndedFromStaff) handleEndMsgScroll();
                let chatMessage = message?.message || message?.message_text;
                let isUserMessage =
                  message?.sender_id == userId ||
                  message?.sender_username === userData?.username ||
                  message?.is_player_sender;
                let msgClass =
                  message?.type == "message" || !message?.type
                    ? isUserMessage
                      ? "text-position user-message"
                      : "staff-message"
                    : message?.type == "typing"
                    ? "typedownloadClass"
                    : "join_message";
                let showTypingMessage =
                  (message?.type == "typing" ||
                    message?.type == "join_message") &&
                  isUserMessage
                    ? "d-none"
                    : "";
                let isFileMsg = message?.is_file;
                const tipMsgClass =
                  message?.is_tip || message?.is_comment ? "tipmsg" : "";
                const isImgFile = isImage(message?.file);
                if (
                  !isUserMessage &&
                  !sessionStorage.getItem("staffId") &&
                  message?.sender_id
                )
                  sessionStorage.setItem("staffId", message.sender_id);

                if (isFileMsg) {
                  let fileMsgClass = isUserMessage
                    ? "user_file_message"
                    : "staff_file_message";
                  const file = extractFileNameFromURL(chatMessage);
                  if (isImage(message.file_extension)) {
                    return (
                      <div key={index}>
                        <a
                          target="_blank"
                          className={`chat-message ${fileMsgClass} pointer`}
                          onClick={() => openLightbox(chatMessage)}
                        >
                          <img
                            className="maxImg"
                            src={chatMessage}
                            height={"150px"}
                            width={"150px"}
                            alt={chatMessage}
                          />
                          <span className={`${isFileMsg ? "fix-width" : ""}`}>
                            {" "}
                            {file}
                            {"."}
                            {message.file_extension}{" "}
                          </span>
                          <span className="timestamp">
                            {message.sent_time &&
                              getMessageTime(message.sent_time)}
                          </span>
                        </a>
                        {lightbox?.open && (
                          <div
                            className="custom-lightbox"
                            onClick={handleLightboxClick}
                          >
                            <div className="lightbox-content">
                              <div className="close-lightbox">
                                <IconButton onClick={handleLightboxClick}>
                                  <img
                                    src="/assets/icons/close.svg"
                                    alt="close-img"
                                  />
                                </IconButton>
                              </div>
                              <img src={lightbox?.src} alt="Lightbox" />
                              <div
                                className="download-wrap pointer"
                                onClick={() => download(lightbox?.src)}
                              >
                                <img
                                  src="/assets/icons/download.svg"
                                  alt="download"
                                  className="download-icon"
                                />
                                <p> Download File </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  } else if (isVideo(message.file_extension)) {
                    return (
                      <div
                        key={index}
                        className={`chat-message ${fileMsgClass} pointer`}
                        onClick={() => handleOpenMediaFile(chatMessage)}
                      >
                        <video
                          controls
                          controlsList="download"
                          className="file-preview"
                        >
                          <source src={chatMessage} />
                        </video>
                        <span>
                          {" "}
                          {file}
                          {"."}
                          {message.file_extension}{" "}
                        </span>
                        <span className="timestamp">
                          {message.sent_time &&
                            getMessageTime(message.sent_time)}
                        </span>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={index}
                        className={`chat-message ${fileMsgClass} pointer`}
                      >
                        <a
                          className={`${
                            !isImgFile ? "pdf-wrap" : "img-wrap fix-width"
                          } pointer`}
                          onClick={() => handleOpenMediaFile(chatMessage)}
                          width="150px"
                          height="150px"
                          style={{ color: "#000" }}
                          download
                        >
                          {isImgFile ? (
                            <img
                              className="maxImg"
                              src={chatMessage}
                              height={"150px"}
                              width={"150px"}
                              alt={chatMessage}
                            />
                          ) : fileMsgClass === "user_file_message" ? (
                            <img
                              src="/assets/icons/user-file.svg"
                              height="48px"
                              alt="png-preview"
                            />
                          ) : (
                            <img
                              src="/assets/icons/staff-file.svg"
                              height="48px"
                              alt="png-preview"
                            />
                          )}
                          <span>
                            {" "}
                            {file}
                            {"."}
                            {message.file_extension}{" "}
                          </span>
                        </a>
                        <span className="timestamp">
                          {message.sent_time &&
                            getMessageTime(message.sent_time)}
                        </span>
                      </div>
                    );
                  }
                } else {
                  return (
                    <div
                      key={index}
                      className={`chat-message ${msgClass} ${tipMsgClass} ${showTypingMessage} pointer`}
                      id={`${
                        message?.type == "typing" ? `typing_${index}` : ""
                      }`}
                    >
                      {msgClass === "staff-message" && (
                        <span className="staff-name">
                          {" "}
                          {staffDetails?.username || staff?.username}{" "}
                        </span>
                      )}
                      <span className="text">
                        {typeof chatMessage == "string"
                          ? ReactHtmlParser(chatMessage)
                          : chatMessage}
                      </span>
                      {message?.type === "typing" && (
                        <img src="/img1/typing-msg.gif" alt="typing-icon" />
                      )}
                      {message?.type !== "typing" && (
                        <span className="timestamp">
                          {message?.sent_time &&
                            getMessageTime(message.sent_time)}
                        </span>
                      )}
                      {message?.type == "typing" &&
                        setTimeout(() => {
                          let typingMessage = document.getElementById(
                            `typing_${index}`
                          );
                          if (typingMessage)
                            typingMessage.style.display = "none";
                        }, 4000)}
                    </div>
                  );
                }
              })}
            <div ref={lastMsgRef}></div>
            {(chatEnded || chatEndedFromStaff) && (
              <div className="join_message"> {Translate.chatEnded} </div>
            )}
          </div>
          {(isEndChatDialogOpen || showTipFeedback) && (
            <EndChatDialog
              showTipFeedback={showTipFeedback}
              setShowTip={setShowTip}
              tipAmount={inputValue}
              setInputValue={setInputValue}
              sendTipCommentMessage={sendTipCommentMessage}
              setHideSendMessage={setHideSendMessage}
              staff={staffDetails}
            />
          )}
          {isChatConnected && !isEndChatDialogOpen && (
            <div className="input-container">
              <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="Type your message..."
                onFocus={sendTypingMessage}
                onKeyUp={sendMessageOnEnter}
              />
              <button type="submit" onClick={sendMessage}>
                <img src="/assets/icons/send.svg" alt="send" />
              </button>
              {staffDetails?.id && (
                <button
                  className="pay-button"
                  disabled={inputValue ? !hideSendMessage : false}
                  onClick={openTipCommentBox}
                >
                  Pay tip
                </button>
              )}
              <button type="submit">
                <input
                  type="file"
                  className="send-media"
                  onChange={handleFileUpload}
                />
                <img src="/assets/icons/attach-file.svg" alt="attach-file" />
              </button>
            </div>
          )}
          </>
        )}


        {tabValue === 1 && (
                    <>
          {loading && (
            <div className="msg-loader mt-1">
              {" "}
              <CircularProgress
                size={"18px"}
                style={{ color: "#D70101" }}
              />{" "}
            </div>
          )}
          <div className="chat-messages" id="announcement-window">
            {announcementMessages?.length > 0 &&
              announcementMessages?.map((message, index) => {
                let chatMessage = message?.message || message?.message_text;
                let isUserMessage = false;
                let msgClass = "staff-message";
                if (
                  !isUserMessage &&
                  !sessionStorage.getItem("staffId") &&
                  message?.sender
                )
                  sessionStorage.setItem("staffId", message.sender_id);

                  return (
                    <div
                      key={index}
                      className={`chat-message ${msgClass} pointer`}
                    >
                      {msgClass === "staff-message" && (
                        <span className="staff-name">
                          {" "}
                          {staffDetails?.username || staff?.username}{" "}
                        </span>
                      )}
                      <span className="text">
                        {typeof chatMessage == "string"
                          ? ReactHtmlParser(chatMessage)
                          : chatMessage}
                      </span>
                      <span className="timestamp">
                        {message?.timestamp &&
                          getMessageTime(message.timestamp)}
                      </span>
                    </div>
                  );
              })}
            <div ref={lastMsgRef}></div>
            {(chatEnded || chatEndedFromStaff) && (
              <div className="join_message"> {Translate.chatEnded} </div>
            )}
          </div>
          </>
        )}

        </div>
      </div>
    </>
  );
};

export default ChatSupport;
