/* eslint-disable default-case */
/* eslint-disable no-undef */
import JwtDecode from "jwt-decode";
import { toastr } from "react-redux-toastr";
import { logoutUser } from "../store/actions/user.actions";
const slug = localStorage.getItem("slug") || localStorage.getItem("adminName");

class Util {
  static getFormattedDate = function (date) {
    if (!date) return null;
    let date_1, localDateTime;
    if (!date) return date;
    if (date.indexOf("Z") > -1) {
      localDateTime = new Date(date);
    } else {
      date_1 = date.toString() + "Z";
      localDateTime = new Date(date);
    }
    return localDateTime;
  };

  // cut number
  static toFixedDecimal = function (value, oddType) {
    if (!value) {
      return value;
    }
    let val = value.toString();
    let dotIndex = val.indexOf(".");
    let roundedValue;
    if (dotIndex >= 0) {
      if (dotIndex === val.length - 2) {
        roundedValue = val + "0";
      } else {
        roundedValue = val.slice(0, dotIndex + 3);
      }
    } else {
      roundedValue = val + ".00";
    }

    if (oddType === "american") {
      roundedValue = parseFloat(roundedValue);
      if (roundedValue >= 2) {
        roundedValue = (roundedValue - 1) * 100;
      }
      if (value < 2) {
        roundedValue = -100 / (roundedValue - 1);
      }

      roundedValue = roundedValue.toFixed(0);
    }
    return roundedValue;
  };

  static isLoggedIn = () => {
    let access_token = window.localStorage.getItem("jwt_access_token")
      ? window.localStorage.getItem("jwt_access_token")
      : window.sessionStorage.getItem("jwt_access_token");
    if (!access_token) {
      return false;
    }
    const decoded = JwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    } else {
      return true;
    }
  };
  static getAccessToken = () => {
    let access_token = window.localStorage.getItem("jwt_access_token")
      ? window.localStorage.getItem("jwt_access_token")
      : window.sessionStorage.getItem("jwt_access_token");

    let rememberMeChecked = window.localStorage.getItem("jwt_access_token")
      ? true
      : false;

    return { access_token, rememberMeChecked };
  };

  static isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = JwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    } else {
      return true;
    }
  };

  static isLoggedIn = () => {
    let access_token = window.localStorage.getItem("jwt_access_token")
      ? window.localStorage.getItem("jwt_access_token")
      : window.sessionStorage.getItem("jwt_access_token");
    if (!access_token) {
      return false;
    }
    const decoded = JwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    } else {
      return true;
    }
  };

  static handleRepeatedLogin(error) {
    if (error.data.detail && error.data.detail === "repeated_login") {
      toastr.error("", "You are already logged in on another device");
      setTimeout(() => {
        logoutUser();
        window.location.href = this.checkForSlug("");
      }, 2000);
    } else if (
      error?.data?.non_field_errors &&
      error?.data?.non_field_errors[0]
    ) {
    } else {
      toastr.error("", "Session Expired");
      setTimeout(() => {
        logoutUser();
        window.location.href = this.checkForSlug("");
      }, 2000);
    }
  }

  static convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  static checkForSlug = (url) => {
    const hasSlug =
      localStorage.getItem("slug") || localStorage.getItem("adminName");
    if (hasSlug) return `/${hasSlug}${url}`;
    else return `/${url}`;
  };
}

export default Util;
