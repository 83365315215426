import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Translate } from "../../localization";
import {
  getCashbackData,
  getCashbackSucess,
  logoutUser,
  setDepositMsg,
  changePassword,
} from "../../store/actions/user.actions";
import ChangePasswordForm from "./Pages/ChangePasswordForm";
import PersonalDetailsForm from "./Pages/Profile";
import "./MyAccount.css";

class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCashbackModal: false,
      showDepositModal: false,
      showWithdrawModal: false,
      oddType: "decimal",
      activeTab: 1,
    };
  }

  componentDidMount() {
    if (this.props.userData?.username && localStorage.getItem("oddType")) {
      this.setState({ oddType: localStorage.getItem("oddType") });
    }
  }

  setActiveTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  componentDidUpdate(prevProps) {}

  logout = () => {
    this.props.logoutUser();
    this.GoToPage("/");
  };

  GoToPage = (path) => {
    this.props.history.push(path);
  };

  showWithdrawFrom = (e) => {
    this.setState({ showWithdrawModal: true });
  };

  showDepositForm = (e) => {
    this.setState({ showDepositModal: true });
  };

  showBonus = () => {
    this.setState({ showCashbackModal: true });
  };

  closeBonus = () => {
    this.setState({ showCashbackModal: false });
  };

  hideDepositForm = () => {
    this.setState({ showDepositModal: false });
  };

  hideWithDrawForm = () => {
    this.setState({ showWithdrawModal: false });
  };

  redirectToTermsPage = () => {
    this.GoToPage("/terms-page");
  };

  handelOddsTypeChange = (e) => {
    this.setState({ oddType: e.target.value }, () => {
      localStorage.setItem("oddType", e.target.value);
    });
  };

  close = () => {
    this.props.setDepositMsg("");
  };

  render() {
    let { isDrawerOpen, language, changePasswordReq, isChangePwdLoading } =
      this.props;
    let { activeTab } = this.state;
    return (
      <>
        <section className="my-account-section">
          <div className="my-account-wrap">
            <PersonalDetailsForm />
            <ChangePasswordForm
              changePasswordReq={changePasswordReq}
              isChangePwdLoading={isChangePwdLoading}
              language={language}
            />
          </div>
        </section>

        <div
          className={`right-section-wrap d-none ${
            !isDrawerOpen ? "sidebar-close" : ""
          }`}
        >
          <section className="my-account-section">
            <div className="my-account-container">
              <div className="d-flex">
                <button
                  className={`btn ${
                    activeTab === 1 ? "btn-primary" : "btn-secondary"
                  } auth-btn m-3 mt-0`}
                  onClick={() => {
                    this.setActiveTab(1);
                  }}
                >
                  {Translate.myProfile}
                </button>
                <button
                  className={`btn ${
                    activeTab === 2 ? "btn-primary" : "btn-secondary"
                  } auth-btn m-3 mt-0`}
                  onClick={() => {
                    this.setActiveTab(2);
                  }}
                >
                  {Translate.changePassword}
                </button>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  {activeTab === 1 && <PersonalDetailsForm />}
                  {activeTab === 2 && (
                    <ChangePasswordForm
                      changePasswordReq={changePasswordReq}
                      isChangePwdLoading={isChangePwdLoading}
                      language={language}
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

MyAccount.propTypes = {
  userData: PropTypes.object,
  logoutUser: PropTypes.func,
  language: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    userData: state.user.data,
    language: state.general.language,
    cashbackData: state.user.cashbackData,
    qr_code: state.user.qr_code,
    depositError: state.user.depositError,
    depositMsg: state.user.depositMsg,
    isDrawerOpen: state.user.isDrawerOpen,
    isChangePwdLoading: state.user.isChangePwdLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => dispatch(logoutUser()),
    getCashbackData: () => dispatch(getCashbackData()),
    getCashbackSucess: () => dispatch(getCashbackSucess()),
    setDepositMsg: (msg) => dispatch(setDepositMsg(msg)),
    changePasswordReq: (oldPassword, newPassword, successToggle) =>
      dispatch(changePassword(oldPassword, newPassword, successToggle)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
