import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setLoginError, submitLogin } from "../../store/actions/user.actions";
import ForgotPassword from "../ForgotPassword";
import LoginForm from "./LoginForm";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openForgotPass: false,
    };
  }

  showForgotPass = () => {
    this.setState({ openForgotPass: true });
  };
  render() {
    let { closeLogin, submitLogin, isLoading } = this.props;
    let { openForgotPass } = this.state;
    return (
      <React.Fragment>
        {!openForgotPass && (
          <LoginForm
            closeLogin={closeLogin}
            submitLogin={submitLogin}
            {...this.props}
            isLoading={isLoading}
            openForgotPass={openForgotPass}
            showForgotPass={this.showForgotPass}
          />
        )}
        {openForgotPass && (
          <ForgotPassword
            closeForgotPassword={() => this.setState({ openForgotPass: false })}
            closeLogin={closeLogin}
          />
        )}
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  userData: PropTypes.object,
  setLoginError: PropTypes.func,
  loginError: PropTypes.string,
  submitLogin: PropTypes.func,
  language: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    userData: state.user.data,
    loginError: state.user.loginError,
    language: state.general.language,
    isLoading: state.user.isLoginLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginError: (error) => dispatch(setLoginError(error)),
    submitLogin: (username, password, toggleSuccess, slug) =>
      dispatch(submitLogin(username, password, toggleSuccess, slug)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
