import { chatRoomRequestTypes } from "config/general.config";
import * as Actions from "../actions/actionTypes";

const initialState = {
  data: null,
  loginError: "",
  signupError: "",
  isLoginLoading: false,
  isSignupLoading: false,
  changePasswordSuccess: false,
  changePasswordError: "",
  cashbackData: null,
  depositAddress: null,
  depositSuccess: null,
  withDrawSuccess: null,
  depositError: null,
  withdrawError: null,
  oddType: "decimal",
  depositMsg: "",
  couponError: "",
  isLoggedIn:
    localStorage.getItem("jwt_access_token") ||
    sessionStorage.getItem("jwt_access_token")
      ? true
      : false,
  isDrawerOpen: false,
  editUserDataSuccess: null,
  editUserDataError: null,
  editUserDataLoading: false,
  isChangePwdLoading: false,
  emailOtpSuccess: false,
  forgotPasswordSuccess: false,
  affiliatedPlayers: [],
  showSignUp: false,
  minDepositAmount: 0,
  isEndChatDialogOpen: false,
  noAvailableStaff: false,
  activeQueries: JSON.parse(localStorage.getItem("Queries") || "[]"),
  pageData: [],
  isChatConnected: false,
  isHomeRoute: true,
  chatDetails: {
    unreadMessagesDetails: {},
    chatConnectionDetails: {},
    chatMessages: {},
  },
  cashAppData: [],
  showOTPLoader: false,
  showOtpField: false,
  cashapplink: null,
  isCashappPaymentRunning: null,
  isCashappPaymentCompleted: null,
  playerWalletAddress: null
};

const userReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_IS_LOGGED_IN: {
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    }
    case Actions.LOGIN_START: {
      return {
        ...state,
        loginError: "",
        isLoginLoading: true,
      };
    }
    case Actions.LOGIN_SUCCESS: {
      return {
        ...state,
        loginError: "",
        isLoginLoading: false,
      };
    }

    case Actions.LOGIN_ERROR: {
      return {
        ...state,
        loginError: action.loginError,
        isLoginLoading: false,
      };
    }
    case Actions.SIGNUP_START: {
      return {
        ...state,
        isSignupLoading: true,
      };
    }

    case Actions.SIGNUP_SUCCESS: {
      return {
        ...state,
        signupError: "",
        isSignupLoading: false,
      };
    }
    case Actions.SIGNUP_ERROR: {
      return {
        ...state,
        signupError: action.signupError,
        isSignupLoading: false,
      };
    }

    case Actions.CHANGE_PASSWORD_START: {
      return {
        ...state,
        isChangePwdLoading: true,
        changePasswordError: "",
        changePasswordSuccess: action.value,
      };
    }
    case Actions.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        isChangePwdLoading: false,
        changePasswordError: "",
        changePasswordSuccess: action.value,
      };
    }

    case Actions.CHANGE_PASSWORD_ERROR: {
      return {
        ...state,
        isChangePwdLoading: false,
        changePasswordSuccess: false,
        changePasswordError: action.error,
      };
    }

    case Actions.DEPOSIT_UNIQUE_KEY: {
      return {
        ...state,
        depositAddress: action.payload,
      };
    }

    case Actions.SET_WALLET_ADDRESS: {
      return {
        ...state,
        playerWalletAddress: action.payload.Wallet_address[0].wallet_address,
      };
    }

    case Actions.DEPOSIT_SUCCESS: {
      return {
        ...state,
        depositSuccess: action.success,
      };
    }

    case Actions.WITHDRAW_SUCCESS: {
      return {
        ...state,
        withDrawSuccess: action.success,
      };
    }

    case Actions.DEPOSIT_ERROR: {
      return {
        ...state,
        depositError: action.depositError,
      };
    }

    case Actions.RESET_DEPOSIT_AMOUNT: {
      return {
        ...state,
        qr_code: null,
        depositError: null,
      };
    }

    case Actions.SET_USER: {
      return {
        ...state,
        data: action.user,
      };
    }

    case Actions.LOGOUT_USER: {
      return {
        ...state,
        data: initialState.data,
        isLoggedIn: false,
        showOtpField: false,
        affiliatedPlayers: [],
      };
    }

    case Actions.SET_CASHBACK_DATA: {
      return {
        ...state,
        cashbackData: action.data,
      };
    }

    case Actions.SET_CASHBACK_SUCESS: {
      return {
        ...state,
        cashbackData: null,
      };
    }

    case Actions.SET_ODD_TYPE: {
      return {
        ...state,
        oddType: action.oddType,
      };
    }
    case Actions.WITHDRAW_ERROR: {
      return {
        ...state,
        withdrawError: action.withdrawError,
      };
    }
    case Actions.DEPOSIT_MSG: {
      return {
        ...state,
        depositMsg: action.depositMsg,
      };
    }
    case Actions.REDEEM_COUPON_ERROR: {
      return {
        ...state,
        couponError: action.couponError,
      };
    }
    case Actions.DRAWER_SET: {
      return {
        ...state,
        isDrawerOpen: !state.isDrawerOpen,
      };
    }

    case Actions.EDIT_USER_DATA: {
      return {
        ...state,
        editUserDataSuccess: action.editUserDataSuccess,
        editUserDataError: action.editUserDataError,
        editUserDataLoading: action.editUserDataLoading,
      };
    }

    case Actions.SEND_EMAIL_OTP: {
      return {
        ...state,
        emailOtpSuccess: action.success,
      };
    }

    case Actions.FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        forgotPasswordSuccess: action.success,
      };
    }

    case Actions.SET_AFFILIATED_PLAYERS: {
      return {
        ...state,
        affiliatedPlayers: action.players,
      };
    }

    case Actions.SHOW_SIGNUP: {
      return {
        ...state,
        showSignUp: action.value,
        showOtpField: false,
      };
    }

    case Actions.MIN_DEPOSIT_AMOUNT: {
      return {
        ...state,
        minDepositAmount: action.amount,
      };
    }

    case Actions.CLEAR_MIN_DEPOSIT_AMOUNT: {
      return {
        ...state,
        minDepositAmount: 0,
      };
    }

    case Actions.CLEAR_DEPOSIT_SUCCESS: {
      return {
        ...state,
        depositSuccess: false,
      };
    }

    case Actions.SET_USER_QUERY: {
      return {
        ...state,
        activeQueries: action.payload,
      };
    }

    case Actions.OPEN_END_CHAT_DIALOG: {
      return {
        ...state,
        isEndChatDialogOpen: true,
      };
    }

    case Actions.CLOSE_END_CHAT_DIALOG: {
      return {
        ...state,
        isEndChatDialogOpen: false,
      };
    }

    case Actions.SET_NO_AVAILABLE_STAFF: {
      return {
        ...state,
        noAvailableStaff: true,
      };
    }

    case Actions.SET_MAIN_DATA: {
      return {
        ...state,
        pageData: action.data,
      };
    }

    case Actions.CLEAR_SLUG: {
      return {
        ...state,
        pageData: [],
      };
    }

    case Actions.SET_HOME_ACTIVE: {
      return {
        ...state,
        isHomeRoute: action.status,
      };
    }

    case Actions.SET_CHAT_CONNECTION_STATUS: {
      return {
        ...state,
        isChatConnected: action.status,
      };
    }

    case Actions.SET_CHAT_CONNECTION_DETAILS: {
      const attriuteType = chatRoomRequestTypes[action.detailType];
      return {
        ...state,
        chatDetails: {
          ...state.chatDetails,
          [attriuteType]: action[attriuteType],
        },
      };
    }

    case Actions.RESET_UNREAD_MESSAGES: {
      return {
        ...state,
        chatDetails: {
          ...state.chatDetails,
          unreadMessagesDetails: {},
        },
      };
    }

    case Actions.CLEAR_CHAT_CONNECTION_DETAILS: {
      return {
        ...state,
        chatDetails: initialState.chatDetails,
      };
    }

    case Actions.SET_CASHAPP_DATA: {
      return {
        ...state,
        cashAppData: action.payload  
      }
    }

    case Actions.SHOW_OTP_MODAL_LOADING: {
      return {
        ...state,
        showOTPLoader: action.payload
      }
    }

    case Actions.SHOW_OTP_MODAL: {
      return {
        ...state,
        showOTPLoader: false,
        showOtpField: true,
        signupError: ""
      }
    }

    case Actions.SET_CASHAPP_LINK:{
      return {
        ...state,
        cashapplink: action.payload
      }
    }

    case Actions.IS_CASHAPP_PAYMENT_RUNNING:{
      return {
        ...state,
        isCashappPaymentRunning: action.payload
      }
    }

    case Actions.IS_CASHAPP_PAYMENT_COMPLETED:{
      return {
        ...state,
        isCashappPaymentCompleted: action.payload
      }
    }

    default:
      return state;
  }
};

export default userReducer;
