/* eslint-disable react/prop-types */

import React, { useState } from "react";
import { IconButton } from "../../node_modules/@material-ui/core/index";

const Validations = (props) => {
  const { touched, error, validationError, warning } = props.props;

  return (
    <>
      <div className="text-danger form-error">
        {touched &&
          ((error && <span className="field_error">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
      {validationError && (
        <p className="error-main">
          {validationError && (
            <span className="field_error">{validationError}</span>
          )}
        </p>
      )}
    </>
  );
};
const renderTextField = (props) => {
  const {
    input,
    type,
    id,
    label,
    className,
    maxLength,
    placeholder,
    meta: { touched, error },
  } = props;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [showPassword, setShowPassword] = useState(false);

  const isPasswordField = type === "password";
  const typeConversion = !isPasswordField
    ? type
    : showPassword
    ? "text"
    : "password";

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {label && <label className="form-label">{label}</label>}
      <div className="form-control-box d-flex position-relative">
        <input
          id={id || ""}
          type={typeConversion}
          placeholder={placeholder || "-"}
          className={className || ""}
          maxLength={maxLength || null}
          {...input}
        />
        {isPasswordField && (
          <IconButton onClick={handleTogglePassword} edge="end">
            {showPassword ? (
              <img src="/assets/icons/visible-password.svg" alt="password" />
            ) : (
              <img src="/assets/icons/hide-password.svg" alt="password" />
            )}
          </IconButton>
        )}
      </div>

      {touched && error && (
        <div className="text-danger form-error">{error}</div>
      )}
    </>
  );
};

export const renderTextArea = (props) => {
  const {
    input,
    label,
    className,
    maxLength,
    placeholder,
    meta: { touched, error },
  } = props;

  return (
    <div>
      {label && <label className="form-label">{label}</label>}
      <div className="form-control-box">
        <textarea
          className={className || ""}
          {...input}
          placeholder={placeholder || ""}
          maxLength={maxLength || null}
          rows="6"
        />
        {touched && error && (
          <div className="text-danger form-error">{error}</div>
        )}
      </div>
    </div>
  );
};

const OnlyErrorMessage = (props) => {
  const {
    meta: { touched, error, warning },
    validationError,
  } = props;
  return (
    <Validations
      props={{
        touched,
        error,
        validationError,
        warning,
      }}
    />
  );
};
export { renderTextField, OnlyErrorMessage };
