const BASE_API = process.env.REACT_APP_API_URL;
const API_URL = process.env.REACT_APP_API_URL + 'api/v0/';
const CASINO_URL = process.env.REACT_APP_API_URL;

export const apiConfig = {
    routes: {
        cashapp: API_URL + 'users/cashapp',
        login: API_URL + 'users/login/',
        signup: API_URL + 'users/signup/',
        getSignUpOtp: API_URL + 'users/otp-signup/',
        getOtp: API_URL + 'users/get-otp',
        verifyOtp: API_URL + 'users/verify-otp',
        sendEmailOtp: API_URL + 'users/send-email-otp',
        forgetPassword: API_URL + 'users/forgot-password',
        resetPassword: API_URL + 'users/reset-password/confirm/',
        getBanner: API_URL + 'users/banners',
        getCurrencies: BASE_API + 'payments/get-currencies/',
        getMatchBanner: API_URL + 'users/match-banner-api',
        changePassword: API_URL + 'users/change-password/',
        user: API_URL + 'players/',
        getAffiliatedPlayers: API_URL + 'users/affiliated-players',
        cashback: API_URL + 'users/cashback/',
        capay: BASE_API + 'payments/cashapp-payment/',
        depositFunds: BASE_API + 'payments/create-nowpayments-deposit/',
        depositUsingQR: BASE_API + 'payments/create-nowpayments-deposit-qr/',
        alchemyPayDeposit: BASE_API + 'payments/alchemypaydeposit',
        getCryptoWalletAddress: BASE_API + 'payments/crypto-wallet',
        getAlchemyPayFiat: BASE_API + 'payments/alchemypay-fiat-query',
        getAlchemyPayCrypto: BASE_API + 'payments/alchemypay-crypto-query',
        withdrawFunds: BASE_API + 'payments/create-nowpayments-withdrawal/',
        cashAppWithdraw: BASE_API + 'payments/create-cashapp-withdrawal/',
        othersWithdraw: BASE_API + 'payments/create-other-withdrawal/',
        deposit: BASE_API + '/payments/create-transaction/',
        setUserTimeZone: API_URL + 'users/set-timezone/',
        withdraw: BASE_API + '/payments/create-withdrawal/',
        placeBet: API_URL + 'betslip/',
        voidBet: BASE_API + '/void-bet/',
        getBetslips: API_URL + 'betslip-list/',
        getSingleBetslip: API_URL + 'betslip/bet-list/',
        getLastBetslip: API_URL + 'betslip/last-bet-slip/',
        getBetLimits: API_URL + 'users/get-bet-limits/',
        getTransactions: API_URL + 'transactions/',
        getCasinoTransactions: API_URL + 'casino-transactions/',
        getWalletTransactions: BASE_API + 'payments/nowpayments-transactions/',
        getLiveCasinoTransactions: API_URL + 'live-casino-transactions/',
        getMinimumAmount: BASE_API + 'payments/min-amount',
        checkValidAddress: BASE_API + 'payments/get-is-valid-address',
        getRecentAddresses: BASE_API + 'payments/get-recent-casino-address/',
        requestAffiliate : API_URL + 'users/affiliate-requests',
        refreshToken: API_URL + 'api-token-refresh/',
        verification: API_URL + 'users/verification/',
        tokenValidation: API_URL + 'users/token-validation/',
        getCasinoCategories: CASINO_URL + 'accounts/get-casino-category',
        getCasinoGames: CASINO_URL + 'accounts/get-category-casino-games',
        getAdminCasinoGames: CASINO_URL + 'accounts/admin-category-casino-games',
        searchCasinoGames: CASINO_URL + 'accounts/game-search', //search games before login
        searchAdminCasinoGames: CASINO_URL + 'accounts/get-game-search', //search games after login as per admin settings
        getCasinoProviders: CASINO_URL + 'accounts/get-casino-vendor',
        getCasinoCategoryGames: CASINO_URL + 'accounts/get-category-casino-games',
        getAdminCasinoCategoryGames: CASINO_URL + 'accounts/admin-category-casino-games',
        getCasinoBanners: CASINO_URL + 'admin_public_details/',
        initCasinoUser: API_URL + 'casino/initiate-user/',
        getGameData: API_URL + 'casino/game',
        accept: API_URL + 'users/terms-and-conditions-privacy-policy-accept/',
        // get: LiveCasinoGames: CASINO_URL + 'live-casino-game-list/',
        getLiveCasinoGames: CASINO_URL + 'game-list/',
        getLiveGameData: CASINO_URL + 'single-game/',
        placeLiveBet: API_URL + 'betslip/place_live_bets/',
        launchGsoftGames: BASE_API + 'GSoft',

        getDeviceLocation: 'https://get.geojs.io/v1/ip/country.json',

        getCashoutData: API_URL + 'betslip/get_cashout_amount/',
        processCashout: API_URL + 'betslip/process_cashout_amount/',
        casinoAPI: CASINO_URL + 'single-game/',
        getLiveStreamData: API_URL + 'live-stream-events/',

        // Virtual sports
        getVirtualSports: CASINO_URL + 'game-list/',

        // Query Contact (chat support)
        submitQuery: API_URL + 'users/csr-query/',
        getRecentMessages: API_URL + 'users/recent-messages/',

        // to search events at sports page
        searchEvent: API_URL + 'search_events/',
        pageContentAPI: API_URL + 'users/footer-pages/',

        // get stats data
        getStatsScore: 'https://api.statscore.com/v2/booked-events?client_id=549&product=prematchcenter&lang=en&mapped_status=mapped&events_details=no',
        spendingLimit: API_URL + 'users/set-max-spend-limit',
        blackOutTime: API_URL + 'users/user-blackout',
        closeAccount: API_URL + 'users/close-account',
        contactUs: API_URL + 'users/contact-us-details',
        aboutUs: API_URL + 'users/about-details',
        privacyPolicy: API_URL + 'users/privacy-policy-details',
        wageringRules: API_URL + 'users/wagering-rules-details',
        playerProtection: API_URL + 'users/player-protection-details',
        termsAndCondition: API_URL + 'users/terms-conditinos',
        promotion: API_URL + 'users/promotions',
        getAds: API_URL + 'users/ads-banner',
        promoCode: API_URL + 'users/check-signup-promo-code/',
        betslipPromoCode: API_URL + 'users/validate-promo-code/',
        getBanners: BASE_API + '/admin_public_details/',
        setSessionInactivity:  API_URL + 'users/set-session-inactivity',
        setReviewBet:  API_URL + 'users/betslip-review-enable-disable/',
        verifyDetail: BASE_API + '/api/v0/users/otp-request/',
        getTempBetslip: API_URL + 'get-temp-betslip',
        createTempBetslip: API_URL + 'create-temp-betslip',
        checkBetslip: API_URL + 'betslip/check-betslip',
        editUserData: API_URL + 'users/userupdate/',
        getFabCasino: CASINO_URL + 'accounts/get-favourite-casino-games',
        updateFabCasino: CASINO_URL + 'accounts/accounts/update-favourite-casino-games',
        comingSoon: API_URL + 'users/extra-page',
        chatQueue: API_URL + 'users/chat-queue/',
        addTipReward: API_URL + 'users/tip/',
        getStaffId: API_URL + 'users/staff-detail',
        //off-market
        getOffMarketGames: API_URL +  'users/get-offmarket-games',
        playerOffMarketGames: BASE_API +  'accounts/get-player-offmarket-games',
        //off-market credit 
        offmarketDeposit: API_URL + 'users/add-credit',
        hasGameApiOffmarketDeposit: API_URL + 'users/game-api-add-credit/',

        offmarketDepositeStatusChecker: API_URL + 'users/game-deposit',
        offmarketWithdrawal: API_URL + 'users/offmarket-withdraw-request/',
        // off-market transactions
        getOffmarketTransactions: API_URL + 'users/offmarket-transactions',
        // Alchemy Transactions
        getAlchemyPayTransactions: BASE_API + 'payments/alchemypay-transactions',

        //footer links
        getCmsLinks: API_URL + 'users/slug',
        getFooterData: API_URL + 'users/footer-detail',
        getSocialMediaLinks: API_URL + 'users/social-links-api',

        // user inactivity tracker
        trackUserActivity: API_URL + 'users/restricted-login/',

        // contact us
        submitContactForm: API_URL + 'users/contact-us/',

        // multitenant View
        getDashBoardGames: API_URL + 'users/bashboard-games/',

        // check slug
        checkSlug: API_URL + 'users/check-slug/',

        // Updated chatroom api's
        getChatRoomDetails: API_URL + 'users/chat-room/',
        clearUnreadMsgCount: API_URL + 'users/read_message_player',
    },
};
