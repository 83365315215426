import { toastr } from "react-redux-toastr";
import genralServices from "../../../services/genralServices";

import {
  PAGE_INFO_START,
  PAGE_INFO_SUCCESS,
  PAGE_INFO_FAILURE,
} from "./actionTypes";

/* Page info action */
export const pageInfoStart = () => {
  return {
    type: PAGE_INFO_START,
  };
};

export const pageInfoSuccess = (payload) => {
  return {
    type: PAGE_INFO_SUCCESS,
    data: payload,
  };
};

export const pageInfoFail = (error) => {
  return {
    type: PAGE_INFO_FAILURE,
    error: error,
  };
};

export const pageInfoRequest = (slug) => {
  return (dispatch) => {
    dispatch(pageInfoStart());
    genralServices
      .getPageInfo(slug)
      .then((response) => {
        if (response?.data) {
          dispatch(pageInfoSuccess(response?.data?.data));
        } else {
          dispatch(pageInfoFail());
        }
      })
      .catch((error) => {
        dispatch(pageInfoFail());
        toastr.error("", error?.response?.data?.message);
      });
  };
};
