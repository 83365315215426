import React, { useEffect, useRef, useState } from "react";
import ChatSupport from "Desktop/MyAccount/ChatSupport/index";
import { useDispatch, useSelector } from "react-redux";
import { closeOffmarketChat, openOffmarketChat } from "store/actions/offmarket.actions";
import * as Actions from "../../store/actions/actionTypes";
import { ChatIcon, CrossIcon } from "icons/index";

const ChatModule = () => {
  const { isOffmarketChatOpen, userQueryMessage } = useSelector(
    (state) => state.offmarket
  );
  const { chatDetails } = useSelector((state) => state.user);
  const messageCount = chatDetails?.unreadMessagesDetails?.unread_message_count;
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [maximizeChat, setMaximizeChat] = useState(false);
  const [query, setQuery] = useState("");
  const countRef = useRef(false);
  const dispatch = useDispatch();
  // Create a ref for the audio element
  const audioRef = useRef(new Audio("/assets/chat-pop.mp3"));

  useEffect(() => {
    if (countRef?.current){
      setUnreadMessageCount(messageCount);
      console.log(messageCount)
      if(messageCount > 0){
        // Play the pop-up sound
        audioRef.current.play();
      }
    }
    else countRef.current = true;
  }, [messageCount]);


  useEffect(() => {
    setQuery(userQueryMessage);
  }, [userQueryMessage]);


  const toggleChat = () =>{
    if (isOffmarketChatOpen) {
      dispatch(closeOffmarketChat());
      localStorage.setItem("was_chat_open", false);
    } else{
      dispatch(openOffmarketChat({message: "", chatGameObj: {}}));
      localStorage.setItem("was_chat_open", true);
    }
  }

  const handleMaximizeChat = () => {
    setMaximizeChat(!maximizeChat);
  };

  const chatClass = maximizeChat ? "maximize-chat-modal" : "chat-window";

  return (
    <div>
        <div className={!isOffmarketChatOpen ? "minimize-chat-modal" : chatClass}>
          <ChatSupport
            userQuery={query}
            setQuery={setQuery}
            handleMaximizeChat={handleMaximizeChat}
            toggleChat={toggleChat}
            maximizeChat={maximizeChat}
          />
        </div>

      {!isOffmarketChatOpen && (
        <div className="chat-support-icon" onClick={toggleChat}>
          <span
            style={{ width: "28px", height: "28px", color: "#000" }}
          >
            <ChatIcon />
          </span>
          {unreadMessageCount > 0 && ( 
            <span className="message-count"> {unreadMessageCount} </span>
          )}
        </div>
      )}

      {isOffmarketChatOpen && (
        <div className="chat-support-icon mobile-hidden" onClick={toggleChat}>
          <span style={{ width: "20px", height: "20px", color: "#000" }}>
            <CrossIcon />
          </span>
        </div>
      )}
    </div>
  );
};

export default ChatModule;
