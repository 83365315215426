import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgetPassword } from "store/actions/user.actions";
import ButtonLoader from "shared/Loader/buttonLoader";
import { Translate } from "localization/index";

const ForgotPassword = ({ closeForgotPassword, closeLogin }) => {
  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const { emailOtpSuccess } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [isLoading]);

  useEffect(() => {
    if (emailOtpSuccess) {
      setLoading(false);
    }
  }, [emailOtpSuccess]);

  const handleCloseModal = () => {
    closeForgotPassword();
    closeLogin();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(forgetPassword(email));
    closeForgotPassword();
  };

  return (
    <div
      class="modal fade login-signup-modal show"
      id="modal-display"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleCloseModal}
            >
              <img
                src="/assets/img/close-icon.svg"
                class="img-fluid"
                alt="Close"
              />
            </button>
            <h1 class="head-title-text">{`${Translate.forgotPassword}`}</h1>
          </div>
          <div class="modal-body">
            <form action="#" class="form-box" onSubmit={(e) => handleSubmit(e)}>
              <div class="row">
                <div class="col-md-12 mx-auto">
                  <div className="input-box">
                    <label class="form-label">
                      {" "}
                      {Translate.pleaseEnterEmail}{" "}
                    </label>
                    <div class="form-control-box">
                      <input
                        type="text"
                        class="form-control"
                        placeholder={Translate.emailAddress}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mx-auto">
                  <div className="btn-box mb-3">
                    <button type="submit" class="gradient-btn w-100">
                      {isLoading ? <ButtonLoader /> : `${Translate.sendEmail}`}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
