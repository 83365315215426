import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { hideSignUpForm, submitSignUp } from "../../store/actions/user.actions";
import SignUpForm from "./SignUpForm";
import Loader from "shared/Loader/index";
import { Translate } from "localization/index";
import Util from "helper/Util";

class SignUp extends Component {
  componentDidMount() {
    if (this.props.from == "comingsoon") {
      sessionStorage.setItem("fromComingSoon", true);
    }
  }

  handleSubmitFinalForm = (data) => {
    const { submitSignUp, whitelabel_admin_uuid, slug } = this.props;
    const agent = localStorage.getItem("slug");
    const { formData, phoneInfo } = data;
    this.setState({ loading: true });
    const obj = {
      // first_name: formData.firstName,
      // last_name: formData.lastName,
      // phone_number: formData.inputPhoneNo,
      // country_code: phoneInfo.code,
      full_name: formData.fullName,
      username: formData.userName,
      password: formData.password,
      email: formData.email,
      otp: formData.otp,
      whitelabel_admin_uuid:
        localStorage.getItem("whitelabel_admin_uuid") || whitelabel_admin_uuid,
    };
    const affiliateCode = localStorage.getItem("affiliateCode");

    if (affiliateCode) {
      obj.affiliate_code = affiliateCode;
    }
    submitSignUp(obj, this.handleCloseModal, agent || slug);
  };

  handleCloseModal = () => {
    let { hideSignUpForm, hideSignUpComingSoon } = this.props;
    if (window.location.pathname.includes("affiliate-invite")) {
      let homeUrl = Util.checkForSlug("/off-market");
      this.props.history.push(homeUrl);
      hideSignUpForm();
      hideSignUpComingSoon && hideSignUpComingSoon();
      localStorage.removeItem("affiliateCode");
    } else {
      hideSignUpForm();
      hideSignUpComingSoon && hideSignUpComingSoon();
    }
  };

  render() {
    let { isLoading, isLoginLoading, showSignUp, footerData  } = this.props;
    const queryParams = new URLSearchParams(window?.location?.search);
    const affiliate_code = queryParams && queryParams.get("affiliate_code");
    if (affiliate_code) {
      sessionStorage.setItem("affiliate_code", affiliate_code);
      sessionStorage.removeItem("affiliate-invite");
    }

    return (
      <Dialog
        onClose={this.handleCloseModal}
        aria-labelledby="login-dialog-title"
        open={showSignUp}
        className="auth__modal login-modal desktop-signup"
        scroll="body"
      >
        <DialogTitle id="login-dialog-title" disableTypography>
          <IconButton
            aria-label="close"
            className="close-modal"
            onClick={this.handleCloseModal}
          >
            <i className=" material-icons fs-22"> close </i>
          </IconButton>
        </DialogTitle>
        <DialogContent className="pt-0">
          <div
            class="modal fade login-signup-modal show"
            id="modal-display"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    onClick={this.handleCloseModal}
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <img
                      src="/assets/img/close-icon.svg"
                      class="img-fluid"
                      alt="CloseImage"
                    />
                  </button>
                  <h1 class="head-title-text">{Translate.createNewAccount}</h1>
                </div>
                <div class="modal-body">
                  {(isLoading || isLoginLoading) && <Loader />}
                  <SignUpForm
                    handleSubmitFinalForm={this.handleSubmitFinalForm}
                    handleCloseModal={this.handleCloseModal}
                  />
                </div>
                <div class="modal-footer">
                  <div className="text-box">
                    <p className="text">
                      Having issues logging in? <br /> Contact{" "}
                      <a href={`mailto:${footerData?.contact}`}>{footerData?.contact}</a> from <br />
                      your registered email for assistance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

SignUp.propTypes = {
  userData: PropTypes.object,
  signupError: PropTypes.string,
  submitSignUp: PropTypes.func,
  hideSignUp: PropTypes.func,
  language: PropTypes.string,
  footerData: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    userData: state.user.data,
    signupError: state.user.signupError,
    language: state.general.language,
    isLoading: state.user.isSignupLoading,
    isLoginLoading: state.user.isLoginLoading,
    showSignUp: state.user.showSignUp,
    whitelabel_admin_uuid: state.user.pageData?.whitelabel_admin_uuid,
    slug: state.general.slug,
    footerData: state.general.footerData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitSignUp: (obj, toggleSuccess, agent) =>
      dispatch(submitSignUp(obj, toggleSuccess, agent)),
    hideSignUpForm: () => dispatch(hideSignUpForm()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUp));
