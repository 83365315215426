import * as Actions from "../actions/actionTypes";
import { differenceInMinutes } from "date-fns";

let sessionStats = sessionStorage.getItem("stats_data");
if (sessionStats) sessionStats = JSON.parse(sessionStats);

let sessionStatsData = null;
// keep data only if it is fetched 30 min before
if (
  sessionStats?.date &&
  differenceInMinutes(new Date(), new Date(`${sessionStats.date}`)) < 30
)
  sessionStatsData = sessionStats.data;

const initialState = {
  loading: false,
  loadingBanners: true,
  language: localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "en",
  country: "",
  translation: null,
  statsData: sessionStatsData,
  offmarketBanners: [],
  promoBanners: [],
  currencies: [],
  alchemyFiatOptions: [],
  alchemyCryptoOptions: [],
  matchBanners: [],
  isShowCasino: true,
  cmsLinks: [],
  advertisements: [],
  countdownDate: "",
  bonus: "",
  countdownEnds: localStorage.getItem("countdownEnds")
    ? JSON.parse(localStorage.getItem("countdownEnds"))
    : false,
  isComingSoonEnabled: false,
  loadingComingSoon: true,
  footerData: {},
  slug: "",
  staffDetails: null,
  isRedirectedLinkIframeOpen: false,
  linkToShowInIframe: null
};

const generalReducer = function (state = initialState, action) {
  switch (action.type) {

    case Actions.SET_REDIRECTED_LINK_IFRAME_STATE: {
      return {
        ...state,
        isRedirectedLinkIframeOpen: action.payload,
      };
    }

    case Actions.SET_TO_REDIRECT_LINK_VALUE: {
      return {
        ...state,
        linkToShowInIframe: action.payload,
      };
    }

    case Actions.SET_LOADING: {
      return {
        ...state,
        loading: action.value,
      };
    }

    case Actions.SET_LANGUAGE: {
      return {
        ...state,
        language: action.value,
      };
    }

    case Actions.SET_COUNTRY_CODE: {
      return {
        ...state,
        country: action.countryCode,
      };
    }

    case Actions.SET_STATS_DATA: {
      return {
        ...state,
        statsData: action.data,
      };
    }

    case Actions.SET_BANNERS: {
      let data = {};
      if (action.isMatchBanner) data = { matchBanners: action.data };
      else data = { banners: action.data };
      return {
        ...state,
        ...data,
      };
    }

    case Actions.SET_OFFMARKET_BANNERS: {
      return {
        ...state,
        offmarketBanners: action.banners,
        loadingBanners: false,
        promoBanners: action.promoBanners
      };
    }

    case Actions.SET_CURRENCIES: {
      return {
        ...state,
        currencies: action.payload,
      };
    }

    case Actions.SET_ALCHEMY_FIAT_OPTIONS: {
      return {
        ...state,
        alchemyFiatOptions: action.payload,
      };
    }

    case Actions.SET_ALCHEMY_CRYPTO_OPTIONS: {
      return {
        ...state,
        alchemyCryptoOptions: action.payload,
      };
    }

    case Actions.SET_TENET_STATUS: {
      return {
        ...state,
        isShowCasino: true,
      };
    }

    case Actions.SET_CMS_LINKS: {
      return {
        ...state,
        cmsLinks: action.links,
      };
    }

    case Actions.SET_FOOTER_DATA: {
      return {
        ...state,
        footerData: action.data,
      };
    }

    case Actions.SET_LINKS: {
      return {
        ...state,
        mediaLinks: action.data,
      };
    }

    case Actions.SET_ADS: {
      return {
        ...state,
        advertisements: action.ads,
      };
    }

    case Actions.COMING_SOON: {
      let { countdownEnds } = state;
      let { bonus, countDownDate, promo, enabled } = action;
      let loadingValue;
      if (enabled !== countdownEnds) {
        localStorage.setItem("countdownEnds", enabled);
        localStorage.setItem("isAdminUser", enabled);
        sessionStorage.setItem("comingSoonEnabled", enabled);
      }
      if (enabled) loadingValue = false;
      return {
        ...state,
        bonus: bonus,
        countDownDate: countDownDate,
        promo: promo,
        isComingSoonEnabled: enabled,
        countdownEnds: enabled,
        loadingComingSoon: loadingValue,
      };
    }

    case Actions.COUNTDOWN_ENDS: {
      return {
        ...state,
        countdownEnds: true,
        isComingSoonEnabled: false,
      };
    }

    case Actions.SET_SLUG: {
      return {
        ...state,
        slug: action.data,
      };
    }

    case Actions.SET_ADMIN: {
      return {
        ...state,
        admin: action.data,
      };
    }

    case Actions.CLEAR_SLUG: {
      return {
        ...state,
        slug: "",
      };
    }

    case Actions.SET_STAFF: {
      return {
        ...state,
        staffDetails: action.staffData,
      };
    }

    default:
      return state;
  }
};

export default generalReducer;
