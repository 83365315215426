import React, { Component } from "react";
import PropTypes from "prop-types";
import Loading from "../Desktop/Common/Loading";
import jwtService from "../services/jwtService";
import { connect } from "react-redux";
import { setUser } from "./actions/user.actions";
import { Translate } from "../localization";
import CircularProgress from "@material-ui/core/CircularProgress";


class Auth extends Component {
  state = {
    waitAuthCheck: true,
  };

  componentDidMount() {
    Translate.setLanguage(this.props.language);
    return Promise.all([this.jwtCheck()]).then(() => {
      this.setState({ waitAuthCheck: false });
    });
  }

  jwtCheck = () => {
    const { userData, language, agent, whitelabel_admin_uuid } = this.props;
    new Promise((resolve) => {
      jwtService.on("onAutoLogin", () => {
        if (!userData) {
          jwtService
            .getUser(language, agent, whitelabel_admin_uuid)
            .then((user) => {
              this.props.setUser(user);
              resolve();
            })
            .catch((error) => {
              resolve();
            });
        }
      });

      jwtService.on("onAutoLogout", (message) => {
        if (message) {
          console.log(message);
        }

        resolve();
      });

      jwtService.on("onNoAccessToken", () => {
        resolve();
      });

      jwtService.init();

      return Promise.resolve();
    });
  };

  render() {
    return this.state.waitAuthCheck ? (
      // <Loading />
      <div className="main-loading-page">
        <CircularProgress size={50} style={{ color: "#D70101" }} />
        <h3>Authenticating...</h3>
      </div>
    ) : (
      <React.Fragment children={this.props.children} />
    );
  }
}

Auth.propTypes = {
  setUser: PropTypes.func,
  language: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    userData: state.user.data,
    language: state.general.language,
    agent: state.general.slug,
    whitelabel_admin_uuid: state.user.pageData?.whitelabel_admin_uuid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => dispatch(setUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
