import axios from "axios";
import jwtDecode from "jwt-decode";
import { format, differenceInMinutes } from "date-fns";
import { apiConfig } from "../config";
import EventEmitter from "../helper/EventEmitter";
import Util from "../helper/Util";

const CancelToken = axios.CancelToken;
let cancel;
const whitelabel_admin_uuid = localStorage.getItem("whitelabel_admin_uuid");
const agent_name = localStorage.getItem("slug");

class jwtService extends EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        let { access_token } = this.getAccessToken();

        if (access_token) {
          let last_request_date = sessionStorage.getItem("last_request_date");

          if (last_request_date) {
            let now = new Date();
            let old_date = new Date(last_request_date);
            // Increase the refresh interval from 5 min to 60 min

            if (differenceInMinutes(now, old_date) >= 60) {
              this.refreshToken(access_token);
            }
            sessionStorage.setItem("last_request_date", now);
          } else {
            let now = new Date();
            sessionStorage.setItem("last_request_date", now);
          }
        }

        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (
            err.response &&
            err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            // if you ever get an unauthorized response, logout the user
            this.emit("onAutoLogout", "Invalid access_token");
            this.setSession(null);
            Util.handleRepeatedLogin(err.response);
          }
          throw err;
        });
      }
    );
  };

  refreshToken = (access_token) => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.refreshToken, {
          token: access_token,
        })
        .then((response) => {
          let token = response.data && response.data.token;

          if (token) {
            if (localStorage.getItem("jwt_access_token")) {
              localStorage.setItem("jwt_access_token", token);
            } else {
              sessionStorage.setItem("jwt_access_token", token);
            }
            axios.defaults.headers.common["Authorization"] = "JWT " + token;
            resolve();
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getCryptoWalletAddress = () => {
    return new Promise((resolve, reject) => {
      const userId = localStorage.getItem("user_id");
      axios
        .get(apiConfig.routes.getCryptoWalletAddress, {
          params: {
            player_id: userId
          }
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getCashAppIdList = () => {
    return new Promise((resolve, reject) => {
      const userId = localStorage.getItem("user_id");
      axios
        .get(apiConfig.routes.cashapp, {
          params: {
            user_id: userId,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  postCashAppPay = (user_cashtag) => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.capay, {
          user_cashtag,
          whitelabel_admin_uuid: whitelabel_admin_uuid,
          agent_name: localStorage.getItem("slug"),
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getCashAppPay = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.routes.capay, {
          params: {
            whitelabel_admin_uuid: whitelabel_admin_uuid,
            agent_name: localStorage.getItem("slug"),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  handleAuthentication = () => {
    let { access_token } = this.getAccessToken();

    if (!access_token) {
      this.emit("onNoAccessToken");
      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit("onAutoLogin", true);
    } else {
      this.setSession(null);
      this.emit("onAutoLogout", "access_token expired");
    }
  };

  signup = (data, language, agent) => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.signup, {
          ...data,
          agent_name: localStorage.getItem("slug") || agent,
        })
        .then((response) => {
          let user = response.data;
          if (user) {
            resolve(user);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getSignUpOtp = (email, username, whitelabel_admin_uuid, agent_name) => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.getSignUpOtp, {
          email,
          username,
          whitelabel_admin_uuid,
          agent_name: localStorage.getItem("slug") || agent_name,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  signInWithUsernameAndPassword = (payload, agent_name) => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.login, {
          ...payload,
          agent_name: localStorage.getItem("slug") || agent_name,
        })
        .then((response) => {
          let user = response.data;
          if (user) {
            this.setSession(user.auth_token, payload?.rememberMe);
            resolve(user);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          // this.logout();
          reject(error);
        });
    });
  };

  changeUserPassword = (oldPassword, newPassword) => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.changePassword, {
          old_password: oldPassword,
          password: newPassword,
          whitelabel_admin_uuid,
          agent_name: localStorage.getItem("slug"),
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  depositFunds = (price_amount, pay_currency, agent, uid) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${apiConfig.routes.depositFunds}`,
          {
            price_amount,
            pay_currency,
            whitelabel_admin_uuid: whitelabel_admin_uuid || uid,
            agent_name: localStorage.getItem("slug") || agent,
          },
          {
            params: {
              unique_id: process.env.REACT_APP_UNIQUE_ID,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  depositUsingQR = (price_amount) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${apiConfig.routes.depositUsingQR}`,
          { price_amount },
          {
            params: {
              unique_id: process.env.REACT_APP_UNIQUE_ID,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  alchemyPayDeposit = (
    amount,
    defaultCountry,
    paymentMethod,
    payWayCode,
    agent,
    uid
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiConfig.routes.alchemyPayDeposit}`, {
          amount,
          country: defaultCountry,
          payment_method: paymentMethod,
          pay_code: payWayCode,
          whitelabel_admin_uuid: whitelabel_admin_uuid || uid,
          agent_name: localStorage.getItem("slug") || agent,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  withdrawFunds = (amount, address, currency, agent_name) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${apiConfig.routes.withdrawFunds}`,
          {
            amount: Number(amount),
            address,
            currency,
            whitelabel_admin_uuid,
            agent_name: localStorage.getItem("slug") || agent_name,
          },
          {
            params: {
              unique_id: process.env.REACT_APP_UNIQUE_ID,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  deposit = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiConfig.routes.deposit}`, params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  othersWithdraw = (amount, method, agent_name) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiConfig.routes.othersWithdraw}`, {
          amount,
          method,
          agent_name: localStorage.getItem("slug") || agent_name,
          whitelabel_admin_uuid,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  cashAppWithdraw = (amount, cashapp_id, agent) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiConfig.routes.cashAppWithdraw}`, {
          amount,
          cashapp_id,
          agent_name: localStorage.getItem("slug") || agent,
          whitelabel_admin_uuid,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  withdraw = (amount, phone_number) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${apiConfig.routes.withdraw}`,
          {
            amount,
            customerRef: phone_number,
          },
          { params: { unique_id: process.env.REACT_APP_UNIQUE_ID } }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  redeemCoupon = (tokenNo, passCode, phoneNo) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${apiConfig.routes.redeemCoupon}`,
          {
            phone_number: phoneNo,
            passcode: passCode,
            token_number: tokenNo,
          },
          {
            params: {
              unique_id: process.env.REACT_APP_UNIQUE_ID,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getUser = (language, agent, uid) => {
    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.routes.user, {
          params: {
            whitelabel_admin_uuid: whitelabel_admin_uuid || uid,
            agent_name: localStorage.getItem("slug") || agent,
          },
        })
        .then((response) => {
          let user = response.data[0];
          if (user) {
            resolve(user);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  trackUserActivity = (status) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiConfig.routes.trackUserActivity}`, {
          operation: status,
          whitelabel_admin_uuid: localStorage.getItem("whitelabel_admin_uuid"),
          agent_name: localStorage.getItem("slug"),
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getBanner = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiConfig.routes.getBanner}`, {
          params: {
            project_id: process.env.REACT_APP_UNIQUE_ID,
          },
        })
        .then((response) => {
          let banners = response.data;

          if (banners) {
            resolve(banners);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getCurrencies = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.routes.getCurrencies)
        .then((response) => {
          let currency = response.data;
          if (currency) {
            resolve(currency);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getAlchemyPayFiat = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.getAlchemyPayFiat)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getAlchemyPayCrypto = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.getAlchemyPayCrypto)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getMatchBanners = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiConfig.routes.getMatchBanner}`, {
          params: {
            project_id: process.env.REACT_APP_UNIQUE_ID,
          },
        })
        .then((response) => {
          let banners = response.data;

          if (banners) {
            resolve(banners);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  setSession = (access_token, rememberMe) => {
    if (access_token) {
      if (rememberMe) {
        localStorage.setItem("jwt_access_token", access_token);
      } else {
        localStorage.setItem("jwt_access_token", access_token);
        sessionStorage.setItem("jwt_access_token", access_token);
      }
      axios.defaults.headers.common["Authorization"] = "JWT " + access_token;
    } else {
      localStorage.removeItem("jwt_access_token");
      sessionStorage.removeItem("jwt_access_token");
      sessionStorage.removeItem("last_request_date");
      localStorage.removeItem("username");
      localStorage.removeItem("user_id");
      localStorage.removeItem("mainAmount");
      delete axios.defaults.headers.common["Authorization"];
      window.location.reload(); // Reload the page when session is removed
    }
  };

  logout = () => {
    this.setSession(null);
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    } else {
      return true;
    }
  };

  getAccessToken = () => {
    let access_token = window.localStorage.getItem("jwt_access_token")
      ? window.localStorage.getItem("jwt_access_token")
      : window.sessionStorage.getItem("jwt_access_token");

    let rememberMeChecked = window.localStorage.getItem("jwt_access_token")
      ? true
      : false;

    return { access_token, rememberMeChecked };
  };

  getPrematches = (sportId) => {
    const url = `${process.env.REACT_APP_API_URL}prematch_sport_data?sport_id=${sportId}`;
    return new Promise((resolve, reject) => {
      axios
        .get(url, {})
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve(error);
        });
    });
  };

  placeBet = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.placeBet, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  placeLiveBet = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.placeLiveBet, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getBetLimits = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.routes.getBetLimits)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getBetslips = (betslip_status, page, params) => {
    cancel && cancel("canceled"); // if request is already pending cancel the earlier
    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.routes.getBetslips, {
          params: {
            bet_slip_status: betslip_status,
            page: page,
            params,
          },
          cancelToken: new CancelToken(function executor(c) {
            cancel = c;
          }),
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getSingleBetslip = (betslip_id, language) => {
    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.routes.getSingleBetslip, {
          params: {
            betslip_id: betslip_id,
            language: language,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getLastBetslip = (betslip_id, language) => {
    let formattedBetslipId = betslip_id;

    if (Array.isArray(betslip_id)) {
      formattedBetslipId = `[${betslip_id.join(",")}]`;
    } else {
      formattedBetslipId = `[${betslip_id}]`;
    }

    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.routes.getLastBetslip, {
          params: {
            betslip_ids: formattedBetslipId,
            language: language,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getTransactions = (tab, params, page) => {
    let from_date =
      params.dateFrom !== null ? format(params.dateFrom, "yyyy-MM-dd") : "";
    let to_date =
      params.dateTo !== null ? format(params.dateTo, "yyyy-MM-dd") : "";
    let base_url = "";
    let timezone_offset;
    if (from_date) {
      timezone_offset = params.dateFrom.getTimezoneOffset() / -60;
    }
    if (tab === "wallet") {
      base_url = apiConfig.routes.getWalletTransactions;
    } else if (tab === "Others") {
      base_url = apiConfig.routes.getTransactions;
    } else if (tab === "casino") {
      base_url = apiConfig.routes.getCasinoTransactions;
    } else if (tab === "offmarket") {
      base_url = apiConfig.routes.getOffmarketTransactions;
    } else if (tab === "alchemypay") {
      base_url = apiConfig.routes.getAlchemyPayTransactions;
    } else if (tab === "live casino") {
      base_url = apiConfig.routes.getLiveCasinoTransactions;
    }

    return new Promise((resolve, reject) => {
      axios
        .get(base_url, {
          params: {
            whitelabel_admin_uuid: localStorage.getItem(
              "whitelabel_admin_uuid"
            ),
            agent_name: localStorage.getItem("slug"),
            from_date: from_date,
            to_date: to_date,
            [tab === "wallet" || tab === "offmarket" || tab === "alchemypay"
              ? "activity_type"
              : "type"]:
              params.activityType === "all" ? "" : params.activityType,
            page: params.page,
            timezone_offset,
            unique_id: process.env.REACT_APP_UNIQUE_ID,
            page_size: params.size,
            ...(tab !== "alchemypay" && { search: params.selectedGame || "" }),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getCashoutData = (betslip_id, total_odds, stake_price) => {
    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.routes.getCashoutData, {
          params: {
            betslip_id: betslip_id,
            total_odds: total_odds,
            stake_price: stake_price,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  processCashout = (betslip_id, cashout_amount) => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.processCashout, {
          betslip_id: betslip_id,
          cashout_amount: cashout_amount,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getLiveStreamData = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.routes.getLiveStreamData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getCashbackData = (userId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.routes.cashback, {
          params: {
            user_id: userId,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  setCashback = (userId) => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.cashback, {
          user_id: userId,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  withdraw = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiConfig.routes.withdraw}`, params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  voidBet = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiConfig.routes.voidBet}`, params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  verification = (phone_number, country_code, via) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiConfig.routes.verification}`, {
          phone_number,
          country_code,
          via,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  token_validation = (phone_number, verification_code, country_code) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiConfig.routes.tokenValidation}`, {
          phone_number,
          verification_code,
          country_code,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getOtp = (phone_number, country_code, is_signup) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiConfig.routes.getOtp}`, {
          phone_number,
          country_code,
          is_signup,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  verifyOtp = (
    phone_number,
    country_code,
    otp,
    username,
    password,
    confirm_password,
    email,
    dob,
    zip_code,
    state,
    full_name,
    complete_address,
    profile_pic,
    user_id_proof,
    inputTermsAndCondition,
    inputPrivacyPolicy,
    affiliate_code,
    applied_promo_code,
    referral_code
  ) => {
    // country_code ='+'+country_code;  // have to send country code with + sign
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiConfig.routes.verifyOtp}`, {
          otp,
          is_signup: true,
          user_data: {
            phone_number,
            country_code,
            username,
            password,
            confirm_password,
            email,
            dob,
            zip_code,
            state,
            full_name,
            complete_address,
            profile_pic,
            user_id_proof,
            affiliate_code,
            applied_promo_code,
            referral_code,
            is_terms_and_conditions_accepted: inputTermsAndCondition,
            is_privacy_policy_accepted: inputPrivacyPolicy,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  verifyLoginOtp = (payload) => {
    // country_code ='+'+country_code;  // have to send country code with + sign
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiConfig.routes.verifyOtp}`, {
          ...payload,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  verifyForgotOtp = (payload) => {
    // country_code ='+'+country_code;  // have to send country code with + sign
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiConfig.routes.verifyOtp}`, {
          ...payload,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  forgetPassword = (email) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiConfig.routes.forgetPassword}`, {
          email,
          whitelabel_admin_uuid: localStorage.getItem("whitelabel_admin_uuid"),
          agent_name: localStorage.getItem("slug"),
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  };

  sendEmailOtp = (username) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiConfig.routes.sendEmailOtp}`, {
          username,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  };

  resetPassword = (uid, token, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiConfig.routes.resetPassword}`, {
          uidb64: uid,
          token,
          password,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  saveSpendingLimit = (limit) => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.spendingLimit, {
          max_spending_limit: +limit,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  submitContact = (payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiConfig.routes.contactUs}`, {
          ...payload,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  submitQuery = (subject, text) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiConfig.routes.submitQuery}`, {
          text,
          subject,
          whitelabel_admin_uuid: localStorage.getItem("whitelabel_admin_uuid"),
          agent_name: localStorage.getItem("slug"),
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getActiveQueries = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiConfig.routes.submitQuery}`, {
          params: {
            whitelabel_admin_uuid: localStorage.getItem(
              "whitelabel_admin_uuid"
            ),
            agent_name: localStorage.getItem("slug"),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  saveBlackOut = (time) => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.blackOutTime, {
          blackout_expire_time: time,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  closeAccount = (reason) => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.closeAccount, {
          is_account_cancelled: true,
          reason_for_cancellation: reason,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  submitPromoCode = (payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiConfig.routes.promoCode}`, {
          promo_code: payload,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  betslipPromoCode = (promoCode, stake, user) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiConfig.routes.betslipPromoCode}`, {
          promo_code: promoCode,
          stake: +stake,
          user,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getBanners = (payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiConfig.routes.getBanners}`, {
          device_type: payload,
          whitelabel_admin_uuid: whitelabel_admin_uuid,
          agent_name: localStorage.getItem("slug"),
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  setSessionInactivity = (payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiConfig.routes.setSessionInactivity}`, {
          is_session_inactivity_enabled: payload,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  setReviewBet = (payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.setReviewBet, {
          is_review_betslip_enabled: payload,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  createTempBetslip = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.createTempBetslip, data, {
          params: {
            unique_id: process.env.REACT_APP_UNIQUE_ID,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  getTempBetslip = (coupon_id, language) => {
    cancel && cancel("canceled");
    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.routes.getTempBetslip, {
          params: {
            coupon_id: coupon_id,
            // language: language,
            // unique_id: process.env.REACT_APP_UNIQUE_ID,
          },
          cancelToken: new CancelToken(function executor(c) {
            cancel = c;
          }),
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  verifyUserDetail = (payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiConfig.routes.verifyDetail}`, {
          ...payload,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  checkBetslip = (coupon_id, language) => {
    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.routes.checkBetslip, {
          params: {
            coupon_id: coupon_id,
            // language: language,
            // unique_id: process.env.REACT_APP_UNIQUE_ID,
          },
        })
        .then((response) => {
          resolve(response.data.response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  editUserData = (payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.editUserData, payload)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  acceptTermPrivacyCondition = (payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.accept, payload)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getAffiliatedPlayers = (username) => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.getAffiliatedPlayers, {
          username: username,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getMinimumAmount = (currency) => {
    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.routes.getMinimumAmount, {
          params: {
            currency_from: currency,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  checkValidAddress = (address, currency) => {
    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.routes.checkValidAddress, {
          params: {
            address: address,
            currency: currency,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getRecentWalletAddresses = (currency, address_count) => {
    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.routes.getRecentAddresses, {
          params: {
            currency,
            address_count,
            whitelabel_admin_uuid,
            agent_name: localStorage.getItem("slug"),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  requestAffiliate = (deposit_count, allDeposits, days, isLifetime) => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.requestAffiliate, {
          no_of_deposit_count: deposit_count,
          is_bonus_on_all_deposits: allDeposits,
          days: days,
          is_lifetime_affiliate: isLifetime,
          whitelabel_admin_uuid,
          agent_name: localStorage.getItem("slug"),
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  submitContactForm = ({ firstName, lastName, phone, email, message }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.submitContactForm, {
          first_name: firstName,
          last_name: lastName,
          email,
          contact_number: phone,
          project_domain: window.location.hostname.includes("localhost")
            ? "www.edgar.nexs.io"
            : window.location.hostname,
          message,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getDashBoardGames = (project_domain) => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.getDashBoardGames, {
          project_domain,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getChatRoomDetails = (request_type, page, agent, uid) => {
    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.routes.getChatRoomDetails, {
          params: {
            request_type,
            whitelabel_admin_uuid: whitelabel_admin_uuid || uid,
            agent_name: localStorage.getItem("slug") || agent,
            page,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  clearUnreadMsgCount = (agent, uid) => {
    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.routes.clearUnreadMsgCount, {
          params: {
            whitelabel_admin_uuid: whitelabel_admin_uuid || uid,
            agent_name: localStorage.getItem("slug") || agent,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

const instance = new jwtService();

export default instance;
