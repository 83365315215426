import { Translate } from "localization/index";

export const commonValidation = (expression, values, errors) => {
  switch (expression) {
    case "email":
      if (!values.email) {
        errors.email = Translate.message.EMPTY_EMAIL;
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = Translate.message.INVALID_EMAIL;
      }
      break;
    case "phone":
      if (!values.phone) {
        errors.phone = Translate.message.PHONE_REQUIRED;
      }
      if (values.phone && values.phone.length < 10) {
        errors.phone = Translate.message.PHONE_LENGTH_REQUIRED;
      }
      if (values.phone && values.phone.length > 10) {
        errors.phone = Translate.message.PHONE_LENGTH_EXCEEDED;
      }
      if (values.phone && Number.isNaN(Number(values.phone))) {
        errors.phone = Translate.message.VALID_PHONE;
      }
      break;
    default:
  }
};

export const simpleLoginValidation = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = Translate.message.USER_NAME_REQUIRED;
  }

  if (!values.password) {
    errors.password = Translate.message.EMPTY_PASSWORD;
  }
  return errors;
};

export const simpleSignupValidation = (values) => {
  const spaces = /\s/;
  const errors = {};

  if (!values.firstName || spaces.test(values.firstName)) {
    errors.firstName = Translate.message.FIRST_NAME_REQUIRED;
  }
  if (!values.lastName || spaces.test(values.lastName)) {
    errors.lastName = Translate.message.LAST_NAME_REQUIRED;
  }
  if (!values.userName || spaces.test(values.userName)) {
    errors.userName = Translate.message.USER_NAME_REQUIRED;
  }
  if ((!/^[a-zA-Z0-9]+$/.test(values.userName)) || (/^[0-9]+$/.test(values.userName))) {
    errors.userName = Translate.message.USER_NAME_SHOULD_BE_ALPHANUMERIC;
  }
  if (!values.fullName) {
    errors.fullName = Translate.message.FULL_NAME_REQUIRED;
  }
  if (!values.password || spaces.test(values.password)) {
    errors.password = Translate.message.EMPTY_PASSWORD;
  } else if (values.password && values.password.length < 8) {
    errors.password = Translate.message.PASSWORD_LENGTH;
  }
  if (!values.confPassword) {
    errors.confPassword = Translate.message.EMPTY_CONFIRM_PASSWORD;
  } else if (values.confPassword !== values.password) {
    errors.confPassword = Translate.message.PASSWORD_NOT_MATCH;
  }
  commonValidation("email", values, errors);
  if (!values.inputPhoneNo) {
    errors.inputPhoneNo = Translate.message.PHONE_REQUIRED;
  }
  if (!values.address) {
    errors.address = Translate.message.ADDRESS_REQUIRED;
  }
  if (!values.state) {
    errors.state = Translate.message.EMPTY_STATE;
  }
  if (!values.country) {
    errors.country = Translate.message.EMPTY_COUNTRY;
  }
  if (!values.zipCode) {
    errors.zipCode = Translate.message.ZIP_CODE_REQUIRED;
  }
  if (!values.captchaValue) {
    errors.captchaValue = Translate.message.CAPTCHA_REQUIRED;
  }
  return errors;
};

export const changePasswordValidation = (values, props) => {
  const errors = {};
  if (!values.inputOldPassword) {
    errors.inputOldPassword = Translate.EMPTY_PASSWORD;
  }

  if (!values.inputNewPassword) {
    errors.inputNewPassword = Translate.EMPTY_PASSWORD;
  } else if (values.inputNewPassword && values.inputNewPassword.length < 8) {
    errors.inputNewPassword = Translate.PASSWORD_LENGTH;
  }

  if (!values.inputConfirmPassword) {
    errors.inputConfirmPassword = Translate.EMPTY_CONFIRM_PASSWORD;
  } else if (values.inputConfirmPassword !== values.inputNewPassword) {
    errors.inputConfirmPassword = Translate.PASSWORD_NOT_MATCH;
  }

  if (values.inputOldPassword && values.inputNewPassword) {
    if (values.inputOldPassword === values.inputNewPassword) {
      errors.inputNewPassword = Translate.OLD_NEW_PASSWORD_SAME;
    }
  }
  return errors;
};

export const contactUsValidation = (values) => {
  const spaces = /\s/;
  const errors = {};

  if (!values.firstName || spaces.test(values.firstName)) {
    errors.firstName = Translate.message.FIRST_NAME_REQUIRED;
  }
  if (!values.lastName || spaces.test(values.lastName)) {
    errors.lastName = Translate.message.LAST_NAME_REQUIRED;
  }
  commonValidation("email", values, errors);
  commonValidation("phone", values, errors);
  if (!values.message) {
    errors.message = Translate.message.DOMAIN_MESSAGE_REQUIRED;
  }
  return errors;
};
