import * as Actions from "../actions/actionTypes";

export const initialState = {
  loading: false,
  isOffmarketChatOpen: JSON.parse(localStorage.getItem("was_chat_open")) || false,
  userQueryMessage: "",
  chatGameObj: {},
  cashappObj: {},
  typeWithdrawRequest: false,
  simpleUserQuery: false,
  openOffmarketModal: false,
  offmarketModalType: "",
  loadingGames: false,
  offMarketGames: [],
  offMarketPlayerGames: [],
  isOffmarketDepositRunning: null,
  isOffmarketDepositCompleted: false,
  isOffmarketDepositFailed: false
};

const offmarketReducer = (state = initialState, action) => {
  switch (action.type) {

    case Actions.IS_OFFMARKET_DEPOSIT_RUNNING: {
      return {
        ...state,
        isOffmarketDepositRunning: action.payload
      };
    }

    case Actions.IS_OFFMARKET_DEPOSIT_COMPLETED: {
      return {
        ...state,
        isOffmarketDepositCompleted: action.payload,
      };
    }

    case Actions.IS_OFFMARKET_DEPOSIT_FAILED: {
      return {
        ...state,
        isOffmarketDepositFailed: action.payload,
      };
    }

    case Actions.OFFMARKET_DEPOSIT_RESET:{
      return{
        ...state,
        isOffmarketDepositRunning: null,
        isOffmarketDepositCompleted: false,
        isOffmarketDepositFailed: false
      }
    }
    
    case Actions.GET_OFF_MARKET_DATA: {
      return {
        ...state,
        loadingGames: true,
      };
    }

    case Actions.GET_OFF_MARKET_GAMES: {
      return {
        ...state,
        loadingGames: false,
        offMarketGames: action.games,
      };
    }

    case Actions.GET_OFF_MARKET_PLAYERS_GAMES: {
      return {
        ...state,
        loadingGames: false,
        offMarketPlayerGames: action.payload,
      };
    }

    case Actions.OPEN_OFFMARKET_MODAL: {
      return {
        ...state,
        openOffmarketModal: true,
        offmarketModalType: action.modal_type,
      };
    }

    case Actions.CLOSE_OFFMARKET_MODAL: {
      return {
        ...state,
        openOffmarketModal: false,
        offmarketModalType: ""
      };
    }

    case Actions.SUBMIT_TRANSACTION: {
      return {
        ...state,
        loading: true,
      };
    }

    case Actions.SET_SUBMIT_TRANSACTION_STATUS: {
      return {
        ...state,
        loading: false,
      };
    }

    case Actions.OPEN_OFFMARKET_CHAT: {
      return {
        ...state,
        isOffmarketChatOpen: true,
        userQueryMessage: action.message,
        
        simpleUserQuery: action?.simpleUserQuery
        ? action?.simpleUserQuery
        : state?.simpleUserQuery,

        typeWithdrawRequest: action?.typeWithdrawRequest
        ? action?.typeWithdrawRequest
        : state?.typeWithdrawRequest,

        cashappObj: action?.cashappObj
        ? action?.cashappObj
        : state?.cashappObj,

        chatGameObj: action?.chatGameObj
          ? action?.chatGameObj
          : state?.chatGameObj,
      };
    }

    case Actions.CLOSE_OFFMARKET_CHAT: {
      return {
        ...state,
        isOffmarketChatOpen: false,
        userQueryMessage: "",
        chatGameObj: {},
        cashappObj: {},
      };
    }

    default:
      return state;
  }
};

export default offmarketReducer;
