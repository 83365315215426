import axios from "axios";
import { apiConfig } from "config/api.config";
import { isMobileOnly } from "react-device-detect";

const whitelabel_admin_uuid = localStorage.getItem("whitelabel_admin_uuid");
class genralServices {
  getDeviceLocation = () => {
    return new Promise((resolve, reject) => {
      fetch(apiConfig.routes.getDeviceLocation).then((response) =>
        resolve(response.json())
      );
    });
  };

  getStatsStatus = (nextUrl) => {
    const url = nextUrl || `${apiConfig.routes.getStatsScore}`;
    return new Promise((resolve, reject) => {
      fetch(url).then((response) => resolve(response.json()));
    });
  };

  getSearchEvents = ({ value, sportId }, nextUrl) => {
    const url =
      nextUrl ||
      `${apiConfig.routes.searchEvent}?participant=${value}&sport_id=${sportId}`;
    return new Promise((resolve, reject) => {
      fetch(url).then((response) => resolve(response.json()));
    });
  };

  getCasinoBanners = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(apiConfig.routes.getCasinoBanners, {
          device_type: isMobileOnly ? "MOBILE_RESPONSIVE" : "DESKTOP",
          whitelabel_admin_uuid: localStorage.getItem("whitelabel_admin_uuid"),
          agent_name: localStorage.getItem("slug"),
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  tenetCasinoStatus = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.routes.tenetCasinoStatus, {
          params: {
            unique_id: process.env.REACT_APP_UNIQUE_ID,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
  getPageInfo = (slugType) => {
    const url = apiConfig.routes.pageContentAPI;
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          params: {
            page_name: slugType,
            whitelabel_admin_uuid: localStorage.getItem(
              "whitelabel_admin_uuid"
            ),
            agent_name: localStorage.getItem("slug"),
          },
        })
        .then((response) => resolve(response))
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  getCmsLinks = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.routes.getCmsLinks, {
          params: {
            unique_id: process.env.REACT_APP_UNIQUE_ID,
            whitelabel_admin_uuid: localStorage.getItem(
              "whitelabel_admin_uuid"
            ),
            agent_name: localStorage.getItem("slug"),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  getFooterData = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.routes.getFooterData, {
          params: {
            whitelabel_admin_uuid: localStorage.getItem(
              "whitelabel_admin_uuid"
            ),
            agent_name: localStorage.getItem("slug"),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  getSocialMediaLinks = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.routes.getSocialMediaLinks, {
          params: {
            whitelabel_admin_uuid: localStorage.getItem(
              "whitelabel_admin_uuid"
            ),
            agent_name: localStorage.getItem("slug"),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  getPromotionBanners = () => {
    const url = apiConfig.routes.promotion;
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          params: {
            whitelabel_admin_uuid: localStorage.getItem(
              "whitelabel_admin_uuid"
            ),
            agent_name: localStorage.getItem("slug"),
          },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  getAdsAndSponsers = () => {
    const url = apiConfig.routes.getAds;
    return new Promise((resolve, reject) => {
      axios
        .post(url, {
          whitelabel_admin_uuid: localStorage.getItem("whitelabel_admin_uuid"),
          agent_name: localStorage.getItem("slug"),
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  comingsoon = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.routes.comingSoon)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  checkSlug = (agent_name, admin_uid) => {
    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.routes.checkSlug, {
          params: {
            white_lable_admin: whitelabel_admin_uuid || admin_uid,
            agent_name,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getStaffId = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.routes.getStaffId, {
          params: {
            whitelabel_admin_uuid: localStorage.getItem(
              "whitelabel_admin_uuid"
            ),
            agent_name: localStorage.getItem("slug"),
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
}
const instance = new genralServices();

export default instance;
