import { PageNotFound } from 'Desktop/Common/PageNotFound';
import StaticInfoPages from 'Desktop/Pages/StaticInfoPages/index';
import FullPageRoute from 'helper/FullPageRoute';
import PrivateRoute from 'helper/PrivateRoute';
import PublicRoute from 'helper/PublicRoute';
import OffMarket from '../Desktop/OffMarket/index';
import NewLandingPage from 'Desktop/Common/NewLandingPage/NewLandingPage';
import { Switch } from 'react-router-dom';
import React, { lazy } from 'react';
const NewPasswordForm = lazy(() => import(/* webpackChunkName: "SignUp" */ 'Desktop/ForgotPassword/NewPasswordForm'));
const MyAffiliates = lazy(() => import(/* webpackChunkName: "MyAffiliates" */'../Desktop/Affiliates/MyAffiliates'));
const ChatSupport = lazy(() => import(/* webpackChunkName: "ChatSupport" */'../Desktop/MyAccount/ChatSupport'));
const MyAccount = lazy(() => import (/* webpackChunkName: "MyAccount" */'../Desktop/MyAccount'));
const Transactions = lazy(() => import (/* webpackChunkName: "Transactions" */'../Desktop/Transactions'));
const Promotion = lazy (()=> import(/* webpackChunkName: "Promotion" */'../Desktop/Promotion'));
const NewDepositModel = lazy(()=> import(/* webpackChunkName: "NewDepositModel" */'Desktop/MyAccount/NewDepositModel'));
const NewWithDrawModel = lazy(()=> import(/* webpackChunkName: "NewWithDrawModel" */'Desktop/MyAccount/NewWithDrawModel'));

const RouteWrapper = ({ type, path, component, ...props }) => {
    switch (type) {
      case 'full-page':
        return <FullPageRoute exact path={path} component={component} {...props} />;
      case 'private':
        return <PrivateRoute exact path={path} component={component} {...props} />;
      default:
        return <PublicRoute exact path={path} component={component} {...props} />;
    }
};

const AdminRoutes = ({ slug }) => {
  const routes = [
    { path: `/${slug}/off-market`, component: OffMarket, type: 'public' },
    { path: `/${slug}/landing-page`, component: NewLandingPage, type: 'full-page' },
    { path: `/${slug}/reset-password/:userId/:token`, component: NewPasswordForm, type: 'public', headerClass: 'header_white', title: 'forgot-password' },
    { path: `/${slug}/my-account/chat-support`, component: ChatSupport, type: 'private', headerClass: 'header_white', title: 'chatSupport' },
    { path: `/${slug}/my-account/transactions`, component: Transactions, type: 'private', headerClass: 'header_white', title: 'transaction' },
    { path: `/${slug}/my-account`, component: MyAccount, type: 'private', headerClass: 'header_white', title: 'myAccount' },
    { path: `/${slug}/deposit`, component: NewDepositModel, type: 'private', headerClass: 'header_white', title: 'deposit' },
    { path: `/${slug}/withdrawl`, component: NewWithDrawModel, type: 'private', headerClass: 'header_white', title: 'withdrawl' },
    { path: `/${slug}/affiliates`, component: MyAffiliates, type: 'private', headerClass: 'header_white', title: 'myAffiliates' },
    { path: `/${slug}/promotion`, component: Promotion, type: 'public', headerClass: 'header_white', title: 'promotion' },
    { path: `/${slug}/page/:pageslug`, component: StaticInfoPages, type: 'public', headerClass: 'header_white', title: '' },
    { path: `/${slug}/affiliate-invite`, component: OffMarket , headerClass:'header_white', title:'' , type: 'public'},
    { path: `/${slug}*`, component: PageNotFound, type: 'full-page' },
  ];

  return (
      <Switch>
          {routes.map((route, index) => (
              <RouteWrapper key={index} {...route} />
          ))}
      </Switch>
  );
};

export default AdminRoutes;