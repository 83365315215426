import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { KeyboardDatePicker } from "@material-ui/pickers/DatePicker/DatePicker";
import MuiPickersUtilsProvider from "@material-ui/pickers/MuiPickersUtilsProvider";
import DateFnsUtils from "@date-io/date-fns";
import PhoneInput from "react-phone-input-2";
import Util from "helper/Util";
import { editUserData } from "store/actions/user.actions";
import { Translate } from "localization/index";
import { sub } from "date-fns";
import ButtonLoader from "shared/Loader/buttonLoader";

const Profile = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.data);
  const editUserDataLoading = useSelector(
    (state) => state.user.editUserDataLoading
  );
  const [fullNameError, setFullNameError] = useState(null);
  const [dobError, setdobError] = useState(null);
  const [mobileError, setMobileError] = useState(null);
  const [addressError, setAddressError] = useState(null);
  const [stateError, setStateError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [user, setUser] = useState({
    id: "",
    fullName: "",
    userName: "",
    firstName: "",
    lastName: "",
    dob: null,
    email: "",
    phone: "",
    zipCode: "",
    profilePic: "",
    country: "",
    state: "",
    address: "",
  });

  const [profilePic, setProfilePic] = useState({
    file: null,
    data: null,
    error: false,
    errorMessage: "",
  });
  const [preview, setPreview] = useState(false);

  const [phoneInfo, setPhoneInfo] = useState({
    code: "1",
    phone: "",
    actualPhoneNumber: "",
  });

  useEffect(() => {
    setUser({
      id: userData?.id,
      fullName: userData?.full_name,
      userName: userData?.username,
      firstName: userData?.first_name,
      lastName: userData?.last_name,
      dob: userData?.dob,
      email: userData?.email,
      zipCode: userData?.zip_code,
      profilePic: userData?.profile_pic,
      country: userData?.country,
      state: userData?.state,
      address: userData?.complete_address,
    });
    setPhoneInfo({
      code: userData?.country_code,
      phone: userData?.mobile_number || userData?.phone_number,
      actualPhoneNumber:
        (userData?.country_code || "1") + userData?.mobile_number,
    });
  }, [userData]);

  useEffect(() => {
    if (!profilePic.file) {
      setPreview(null);
      return;
    }
    const objectUrl = URL.createObjectURL(profilePic.file);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profilePic]);

  const handleDobChange = (date) => {
    setUser({
      ...user,
      dob: date,
    });
  };

  const handleEmailChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleEmailSubmit = (email) => {
    if (/.+@.+\..+/.test(email) === false)
      setEmailError("Please enter a valid email");
  };

  const resetErrors = () => {
    setFullNameError("");
    setdobError("");
    setMobileError("");
    setAddressError("");
    setStateError("");
    setEmailError("");
  };

  const handleChange = (e) => {
    resetErrors("");
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handlePhone = (value, data) => {
    const tempPhoneInfo = { ...phoneInfo };
    tempPhoneInfo.actualPhoneNumber = value;
    tempPhoneInfo.code = data.dialCode;
    tempPhoneInfo.phone = value.slice(data.dialCode.length);
    setPhoneInfo(tempPhoneInfo);
  };

  const handleFileChange = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    let file = e.target.files[0];
    if (!validateFileType("inputProfilePic")) {
      setProfilePic({ error: true, errorMessage: "formatNotSupported" });
    } else {
      const base64Url = await Util.convertToBase64(file);
      setProfilePic({
        ...profilePic,
        file: file,
        data: { base64: base64Url, name: file?.fileName },
        error: false,
        errorMessage: "",
      });
    }
  };

  const validateFileType = (id) => {
    const inputElement = document.getElementById(id);
    const files = inputElement.files;
    if (files.length === 0) {
      return true;
    } else {
      const filename = files[0].name;

      /* getting file extension eg- .jpg,.png, etc */
      const extension = filename.substr(filename.lastIndexOf("."));

      /* define allowed file types */
      const allowedExtensionsRegx = /(\.jpg|\.jpeg|\.png)$/i;

      /* testing extension with regular expression */
      const isAllowed = allowedExtensionsRegx.test(extension);

      if (isAllowed) {
        return true;
      } else {
        return false;
      }
    }
  };

  const checkErrors = () => {
    let hasErrors = false;
    if (!user.fullName) {
      setFullNameError(Translate.required);
      hasErrors = true;
    }
    if (!user.dob) {
      setdobError(Translate.required);
      hasErrors = true;
    }
    if (!phoneInfo.phone) {
      setMobileError(Translate.required);
      hasErrors = true;
    }
    if (!user.email) {
      setEmailError(Translate.required);
      hasErrors = true;
    }
    return hasErrors;
  };

  const onSubmit = () => {
    const originalDate = new Date(user.dob);
    const year = originalDate.getFullYear();
    const month = String(originalDate.getMonth() + 1).padStart(2, "0");
    const day = String(originalDate.getDate()).padStart(2, "0");
    const newDateString = `${year}-${month}-${day}`;
    const check = checkErrors();
    if (!checkErrors()) {
      dispatch(
        editUserData({
          id: user.id,
          email: user.email,
          dob: newDateString,
          state: user.state,
          phone_number: `${phoneInfo.phone}`,
          country_code: phoneInfo.code,
          zip_code: user.zipCode,
          full_name: user.fullName,
          profile_pic: profilePic?.data?.base64 || user?.profilePic,
          complete_address: user.address,
        })
      );
    }
  };

  return (
    <>
      <div className="profile-img-container">
        <div className="img-box">
          {preview ? (
            <img src={preview} className="img-fluid" alt="Profile Image" />
          ) : (
            <img
              src={
                user?.profilePic?.length
                  ? user.profilePic
                  : "/assets/img/profile-img.webp"
              }
              className="img-fluid"
              alt="Profile Image"
            />
          )}
        </div>

        <label className="upload-image" for="inputProfilePic">
          <img
            src="/assets/img/edit-pencil.svg"
            className="img-fluid mx-auto d-block"
            alt="Pencil"
          />
        </label>
        <input
          type="file"
          className="d-none"
          id="inputProfilePic"
          onChange={handleFileChange}
        />
        {profilePic.error && (
          <p className="auth__error sm">{profilePic.errorMessage}</p>
        )}
      </div>

      <div className="card-container">
        <div className="header-container">
          <h1 className="title-text">{Translate.personalDetails}</h1>
        </div>
        <hr className="header-bottom-border" />
        <div className="body-container">
          <form action="#" className="form-box">
            <div className="row">
              <div className="col-md-6">
                <div className="input-box">
                  <label className="form-label"> {Translate.fullname} </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Full Name"
                    name="fullName"
                    value={user.fullName}
                    onChange={handleChange}
                  />
                  {fullNameError && (
                    <p className="auth__error sm">{fullNameError}</p>
                  )}
                </div>
              </div>

              <div className="col-md-6 custom-datepicker">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div className="input-box" style={{ marginBottom: 0 }}>
                    <label className="form-label">
                      {" "}
                      {Translate.dateOfBirth}{" "}
                    </label>
                    <KeyboardDatePicker
                      disableToolbar
                      autoOk
                      className="dob-datepicker mt-0"
                      minDate={sub(new Date(), { years: 100 })}
                      maxDate={sub(new Date(), { years: 18 })}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      variant="inline"
                      id="dob"
                      format="dd/MM/yyyy"
                      value={user.dob}
                      onChange={handleDobChange}
                      DialogProps={{
                        className: "popoverModal",
                        placement: "bottom-end",
                      }}
                      PopoverProps={{
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        placement: "bottom-end",
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>
                {dobError && <p className="auth__error sm">{dobError}</p>}
              </div>
              <div className="col-md-6">
                <div className="input-box">
                  <label className="form-label">
                    {" "}
                    {Translate.emailAddress}{" "}
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email Address"
                    name="email"
                    value={user.email}
                    onChange={handleEmailChange}
                    onSubmit={handleEmailSubmit}
                  />
                  {emailError && <p className="auth__error sm">{emailError}</p>}
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-box">
                  <label className="form-label">
                    {" "}
                    {Translate.mobileNumber}{" "}
                  </label>
                  <PhoneInput
                    country={"us"}
                    buttonClass="button_class"
                    containerClass=""
                    enableSearch
                    onChange={(value, code) => handlePhone(value, code)}
                    value={phoneInfo.actualPhoneNumber}
                  />
                  {mobileError && (
                    <p className="auth__error sm">{mobileError}</p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-box">
                  <label className="form-label"> {Translate.address} </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Street Address"
                    name="address"
                    value={user.address}
                    onChange={handleChange}
                  />
                  {addressError && (
                    <p className="auth__error sm">{addressError}</p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-box">
                  <label className="form-label"> {Translate.state} </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="State"
                    name="state"
                    value={user.state}
                    onChange={handleChange}
                  />
                  {stateError && <p className="auth__error sm">{stateError}</p>}
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-box">
                  <label className="form-label"> {Translate.zipCode} </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Zip Code"
                    name="zipCode"
                    value={user.zipCode}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="form-btn-box">
              <button
                type="button"
                onClick={onSubmit}
                disabled={editUserDataLoading}
                className="gradient-btn"
              >
                {editUserDataLoading ? <ButtonLoader /> : Translate.save}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Profile;
