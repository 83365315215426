import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";
import { reducer as formReducer } from "redux-form";
import generalReducer from "./general.reducer";
import userReducer from "./user.reducer";
import transactionReducer from "./transaction.reducer";
import promotionReducer from "./promotion.reducer";
import extraReducer from "./extra.reducer";
import offmarketReducer from "./offmarket.reducer";

const rootReducer = combineReducers({
  form: formReducer,
  general: generalReducer,
  user: userReducer,
  transaction: transactionReducer,
  toastr: toastrReducer,
  promotion: promotionReducer,
  extraPage: extraReducer,
  offmarket: offmarketReducer,
});

export default rootReducer;
