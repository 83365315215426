import React, { useEffect, useState, useRef  } from "react";
import { withRouter } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import PhoneInput from "react-phone-input-2";
import { renderTextField, OnlyErrorMessage } from "utils/formUtils.js";
import { simpleSignupValidation as validate } from "utils/validate";
import "react-phone-input-2/lib/style.css";
import { Translate } from "localization/index";
// import {
//   CircularProgress,
//   Dialog,
//   DialogContent,
//   DialogTitle,
//   IconButton,
// } from "@material-ui/core";
// import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getOtpSignup } from "store/actions/user.actions";
// import {getFooterData} from "store/actions/general.actions";
import * as Actions from "../../store/actions/actionTypes/user";

const SignUpForm = (props) => {
  const { handleSubmit, change, handleSubmitFinalForm, history } = props;
  const { signupError } = useSelector((state) => state.user);
  const [phoneInfo, setPhoneInfo] = useState({
    code: "1",
    phone: "",
    actualPhoneNumber: "",
  });
  // const [showOtpModal, setShowOtpModal] = useState(false);
  const [formData, setFormData] = useState(false);
  const [otp, setOtp] = useState({
    value: null,
    error: null,
  });
  const [loading, setLoading] = useState(false);
  const [verifyUser, setVerifyUser] = useState({ username: '', full_name: '', email: '' });
  const [canVerify, setCanVerify] = useState(false);
  const [isAnyError, setIsAnyError] = useState({
    username: false,
    full_name: false,
    email: false,
  });
  const [otpVerified, setOTPVerified] = useState(false);
  const [otpData, setOtpData] = useState(null);
  const [otpErr, setOtpErr] = useState(null);
  const dispatch = useDispatch();
  const { showOtpField } = useSelector((state) => state.user);
  const { showOTPLoader } = useSelector((state) => state.user);
  const observer = useRef(null);
  const { slug } = useSelector((state) => state.general);
  const agent = localStorage.getItem("slug");

  const handleVerifyUser = () => {
    if (canVerify) {
      dispatch(getOtpSignup(verifyUser, slug || agent));
      dispatch({type: Actions.SHOW_OTP_MODAL_LOADING, payload: true});
    }
  };

  useEffect(()=>{
    if (verifyUser.username !== "" && verifyUser.email !== "" && verifyUser.full_name !== "" && (Object.values(isAnyError).every((val) => val === false))) {
      setCanVerify(true); // THERE IS NO ERROR on FORM
    } else{
      setCanVerify(false);
    }
  }, [verifyUser, isAnyError])


  useEffect(() => {
    const checkForErrors = () => {
        console.log("Readyt to check error....")
        const errorDivs = document.querySelectorAll('.text-danger.form-error');
        const hasError = errorDivs.length > 0;
        setIsAnyError((prevErrors) => ({
          ...prevErrors,
          username: hasError,
          full_name: hasError,
          email: hasError,
        }));
    };

    // Create a MutationObserver to monitor changes in the document
    observer.current = new MutationObserver(checkForErrors);

    // Configure the observer to monitor the entire document for changes
    observer.current.observe(document, { childList: true, subtree: true });

    // Initial check for errors
    checkForErrors();

    // Clean up the observer when the component unmounts
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);


  const handleCheckOTPLength = (e) => {
    if (otpData !== null && otpData !== "") {
      let otpLen = otpData.split("");
      if (otpLen.length == 6) {
        setOTPVerified(true);
        setOtpErr(null);
      } else {
        setOTPVerified(false);
        setOtpErr("Please enter valid OTP code");
      }
    } else {
      setOTPVerified(false);
      setOtpErr("Please enter valid OTP code");
    }
  };


  // const handleSubmitOtp = () => {
  //   const finalData = {
  //     phoneInfo,
  //     formData,
  //     toggleSuccess,
  //     otp: otp.value,
  //   };
  //   if (otp.value === null || otp.value == "") {
  //     setOtp({ ...otp, error: Translate.EMPTY_OTP });
  //   } else {
  //     handleSubmitFinalForm(finalData);
  //     setOtp({ value: null, error: null });
  //   }
  // };

  // const handleOtp = (value) => {
  //   if (value.match(/ /)) {
  //     setOtp({ ...otp, value: value.replace(/\s/g, "") });
  //     return;
  //   } else {
  //     setOtp({ value, error: null });
  //     change("otp", value);
  //   }
  // };

  // const handleClose = () => {
  //   setShowOtpModal(false);
  //   setOtp({ value: null, error: null });
  // };

  // const handlePhone = (value, data) => {
  //   const tempPhoneInfo = { ...phoneInfo };
  //   tempPhoneInfo.actualPhoneNumber = value;
  //   tempPhoneInfo.code = data.dialCode;
  //   tempPhoneInfo.phone = value.slice(data.dialCode.length);
  //   setPhoneInfo(tempPhoneInfo);
  //   change("inputPhoneNo", tempPhoneInfo.phone);
  // };


  // const toggleSuccess = () => {
  //   history.push("/");
  // };

  return (
    <>
      {showOtpField && !otpVerified && (
        <p
          style={{
            color: "var(--Grey)",
            textAlign: "center",
            marginTop: "-32px",
            marginBottom: "24px",
          }}
        >
          Please enter your otp code
        </p>
      )}

      <form
        onSubmit={handleSubmit((formData) =>
          handleSubmitFinalForm({ formData, phoneInfo })
        )}
        className="form-box"
      >
        {signupError !== null && signupError !== "" ? (
          <div
            style={{
              color: "red",
              fontSize: "15px",
              textAlign: "center",
              paddingBottom: "5px",
            }}
          >
            {JSON.stringify(signupError)}
          </div>
        ) : null}
        <div className="row flex-column">
          {!otpVerified && !showOtpField ? (
            <>
              <div className=" mx-auto">
                <div className="input-box">
                  <Field
                    className="form-control"
                    name="userName"
                    type="text"
                    label={Translate.username}
                    component={renderTextField}
                    placeholder={Translate.username}
                    maxLength={25}
                    onChange={(e) => {
                      setVerifyUser({ ...verifyUser, username: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className=" mx-auto">
                <div className="input-box">
                  <Field
                    className="form-control"
                    name="fullName"
                    type="text"
                    label={Translate.fullName}
                    component={renderTextField}
                    placeholder={Translate.fullName}
                    maxLength={50}
                    onChange={(e) => {
                      setVerifyUser({ ...verifyUser, full_name: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="mx-auto ">
                <div className="input-box">
                  <Field
                    className="form-control"
                    name="email"
                    type="text"
                    label={Translate.emailAddress}
                    component={renderTextField}
                    placeholder={Translate.emailAddress}
                    maxLength={45}
                    onChange={(e) => {
                      setVerifyUser({ ...verifyUser, email: e.target.value })
                    }}
                  />
                </div>
              </div>
            </>
          ) : null}

          {/* continue button */}
          {/* {canVerify && !showOtpField ? ( */}
          {!showOtpField ? (
            <div className="col-md-6 pt-2 mx-auto d-flex align-items-center justify-content-center w-75">
              <button
                type="button"
                className={`w-100 gradient-btn`}
                style={{color: canVerify ? "var(--brown)" : "#32181080"}}
                onClick={handleVerifyUser}
                disabled={!canVerify}
              >
                {Translate.continue}
              </button>
            </div>
          ) : null}

          {showOTPLoader ? (
            <div style={{
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              zIndex: "9999",
              backdropFilter: "blur(4px)"
            }}>
              <div className="loader"></div>
            </div>
          ) : null}

          {showOtpField && !otpVerified ? (
            <>
              <div className=" mx-auto justify-content-center">
                <div className="input-box">
                  <Field
                    className="form-control"
                    name="otp"
                    type="text"
                    label={"OTP Code"}
                    component={renderTextField}
                    placeholder={"OTP Code"}
                    maxLength={6}
                    onChange={(e) => setOtpData(e.target.value)}
                  />
                </div>
                {otpErr ? (
                  <div
                    style={{
                      color: "red",
                      fontSize: "14px",
                      textAlign: "center",
                      paddingBottom: "5px",
                    }}
                  >
                    {otpErr}
                  </div>
                ) : null}
              </div>
              <div className="col-md-12 pt-2 mx-auto d-flex align-items-center justify-content-center">
                <button
                  type="button"
                  className="gradient-btn"
                  onClick={handleCheckOTPLength}
                >
                  Verify OTP
                </button>
              </div>
            </>
          ) : null}

          {otpVerified ? (
            <>
              {/* <div className=" mx-auto">
                <div className="input-box">
                  <label className="form-label">
                    {" "}
                    {Translate.phoneNumber}{" "}
                  </label>
                  <PhoneInput
                    inputProps={{
                      name: "inputPhoneNo",
                    }}
                    country={"us"}
                    buttonclassName="button_class"
                    containerclassName="pnone-input"
                    enableSearch
                    onChange={(value, code) => handlePhone(value, code)}
                    value={phoneInfo.actualPhoneNumber}
                  />
                  <Field name="inputPhoneNo" component={OnlyErrorMessage} />
                </div>
              </div> */}
              <div className=" mx-auto">
                <div className="input-box">
                  <Field
                    className="form-control"
                    name="password"
                    type="password"
                    label={Translate.password}
                    component={renderTextField}
                    placeholder={Translate.password}
                    maxLength={25}
                  />
                </div>
              </div>
              <div className=" mx-auto">
                <div className="input-box">
                  <Field
                    className="form-control"
                    name="confPassword"
                    type="password"
                    label={Translate.confirmPassword}
                    component={renderTextField}
                    placeholder={Translate.confirmPassword}
                    maxLength={25}
                  />
                </div>
              </div>
            </>
          ) : null}
        </div>
        {otpVerified ? (
          <div className="row justify-content-center">
            <div className=" mx-auto">
              <div className="btn-box">
                <button type="submit" className="gradient-btn w-100">
                  {Translate.createNewAccount}
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </form>
      {/* {showOtpModal && (
        <OTPModal />
      )} */}
    </>
  );
};

export default reduxForm({
  form: "signup-form",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: false,
  validate,
})(withRouter(SignUpForm));
