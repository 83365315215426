export const en = {
  en: "EN",
  tr: "TR",
  fr: "FR",
  de: "DE",
  ru: "RU",
  nl: "NL",
  th: "TH",
  it: "IT",
  ja: "JA",
  sv: "SV",
  es: "ES",
  ko: "KO",
  pt: "pt",
  zh: "ZH",
  sports: "Sports",
  sportsBook: "Sport Book",
  live: "LIVE",
  inplay: "Live",
  today: "Today",
  upcoming: "Upcoming",
  tomorrow: "Tomorrow",
  highlights: "Highlights",
  results: "Results",
  lastMinute: "Last Minute",
  emailAddress: "Email Address",
  address: "Address",
  zipCode: "Zip Code",
  dateOfBirth: "Date of Birth",
  mobileNumber: "Mobile Number",
  state: "State",
  wallet: "Wallet",
  liveCasino: "Live Casino",
  virtualSports: "VirtualSports",
  casino: "Casino",
  offmarket: "Off market",
  alchemyPay: "Alchemy pay",
  myBets: "My Bets",
  deposit: "Topup", //Replace the term deposit as a Topup according to the new glossary.
  walletAddress: "Enter Wallet Address",
  validAddress: "Please Enter a valid address.",
  home: "Home",
  login: "Login",
  signup: "Sign up",
  phoneNumber: "Phone Number",
  betslip: "Betslip",
  search: "Search",
  searchHere: "Search here",
  transaction: "Transactions",
  changePassword: "Change password",
  updatePassword: "Update password",
  forgotPassword: "Forgot password?",
  getOTP: "Send OTP",
  sendEmail: "Send Email",
  setnewPass: "Set New Password",
  logout: "Logout",
  single: "Single",
  multiple: "Multiple",
  possibleWin: "Possible win",
  amount: "Amount",
  gameName: "Game Name",
  orderNo: "Order Number",
  crypto: "Cryptocurrency",
  network: "Network",
  paymentMethod: "Payment method",
  ok: "Ok",
  totalOdds: "Total odds",
  odd: "Odd",
  totalPossibleWin: "Total possible win",
  placeBet: "Place bet",
  bet: "Bet",
  fastBet: "Fast Bet",
  fastBet1: "Fast",
  fastBet2: "bet",
  username: "Username",
  firstName: "First Name",
  lastName: "Last Name",
  fullName: "Full Name",
  password: "Password",
  passwordAgain: "Confirm Password",
  stayLoggedIn: "Stay logged in",
  required: "This field is required.",
  no_promo_data: "No promotion details found !!",
  all: "All",
  submitOtp: "Submit OTP",
  enterOtp: "Enter OTP",
  "in game": "In Game",
  won: "Won",
  lost: "Lost",
  refund: "Refund",
  date: "Date",
  winAmount: "Win amount",
  about: "ABOUT",
  privacy_policy: "PRIVACY POLICY",
  terms_and_condition: "TERMS AND CONDITIONS",
  affiliates: "AFFILIATES",
  faq: "FAQ",
  new_page_test: "NEW PAGE TEST",
  stake: "Stake",
  pick: "Pick",
  myAccount: "My Account",
  football: "Soccer",
  basketball: "Basketball",
  iceHockey: "Ice Hockey",
  tennis: "Tennis",
  volleyball: "Volleyball",
  boxing: "Boxing",
  americanFootball: "American Football",
  baseball: "Baseball",
  hockey: "Hockey",
  tableTennis: "Table Tennis",
  badminton: "Badminton",
  snooker: "Snooker",
  cricket: "Cricket",
  futsal: "Futsal",
  golf: "Golf",
  handball: "Handball",
  rugbyLeagues: "Rugby leagues",
  pickCanNotBeCombined: "This pick can not be combined",
  matchStopped: "Match stopped",
  favorites: "Favorites",
  cashout: "Cashout",
  greaterThanZero: "Amount should be greater than zero.",
  minAmountRequired: "The minimum amount required is",
  affiliateDepositCount: "Request affiliate bonus for deposit count:",
  requestAffiliateHeading: "Request Elevated Access",
  enableAllDeposits: "Enable for all deposits",
  days: "Number of Days",
  noAffiliateFound: "No Affiliated Players found",
  submit: "Submit",
  expDate: "Current Expiry Date",
  requestForLifetime: "Request affiliation for lifetime",
  depositCountRequired: "Deposit count required",
  durationRequired: "Affiliate duration required",
  "in progress": "In Progress",
  rejected: "Rejected",
  tip: "Tip",
  noGoal: "No Goal",
  yes: "Yes",
  over: "Over",
  under: "Under",
  even: "Even",
  areYouSureWantCashout: "Are you sure you want to Cashout?",
  areYouSureWantPayTicket: "Are you sure you want to Pay ticket?",
  no: "No",
  bonus: "Bonus",
  termsAndConditon: "Terms and Conditions",
  aboutUs: "About us",
  contactUs: "Contact us",
  socialMedia: "Social Media",
  payments: "Payments",
  howPlacebet: "How to place bet",
  // new translation below
  credit: "Credit",
  debit: "Debit",
  pay: "Pay",
  pending: "Pending",
  paySuccess: "Success",
  payFail: "Failed",
  finished: "Finished",

  casinoDescription: "The Top Secret & Highly Classified Crypto Casino.",
  withdraw: "Withdraw",
  totalOdd: "Total Odd",
  totalStake: "Total Stake",
  topGames: "Top Games",
  allGames: "All Games",
  canNotBeCombined: "This Pick can not be combined",
  betSettled: "Bet Settled",
  betSuspended: "Bet Suspended",
  insufficientBalance: "Insufficient balance",
  oldPassword: "Old Password",
  newPassword: "New Password",
  confirmPassword: "Confirm New Password",
  pleaseEnterUsername: "Please enter your username",
  pleaseEnterEmail: "Please enter email",
  enterAmount: "Enter Amount",
  qrScan:
    "Attention: the QRCode expires in 5 minutes. If needed, please generate another QRCode for payment.",
  change: "Change",
  continue: "Continue",
  cashAppPay: "Pay using CashApp",
  fromDate: "From Date",
  toDate: "To Date",
  activityType: "Activity Type",
  searchGame: "Search Game",
  show: "Show",
  gameType: "Game Type",
  repeatBet: "Repeat Bet",
  someThingWrong: "Something Went Wrong",
  status: "Status",
  coupon: "Coupon ID",
  acceptOddChangesText: "You need to accept changes to be able to place a bet",
  turnOffAway: "Turn off anyway",
  acceptOddChanges: "Accept odd changes",
  maxWinAmount: "Max win amount",
  maxOdd: "Max odd",
  dateTime: "Date/Time",
  type: "Type",
  refreshCashout: "Refresh Cashout",
  vendor: "Vendor",
  stakeLowerThen1: "The stake must be less then ",
  stakeGreaterThen1: "The stake must be greater then ",
  nothingFound: "Nothing Found",
  noLiveMatch: "No live match is available",
  casinoGames: "Casino Games",
  payout: "Payout",
  chatStartMessage:
    "Need help? Start a conversation with our customer care representative, we will be happy to assist you.",
  chatQuery: "Tell us how can we help you?",
  startNewChat: "New Conversation",
  chatEnded: "Chat Ended !!",
  queueMessage:
    "Please wait for a while you are in QUEUE! You will be connected to a representative soon.",
  queuePosition: "Your queue position",
  missingTip: "Missing Tip",
  pleaseAddAtleast: "Please add atleast",
  moreEvents: "more events",
  ESports: "ESports",
  KSports: "KSports",
  promotion: "Promotions",
  customerServicePage: "Customer Service Page",
  decimal: "Decimal",
  fraction: "Fraction",
  americanOdds: "American Odds",
  phoneNo: "Phone No",
  readAllTerms: "Please read all the terms and condition",
  totalWin: "Total Payout",
  bettingRules: "Betting Rules",
  allTransactions: "All Transactions",
  trans_type: "Transaction Type",
  responsibleGaming: "Responsible Gaming",
  redeemCoupon: "Redeem Coupon",
  back: "Back",
  tokenNo: "Token No",
  passCode: "Passcode",
  enterTokenNo: "Enter Token No",
  enterPassCode: "Enter Passcode",
  TXID: "TXID",
  selectLanguage: "Select Language",
  selectCurrency: "Select Cryptocurrency",
  payMethods: "Select Payment Method",
  selectNetwork: "Select Network",
  close: "Close",
  language: "Language",
  marketsText: "Markets",
  comingsoon: "Coming soon",
  thisMonth: "This Month",
  thisYear: "This Year",
  featuredMatch: "Featured Matches",
  liveMatches: "Live Matches",
  preMatches: "Pre-Matches",
  personalDetails: "Personal Details",
  save: "Save",
  others: "Others",
  EMPTY_PASSWORD: "Please provide password",
  PASSWORD_LENGTH: "Password must contain atleast 8 characters",
  PASSWORD_NOT_MATCH: "Password and Confirm password must be same.",
  OLD_NEW_PASSWORD_SAME: "Old and new passwords cannot be same",
  EMPTY_CONFIRM_PASSWORD: "Please provide confirm password",
  languageOption: "Language Option",
  createNewAccount: "Create New Account",
  myProfile: "My Profile",
  myAffiliates: "My Affiliates",
  created: "Created",
  fullname: "Full Name",

  // new added
  addCashApp: 'Add $CA ID',
  removeCashApp: 'Remove $CA ID',
  selectCashApp: 'Select $CA ID',
  method: 'Your Method',
  cashApp: '$CA',
  HowToStart: "How to get started ?",
  GetStartStep1: "Sign up first",
  GetStartStep2: "Deposit money",
  GetStartStep3: "Choose a game & play",
  GetStartStep1desc: "Begin your journey by signing up for an account.",
  GetStartStep2desc: "Deposit funds securely to unlock the full gaming experience.",
  GetStartStep3desc: "Explore our wide selection of games and start playing your favorites.",
  featuredGames: "Featured Games",
  myGames: "My Games",
  MainBalance: "Main Balance",
  cryptocur: "Crypto",
  bonusEarned: "Bonus Earned",
  betaVersionMessage: "We are currently running a beta version. If you face any issues, please contact us via Telegram:",
  capay: "CA Pay",
  noCashAppId: "You haven’t registered any $cashtag yet. Please register first.",
  noCashAppIdNote: "Note: Only payments from registered $cashtag gets deposited.",
  selectCashTag: "Please select a $cashtag for your payment.",
  selectCashTagNote: "Note: Payment must be done from selected $cashtag only.",
  cashappPaymentPending: "Your payment is pending. Please allow sometime to process after payment.",
  cashAppPaymentCompleted: "is deposited via Cashapp successfully.",
  cashAppPaymentFailed: "The deposit via Cashapp was unsuccessful.",
  
  // new Translation end
  // new Translation end

  message: {
    LOGIN_SUCCESS: "Login successful",
    FORGOT_SUCCESS: "Password send to your registered email address.",
    WrongEAndPwd: "Please provide valid email id or password",
    EMPTY_EMAIL: "Please provide email",
    EMPTY_PASSWORD: "Please provide password",
    INVALID_EMAIL: "Please provide valid email",
    INVALID_ADDRESS: "Please enter valid wallet address",
    NAME_REQUIRED: "Please provide name",
    FIRST_NAME_REQUIRED: "Please provide first name",
    LAST_NAME_REQUIRED: "Please provide last name",
    DOMAIN_MESSAGE_REQUIRED: "Please provide the domain message",
    USER_NAME_REQUIRED: "Please provide user name",
    USER_NAME_SHOULD_BE_ALPHANUMERIC: "Username must be alphanumeric",

    FULL_NAME_REQUIRED: "Please provide full name",
    PHONE_REQUIRED: "Please provide phone number",
    PHONE_LENGTH_REQUIRED: "Phone number must contain 10 digits",
    PHONE_LENGTH_EXCEEDED: "Phone number must be of 10 digits only.",
    ONLY_CHARECTERS: "Please input alphabet characters only",
    VALID_PHONE: "Phone number should contain only numbers",
    EMPTY_CONFIRM_PASSWORD: "Please provide confirm password",
    PASSWORD_LENGTH: "Password must contain atleast 8 characters",
    PASSWORD_NOT_MATCH: "Password are not matching",
    EMPTY_ACCESS_TOKEN: "Please provide access token",
    EMPTY_OTP: "Please provide otp",
    SIMPLE_SIGNUP_SUCCESS: "You have been registered successfully",
    NUMBER_REQUIRED: "Please provide only number",
    DOB_REQUIRED: "Please provide date of birth",
    ADDRESS_REQUIRED: "Please provide address",
    EMPTY_STATE: "Please provide state",
    EMPTY_CITY: "Please provide city",
    EMPTY_COUNTRY: "Please provide country",
    SOME_THING_WRONG: "Some thing went wrong",
    PROJECT_NAME_REQUIRED: "Please provide project name",
    PROJECT_DOMAIN_REQUIRED: "Please provide project domain",
    PROJECT_DOMAIN_INVALID: "Please provide valid domain",
    FRONTEND_DOMAIN_INVALID: "Please provide valid domain",
    CLIENT_PUBLIC_KEY_REQUIRED: "Please provide valid domain",
    BANNER_TITLE_REQUIRED: "Please provide banner title",
    BANNER_TYPE_REQUIRED: "Please provide banner type",
    BANNER_CATEGORY_REQUIRED: "Please provide banner category",
    ZIP_CODE_REQUIRED: "Please provide zip code",
    CAPTCHA_REQUIRED: "Please select captcha",
    FILE_REQUIRED: "Please provide file",
  },

  marketNames: {
    1: "1x2",
    2: "Under/Over",
    238: "Remaining match",
    59: "Next Goal",
    7: "Double Chance",
    52: "Draw No Bet",
    226: "12 Including Overtime",
    13: "European Handicap",
    4: "HT/FT",
    17: "Both Teams To Score",
    59: "Next Goal",
    28: "Under/Over Including Overtime",
    247: "Remaining 1st Half",
  },
  tabNames: {
    All: "All",
    "Main Markets": "Main Markets",
    Totals: "Totals",
    "1st Half": "1st Half",
    "2nd Half": "2nd Half",
    "Home Team": "Home Team",
    "Away Team": "Away Team",
    "All Periods": "All Periods",
    Results: "Results",
    "Home / Away": "Home / Away",
    Score: "Score",
    Corners: "Corners",
  },
  oddTranslation: {
    Yes: "Yes",
    No: "No",
    Under: "Under",
    Over: "Over",
    "1 And Under": "1 + Under",
    "X And Under": "X + Under",
    "2 And Under": "2 + Under",
    "1 And Over": "1 + Over",
    "X And Over": "X + Over",
    "2 And Over": "2 + Over",
    "1 And Both Teams To Score": "1 + Yes",
    "X And Both Teams To Score": "X + Yes",
    "2 And Both Teams To Score": "2 + Yes",
    "Both Teams Not To Score": "+ No",
    "To Nil": "+ No",
    "Both Teams To Score And 1": "1 + Yes",
    "Both Teams To Score And X": "X + Yes",
    "Both Teams To Score And 2": "2 + Yes",
    Odd: "Odd",
    Even: "Even",
    "Or More": "+",
    "Any Other Score": "5+:X/X:5+",
    "No Goal": "No Goal",
    "Under And Yes": "Under + Yes",
    "Over And Yes": "Over + Yes",
    "Under And No": "Under + No",
    "Over And No": "Over + No",
    "No Corner": "No Corner",
    "1st Half": "1st Half",
    "2nd Half": "2nd Half",
  },
};
