import React from "react";

const ButtonLoader = () => {
  return (
    <span
      className="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    />
  );
};

export default ButtonLoader;
