import * as Actions from "../actions/actionTypes";
import jwtService from "../../services/jwtService";
import genralServices from "../../services/genralServices";
import { toastr } from "react-redux-toastr";

// Show loading overlay
export const setLoading = (value) => {
  return (dispatch) => {
    dispatch({ type: Actions.SET_LOADING, value });
  };
};

// Set language
export const setLanguage = (value) => {
  return (dispatch) => {
    dispatch({ type: Actions.SET_LANGUAGE, value });
  };
};

export const getCasinoBanners = () => {
  return (dispatch) => {
    genralServices
      .getCasinoBanners()
      .then((data) => {
        dispatch({
          type: Actions.SET_OFFMARKET_BANNERS,
          banners: data.banners.homepage,
          promoBanners: data.banners.promotional,
        });
      })
      .catch((error) => {
        toastr.error("Unable to fetch banners");
      });
  };
};

export const getCurrencies = () => {
  return (dispatch) => {
    jwtService
      .getCurrencies()
      .then((data) => {
        dispatch({ type: Actions.SET_CURRENCIES, payload: data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getAlchemyPayFiat = () => {
  return (dispatch) => {
    jwtService
      .getAlchemyPayFiat()
      .then((data) => {
        dispatch({ type: Actions.SET_ALCHEMY_FIAT_OPTIONS, payload: data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getAlchemyPayCrypto = () => {
  return (dispatch) => {
    jwtService
      .getAlchemyPayCrypto()
      .then((data) => {
        dispatch({ type: Actions.SET_ALCHEMY_CRYPTO_OPTIONS, payload: data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getDeviceLocation = () => {
  return (dispatch) => {
    genralServices.getDeviceLocation().then((data) =>
      dispatch({
        type: Actions.SET_COUNTRY_CODE,
        countryCode: data.country,
      })
    );
  };
};

export const getTenentStatus = () => {
  return (dispatch) => {
    genralServices
      .tenetCasinoStatus()
      .then((res) => {
        dispatch({ type: Actions.SET_TENET_STATUS, isShowCasino: res });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getCmsLinks = () => {
  return (dispatch) => {
    genralServices
      .getCmsLinks()
      .then((data) => {
        dispatch({ type: Actions.SET_CMS_LINKS, links: data.response[0] });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getFooterData = () => {
  return (dispatch) => {
    genralServices
      .getFooterData()
      .then((data) => {
        dispatch({ type: Actions.SET_FOOTER_DATA, data: data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getSocialMediaLinks = () => {
  return (dispatch) => {
    genralServices
      .getSocialMediaLinks()
      .then((data) => {
        dispatch({ type: Actions.SET_LINKS, data: data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getAdsAndSponsers = () => {
  return (dispatch) => {
    genralServices
      .getAdsAndSponsers()
      .then((data) => {
        dispatch({ type: Actions.SET_ADS, ads: data.banners.homepage });
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const comingSoon = (callback) => {
  return (dispatch) => {
    genralServices.comingsoon().then((data) => {
      dispatch({
        type: Actions.COMING_SOON,
        bonus: data.bonus,
        countDownDate: data.datetime,
        promo: data.promo,
        enabled: data.enabled,
      });
      if (callback) callback();
    });
  };
};

export const expireCountDown = () => {
  return (dispatch) => {
    dispatch({ type: Actions.COUNTDOWN_ENDS });
  };
};

export const checkSlug = (agent, adminUid, history) => {
  let slug = agent.split("/")[0];
  return (dispatch, getState) => {
    genralServices
      .checkSlug(slug, adminUid)
      .then((response) => {
        if (!response?.is_admin) {
          localStorage.setItem("slug", response?.slug);
          dispatch({ type: Actions.SET_SLUG, data: response?.slug });
        } else {
          localStorage.setItem("adminName", response?.slug);
          dispatch({ type: Actions.SET_ADMIN, data: response?.slug });
        }
      })
      .catch((err) => {
        history.push("/not-found");
      });
  };
};

export const getStaffId = () => {
  return (dispatch) => {
    genralServices
      .getStaffId()
      .then((data) => {
        sessionStorage.setItem("staffId", data?.id);
        dispatch({ type: Actions.SET_STAFF, staffData: data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
