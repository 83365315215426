/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Field, reduxForm, reset } from "redux-form";
import { renderTextField } from "utils/formUtils.js";
import { changePasswordValidation as validate } from "utils/validate";
import ButtonLoader from "shared/Loader/buttonLoader";
import { Translate } from "localization/index";
import { useHistory } from "../../../../node_modules/react-router-dom/cjs/react-router-dom.min";

const ChangePasswordForm = (props) => {
  const dispatch = useDispatch();
  const { changePasswordReq, handleSubmit, isChangePwdLoading, initialize } =
    props;
  useEffect(() => {
    initialize({
      inputOldPassword: "",
      inputNewPassword: "",
      inputConfirmPassword: "",
    });
  }, []);

  const history = useHistory();
  const successToggle = () => {
    dispatch(reset("change-pwd-form"));
  };
  const simpleChangePwdSubmit = (formData) => {
    changePasswordReq(
      formData.inputOldPassword,
      formData.inputNewPassword,
      successToggle
    );
  };

  return (
    <div className="card-container">
      <div className="header-container">
        <h1 className="title-text">{Translate.changePassword}</h1>
      </div>
      <hr className="header-bottom-border" />
      <div className="body-container">
        <form
          class="form-box"
          onSubmit={handleSubmit((formData) => simpleChangePwdSubmit(formData))}
        >
          <div className="row">
            <div className="col-md-6">
              <div className="input-box">
                <Field
                  className="form-control"
                  parentClassName="focus-outer"
                  name="inputOldPassword"
                  type="password"
                  label={Translate.oldPassword + " *"}
                  component={renderTextField}
                  placeholder={"Current Password"}
                  maxLength={25}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-box">
                <Field
                  className="form-control"
                  parentClassName="focus-outer"
                  name="inputNewPassword"
                  type="password"
                  label={Translate.newPassword + " *"}
                  component={renderTextField}
                  placeholder={"New Password"}
                  maxLength={25}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="input-box">
                <Field
                  className="form-control"
                  parentClassName="focus-outer"
                  name="inputConfirmPassword"
                  type="password"
                  label={Translate.confirmPassword + " *"}
                  component={renderTextField}
                  placeholder={"Confirm New Password"}
                  maxLength={25}
                />
              </div>
            </div>
            <div className="col-lg-6 offset-lg-6 text-end mt-4"></div>
          </div>
          <div className="form-btn-box">
            <button
              className="gradient-btn"
              type="submit"
              disabled={isChangePwdLoading}
            >
              {isChangePwdLoading ? <ButtonLoader /> : Translate.save}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default reduxForm({
  form: "change-pwd-form",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  validate,
})(ChangePasswordForm);
