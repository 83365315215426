export const GET_OFF_MARKET_GAMES = "[APP] GET OFF MARKET GAMES";
export const GET_OFF_MARKET_PLAYERS_GAMES =
  "[APP] GET OFF MARKET PLAYERS GAMES";
export const GET_OFF_MARKET_DATA = "[APP] GET OFF MARKET DATA";
export const OPEN_OFFMARKET_CHAT = "[APP] OPEN_OFFMARKET_CHAT";
export const CLOSE_OFFMARKET_CHAT = "[APP] CLOSE_OFFMARKET_CHAT";
export const SUBMIT_TRANSACTION = "[APP] SUBMIT_TRANSACTION";
export const SET_SUBMIT_TRANSACTION_STATUS =
  "[APP] SET_SUBMIT_TRANSACTION_STATUS";
export const OPEN_OFFMARKET_MODAL = "[APP] OPEN_OFFMARKET_MODAL";
export const CLOSE_OFFMARKET_MODAL = "[APP] CLOSE_OFFMARKET_MODAL";
export const IS_OFFMARKET_DEPOSIT_RUNNING = "[APP] IS_OFFMARKET_DEPOSIT_RUNNING";
export const IS_OFFMARKET_DEPOSIT_COMPLETED = "[APP] IS_OFFMARKET_DEPOSIT_COMPLETED";
export const IS_OFFMARKET_DEPOSIT_FAILED = "[APP] IS_OFFMARKET_DEPOSIT_FAILED";
export const OFFMARKET_DEPOSIT_RESET = "[APP] OFFMARKET_DEPOSIT_RESET";
