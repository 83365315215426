import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { useDispatch } from "react-redux";
import IdleTimer from "react-idle-timer";
import { logoutUser, trackUserActivity } from "store/actions/user.actions";

const CheckUserIdle = () => {
  const [isUserActive, setIsUserActive] = useState(true);
  const timer = 1000 * 60 * 15;
  const activityStatusTimer = 1000 * 60 * 14;
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    let sendUserActivity;
    if (isUserActive) {
      sendUserActivity = setInterval(() => {
        dispatch(trackUserActivity("last_activity_time"));
      }, activityStatusTimer);
    }
    return () => clearInterval(sendUserActivity);
  }, [dispatch, isUserActive, activityStatusTimer]);

  const onIdle = (timeRemaining) => {
    const isCasinoActive = location.pathname === "/casino/game";
    if (isCasinoActive && timeRemaining > 0) {
      dispatch(trackUserActivity("last_activity_time"));
    } else {
      dispatch(logoutUser());
      setIsUserActive(false);
      if (!isUserActive) setIsUserActive(true);
      toastr.error("", "User is logged out due to inactivity.");
    }
  };

  const onActive = () => {
    setIsUserActive(true);
  };

  return (
    <div>
      <IdleTimer
        timeout={timer}
        crossTab={true}
        onIdle={onIdle}
        onActive={onActive}
      ></IdleTimer>
    </div>
  );
};

export default CheckUserIdle;
