import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiConfig } from "config/api.config";
import { toastr } from "react-redux-toastr";
import { useDispatch, useSelector } from "react-redux";
import { closeOffmarketChat } from "store/actions/offmarket.actions";
import * as Actions from "./../../../store/actions/actionTypes";
import { getUser } from "store/actions/user.actions";
import { CircularProgress } from "@material-ui/core/index";

const AddTipComment = ({
  tipAmount,
  setInputValue,
  sendTipCommentMessage,
  setHideSendMessage,
  setShowTip,
}) => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(tipAmount || "");
  const [showLoader, setShowLoader] = useState(false);
  const userId = localStorage.getItem("user_id");
  const { slug, staffDetails } = useSelector((state) => state.general);
  const staffId =
    staffDetails?.id || parseInt(sessionStorage.getItem("staffId"));
  const pageData = useSelector((state) => state.user.pageData);
  const isOffmarketChatOpen = useSelector(
    (state) => state.offmarket.isOffmarketChatOpen
  );

  const sendTipReward = async () => {
    if (amount <= 0) {
      toastr.error("Please enter valid amount.");
      return;
    }
    setShowLoader(true);
    const payload = {
      is_tip: true,
      tip: amount,
      staff: parseInt(staffId),
      room_name: `P${userId}Chat`,
      whitelabel_admin_uuid:
        localStorage.getItem("whitelabel_admin_uuid") ||
        pageData?.whitelabel_admin_uuid,
      agent_name: localStorage.getItem("slug") || slug,
    };

    await axios
      .post(apiConfig.routes.addTipReward, payload)
      .then(() => {
        toastr.success("Tip sent successfully");
        sendTipCommentMessage(Number(amount));
        dispatch({ type: Actions.CLOSE_END_CHAT_DIALOG });
        setShowTip(false);
        dispatch(getUser());
      })
      .catch((error) => {
        console.error(error);
        if (error.response?.data?.message)
          toastr.error(error.response?.data?.message);
        else toastr.error("Something went wrong!!");
      })
      .finally(() => {
        setAmount("");
        setShowLoader(false);
        setHideSendMessage(false);
        setInputValue("");
      });
  };

  useEffect(() => {
    if (tipAmount) sendTipReward();
  }, []);

  const handleTipAmount = (e) => {
    const { value } = e.target;
    if (value.toString().length > 5) return;
    setAmount(value);
  };

  const sendOnEnter = (e) => {
    if (e.key === "Enter") {
      sendTipReward();
    }
  };

  const handleClose = () => {
    dispatch({ type: Actions.CLOSE_END_CHAT_DIALOG });
    setShowTip(false);
  };

  const blockInvalidChar = (e) => {
    if (["e", "E", "+", "-"].includes(e.key)) {
      e.preventDefault();
    }
  };

  if (tipAmount || showLoader)
    return (
      <div className="d-flex justify-content-center gap-2">
        <CircularProgress size={"18px"} style={{ color: "#D70101" }} />
        <span className="sending-tip"> Sending tip... </span>
      </div>
    );
  return (
    <>
      <div className="tip-header">
        <span> Reward a Tip </span>
        <span onClick={handleClose}>
          {" "}
          <img
            src="/assets/icons/close.svg"
            alt="close"
            className="close-select-file"
          />{" "}
        </span>
      </div>
      {!tipAmount && (
        <>
          <span className="quick-select"> Quick select amount </span>
          <div className="amount-wrap d-flex justify-content-center mb-3">
            <button onClick={() => setAmount(5)}> +5 </button>
            <button onClick={() => setAmount(10)}> +10 </button>
            <button onClick={() => setAmount(20)}> +20 </button>
            <button onClick={() => setAmount(50)}> +50 </button>
          </div>
        </>
      )}
      <div className="input-container tipInput">
        <input
          type="number"
          value={amount}
          onChange={handleTipAmount}
          placeholder="Add tip amount"
          onKeyUp={sendOnEnter}
          onKeyDown={blockInvalidChar}
          maxLength={5}
          required
        />
        <button type="submit" onClick={sendTipReward}>
          <img src="/assets/icons/send.svg" alt="send" />
        </button>
      </div>
    </>
  );
};

export default AddTipComment;
