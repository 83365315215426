export const fr = {
  en: "EN",
  tr: "TR",
  fr: "FR",
  de: "DE",
  ru: "RU",
  nl: "NL",
  th: "TH",
  it: "IT",
  ja: "JA",
  sv: "SV",
  es: "ES",
  ko: "KO",
  pt: "pt",
  zh: "ZH",
  sports: "Sports",
  live: "En Direct",
  inplay: "En Direct",
  today: "Aujourd'hui",
  upcoming: "A venir",
  tomorrow: "Demain",
  highlights: "Points forts",
  results: "Résultats",
  lastMinute: "Dernière minute",
  liveCasino: "Casino en direct",
  casino: "Casino",
  others: "Autres",
  save: "Sauvegarder",
  EMPTY_PASSWORD: "Veuillez fournir un mot de passe.",
  PASSWORD_LENGTH: "Le mot de passe doit contenir au moins 8 caractères.",
  PASSWORD_NOT_MATCH:
    "Le mot de passe et le mot de passe de confirmation doivent être identiques.",
  OLD_NEW_PASSWORD_SAME:
    "Les anciens et les nouveaux mots de passe ne peuvent pas être identiques.",
  EMPTY_CONFIRM_PASSWORD: "Veuillez fournir un mot de passe de confirmation.",
  myBets: "Mes paris",
  home: "Domicile",
  login: "Connexion",
  signup: "S'inscrire",
  phoneNumber: "Numéro de téléphone",
  betslip: "Coupon ID",
  search: "Rechercher",
  continue: "Continuer",
  cashAppPay: "Payer avec CashApp",
  searchHere: "Tapez ici",
  selectCurrency: "Sélectionnez réseau",
  selectNetwork: "Sélectionnez le réseau de crypto-monnaie",
  payMethods: "Sélectionnez le mode de paiement",
  personalDetails: "Détails personnels",
  pleaseEnterUsername: "S'il vous plaît entrez votre nom d'utilisateur",
  emailAddress: "Adresse e-mail",
  address: "Adresse",
  zipCode: "Code postal",
  dateOfBirth: "Date de naissance",
  mobileNumber: "Numéro de portable",
  state: "État",
  walletAddress: "Entrez l'adresse du portefeuille",
  required: "Ce champ est obligatoire.",
  greaterThanZero: "Le montant doit être supérieur à zéro.",
  minAmountRequired: "Le montant minimum requis est",
  affiliateDepositCount:
    "Demander un bonus d'affiliation pour le nombre de dépôts:",
  requestAffiliateHeading: "Demander un accès élevé",
  enableAllDeposits: "Activer pour tous les dépôts",
  days: "Nombre de jours",
  noAffiliateFound: "Aucun joueur affilié trouvé",
  submit: "Soumettre",
  expDate: "Date d'expiration actuelle",
  requestForLifetime: "Demander une affiliation à vie",
  depositCountRequired: "Nombre de dépôts requis",
  durationRequired: "Durée d'affiliation requise",
  validAddress: "S'il-vous-plaît entrez une adresse valide.",
  fullname: "Nom et prénom",
  transaction: "Transaction",
  changePassword: "Changer le mot de passe",
  updatePassword: "Mettre à jour le mot de passe",
  forgotPassword: "Mot de passe oublié",
  getOTP: "Envoyer OTP",
  setnewPass: "Set New Password",
  logout: "Se déconnecter",
  single: "Seul",
  multiple: "Plusieurs",
  possibleWin: "Victoire possible",
  amount: "Montant",
  ok: "D'accord",
  totalOdds: "Cotes totales",
  odd: "Impair",
  totalPossibleWin: "Total des victoire possible",
  placeBet: "Pariez",
  fastBet: "Vite Pari",
  fast: "Vite",
  bet: "Pari",
  username: "Nom d'utilisateur",
  password: "Mot de passe",
  stayLoggedIn: "Rester connecté",
  all: "Tout",
  submitOtp: "Soumettre OTP",
  enterOtp: "Entrer OTP",
  "in game": "En jeu",
  won: "Gagné",
  lost: "Perdu",
  refund: "Remboursement",
  date: "Date",
  winAmount: "Montant Win",
  stake: "Miser",
  pick: "Prendre",
  myAccount: "Mon compte",
  football: "Football",
  basketball: "Basketball",
  iceHockey: "Hockey sur glace",
  tennis: "Tennis",
  volleyball: "Volley-ball",
  boxing: "Boxe",
  americanFootball: "football américain",
  baseball: "Base-ball",
  hockey: "Le hockey",
  tableTennis: "Tennis de table",
  badminton: "Badminton",
  cricket: "Criquet",
  futsal: "Futsal",
  golf: "Le golf",
  handball: "Handball",
  rugbyLeagues: "Ligues de rugby",
  pickCanNotBeCombined: "Ce choix ne peut pas être combiné",
  matchStopped: "Match arrêté",
  favorites: "Favoris",
  cashout: "Encaissement",
  "in progress": "En cours",
  rejected: "Rejeté",
  startNewChat: "Nouvelle conversation",
  chatQuery: "Dites-nous comment pouvons-nous vous aider ?",
  chatStartMessage:
    "Besoin d'aide? Commencez une conversation avec notre représentant du service à la clientèle, nous serons heureux de vous aider.",
  chatEnded: "Chat terminé !!",
  queuePosition: "Votre position dans la file d'attente",
  queueMessage:
    "Veuillez patienter un instant, vous êtes dans la file d'attente ! Vous serez bientôt mis en relation avec un représentant.",
  tip: "Conseil",
  noGoal: "Sans but",
  yes: "Oui",
  over: "Plus de",
  under: "En dessous de",
  even: "Même",
  areYouSureWantCashout: "Etes-vous sûr que vous voulez Encaissement?",
  trans_type: "Type de transaction",
  bonus: "Bonus",
  wallet: "Portefeuille",
  about: "À PROPOS",
  privacy_policy: "POLITIQUE DE CONFIDENTIALITÉ",
  terms_and_condition: "TERMES ET CONDITIONS",
  affiliates: "AFFILIER",
  new_page_test: "TEST NOUVELLE PAGE",
  termsAndConditon: "Termes et conditions",
  no_promo_data: "Aucun détail de promotion trouvé !!",
  aboutUs: "À propos de nous",
  contactUs: "Contactez-nous",
  socialMedia: "Réseaux sociaux",
  payments: "Paiements",
  howPlacebet: "Comment placer un pari",
  deposit: "Dépôt",
  credit: "Crédit",
  allTransactions: "toutes transactions",
  debit: "Débit",
  withdraw: "Retirer",
  totalOdd: "Total des Odd",
  totalStake: "Mise totale",
  topGames: "Meilleurs jeux",
  allGames: "Tous les jeux",
  canNotBeCombined: "Cette Pioche ne peut pas être combiné",
  casinoDescription: "Le casino crypto top secret et hautement classifié.",
  betSettled: "Bet Réglé",
  betSuspended: "Bet suspendu",
  insufficientBalance: "Solde insuffisant",
  oldPassword: "Ancien mot de passe",
  newPassword: "Nouveau mot de passe",
  confirmPassword: "Confirmer le nouveau mot de passe",
  enterAmount: "Entrer le montant",
  change: "Changer",
  fromDate: "Partir de la date",
  toDate: "À ce jour",
  noGoal: "Non",
  activityType: "Type d'activité",
  show: "Spectacle",
  gameType: "Type de jeu",
  repeatBet: "Remiser",
  someThingWrong: "Une erreur se est",
  status: "Statut",
  coupon: "Coupon ID",
  acceptOddChangesText:
    "Vous devez accepter les changements pour être en mesure de placer un pari",
  turnOffAway: "Éteignez de toute façon",
  acceptOddChanges: "Accepter les changements impairs",
  maxWinAmount: "Nombre maximal de victoire",
  maxOdd: "Max étrange",
  dateTime: "Date / Heure",
  type: "Taper",
  refreshCashout: "Actualiser encaissement",
  vendor: "Vendeur",
  stakeLowerThen1: "La mise dois etre inferieur à",
  stakeGreaterThen1: "La mise dois etre superieur à",
  ESports: "Déports",
  KSports: "Ksports",
  promotion: "Promotions",
  customerServicePage: "Service client",
  decimal: "Décimal",
  fraction: "Fraction",
  americanOdds: "Cotes américaines",
  nothingFound: "rien n'a été trouvé",
  noLiveMatch: "Aucun match en direct n'est disponible",
  sportBook: "Livre de sport",
  phoneNo: "Numero",
  readAllTerms:
    " Je certifie avoir au moins 18ans et avoir pris connaissances des CGU et des réglements , et en accepter l'application",
  languageOption: "Options de langue",
  createNewAccount: "Créer un nouveau compte",
  myProfile: "Mon profil",
  marketNames: {
    1: "1x2",
    2: "Moins/Plus",
    238: "correspondance restante",
    59: "Prochain but",
    7: "Double chance",
    52: "Rembourser si nul",
    226: "12 heures supplémentaires comprises",
    13: "Handicap européen",
    4: "Mi temps /Fin",
    17: "Les deux equipes marquent",
    59: "Prochain but",
    28: "Moins/Plus incluant les heures supplémentaires",
    247: "Restant 1er semestre",
  },




  message: {
    LOGIN_SUCCESS: "Connexion réussie",
    FORGOT_SUCCESS: "Mot de passe envoyé à votre adresse e-mail enregistrée.",
    WrongEAndPwd: "Veuillez fournir un identifiant de messagerie ou un mot de passe valide.",
    EMPTY_EMAIL: "Veuillez fournir un e-mail.",
    EMPTY_PASSWORD: "Veuillez fournir un mot de passe.",
    INVALID_EMAIL: "Veuillez fournir une adresse e-mail valide.",
    INVALID_ADDRESS: "Veuillez saisir une adresse de portefeuille valide",
    NAME_REQUIRED: "Veuillez indiquer le nom.",
    FIRST_NAME_REQUIRED: "Veuillez indiquer votre prénom",
    LAST_NAME_REQUIRED: "Veuillez fournir le nom de famille",
    DOMAIN_MESSAGE_REQUIRED: "Veuillez fournir le message du domaine",
    USER_NAME_REQUIRED: "Veuillez fournir un nom d'utilisateur.",
    USER_NAME_SHOULD_BE_ALPHANUMERIC: "Le nom d'utilisateur doit être alphanumérique",

    FULL_NAME_REQUIRED: "Veuillez fournir le nom complet.",
    PHONE_REQUIRED: "Veuillez fournir le numéro de téléphone.",
    PHONE_LENGTH_REQUIRED: "Le numéro de téléphone doit contenir 10 chiffres.",
    ONLY_CHARECTERS: "Veuillez saisir uniquement des caractères alphabétiques.",
    VALID_PHONE: "Le numéro de téléphone ne doit contenir que des chiffres.",
    EMPTY_CONFIRM_PASSWORD: "Veuillez fournir un mot de passe de confirmation.",
    PASSWORD_LENGTH: "Le mot de passe doit contenir au moins 8 caractères.",
    PASSWORD_NOT_MATCH: "Les mots de passe ne correspondent pas.",
    EMPTY_ACCESS_TOKEN: "Veuillez fournir un jeton d'accès.",
    EMPTY_OTP: "Veuillez fournir otp.",
    SIMPLE_SIGNUP_SUCCESS: "Vous avez été enregistré avec succès.",
    NUMBER_REQUIRED: "Veuillez fournir uniquement un numéro.",
    DOB_REQUIRED: "Veuillez indiquer la date de naissance.",
    ADDRESS_REQUIRED: "Veuillez fournir l'adresse.",
    EMPTY_STATE: "Veuillez indiquer l'état.",
    EMPTY_CITY: "Veuillez indiquer la ville.",
    EMPTY_COUNTRY: "Veuillez indiquer le pays.",
    SOME_THING_WRONG: "Quelque chose a mal tourné.",
    PROJECT_NAME_REQUIRED: "Veuillez fournir le nom du projet.",
    PROJECT_DOMAIN_REQUIRED: "Veuillez indiquer le domaine du projet.",
    PROJECT_DOMAIN_INVALID: "Veuillez fournir un domaine valide.",
    FRONTEND_DOMAIN_INVALID: "Veuillez fournir un domaine valide.",
    CLIENT_PUBLIC_KEY_REQUIRED: "Veuillez fournir un domaine valide.",
    BANNER_TITLE_REQUIRED: "Veuillez fournir le titre de la bannière.",
    BANNER_TYPE_REQUIRED: "Veuillez indiquer le type de bannière.",
    BANNER_CATEGORY_REQUIRED: "Veuillez indiquer la catégorie de la bannière.",
    ZIP_CODE_REQUIRED: "Veuillez fournir le code postal.",
    CAPTCHA_REQUIRED: "Veuillez sélectionner captcha.",
    FILE_REQUIRED: "Veuillez fournir le fichier",
  },

  tabNames: {
    All: "Toute",
    "Main Markets": "Marchés principaux",
    Totals: "Les totaux",
    "1st Half": "1ère moitié",
    "2nd Half": "2e mi-temps",
    "Home Team": "Equipe Domicile",
    "Away Team": "Equipe Visiteur",
    "All Periods": "toutes les périodes",
    Results: "Résultats",
    "Home / Away": "Domicile/Exterieur",
  },


  //new added
  betaVersionMessage: "Nous exécutons actuellement une version bêta. Si vous rencontrez des problèmes, veuillez nous contacter via Telegram:"
};
