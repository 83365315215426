import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Typography } from "../../../node_modules/@material-ui/core/index";

const ConfirmationPopup = ({
  open,
  handleClose,
  type,
  amount,
  handleProceed,
}) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className="confirmation-popup"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="dialogTitle">
          {
            <img
              src="/img1/credit.webp"
              style={{ height: "100px" }}
              alt="credit"
            />
          }
          <Typography>
            {" "}
            {`${type.charAt(0).toUpperCase() + type.slice(1)} Confirmation`}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className="text-light dialogContentText"
            id="alert-dialog-slide-description"
          >
            Are you sure you want to proceed with {type} of{" "}
            <strong>${amount}</strong> ?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialogActions">
          <Button className="gradient-btn" onClick={handleProceed}>
            Yes
          </Button>
          <Button className="gradient-btn" onClick={handleClose}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmationPopup;
