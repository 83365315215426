import React, { useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import { TextField, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FailedIcon, SuccessTick } from "icons/index";
const OffmarketModal = ({
  open,
  close,
  fieldName,
  fieldLabel,
  fieldPlaceholder,
  fieldType,
  fieldValue,
  handleChange,
  handleSubmit,
  error,
  amount,
  gameDetails,
}) => {
  const { loading, offmarketModalType } = useSelector((state) => state.offmarket);
  const isSignUp = offmarketModalType === "SignUp";
  const isError = isSignUp && fieldValue?.length < 5;
  const { isOffmarketDepositRunning } = useSelector((state) => state.offmarket);
  const { isOffmarketDepositCompleted } = useSelector((state) => state.offmarket);
  const { isOffmarketDepositFailed } = useSelector((state) => state.offmarket);


  return (
    <div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal login-signup-modal fade show">
          <div className="modal-dialog modal-dialog-centered">

            {(offmarketModalType !== "Deposit" || (offmarketModalType === "Deposit" && !isOffmarketDepositRunning)) && (
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="btn-close" onClick={close}>
                    <img
                      src="/assets/img/close-icon.svg"
                      className="img-fluid"
                      alt="CloseImage"
                    />
                  </button>
                  <h1 className="head-title-text">
                    {/* Changing the term deposit and withdraw on the player side. The term Deposit as a Recharge and Withdraw as a Redeem */}
                    {offmarketModalType === "Deposit"?  "Recharge" : offmarketModalType === "Withdraw" ? "Redeem" : offmarketModalType}
                  </h1>
                </div>
                <div className="modal-body">
                  <div className="form-box">
                    <div className="row">
                      <div
                        className="col-md-12 mx-auto"
                        style={{ marginBottom: "1.25rem" }}
                      >
                        <label className="form-label">{fieldLabel}</label>
                        <TextField
                          className={`form-control ${
                            offmarketModalType !== "SignUp" ? "dollar-sign-input" : ""
                          }`}
                          name={fieldName}
                          type={fieldType}
                          placeholder={fieldPlaceholder}
                          variant="outlined"
                          value={fieldValue}
                          onChange={handleChange}
                          inputProps={{ maxLength: 15 }}
                        />
                        {isError && (
                          <Typography
                            variant="caption"
                            color="error"
                            style={{ fontSize: "1rem" }}
                          >
                            Username must contain atleast 5 characters
                          </Typography>
                        )}
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-md-12 mx-auto">
                        <div className="btn-box">
                          <button
                            className="gradient-btn w-100"
                            onClick={(loading || error || isError) ? () => {} : handleSubmit}
                            disabled={loading || error}
                            >
                            {loading ? (
                              <CircularProgress className="progress" size={30} />
                            ) : 
                              <>
                                {(offmarketModalType === "Deposit")?  "Recharge" : (offmarketModalType === "Withdraw") ? "Redeem" : offmarketModalType}
                              </>
                            }
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}




            {/* Offmarket Deposit Pending */}
            {offmarketModalType === "Deposit" && isOffmarketDepositRunning && !isOffmarketDepositCompleted && !isOffmarketDepositFailed && (
              <div className="modal-content">
                <div className="modal-body d-flex flex-column justify-content-center align-items-center" style={{padding: "3.5rem 6rem"}}>
                  <h2 style={{ fontSize: "28px" }}>${amount}</h2>
                  <p>is being deposited to {gameDetails.name}</p>
  
                  <span class="processingLoader" style={{margin: "28px 0px"}}></span>
  
                  <p className="tiny">Please keep patience. Sometimes it may take 1-2 mins.</p>
  
                </div>
              </div>
            )}

            {/* Offmaket Deposit Success */}
            { offmarketModalType === "Deposit" && isOffmarketDepositCompleted && (
            <div className="modal-content">
              <div
                className="modal-body d-flex flex-column justify-content-center align-items-center"
                style={{ padding: "3.5rem 6rem", gap: "12px" }}
              >
                <span className="pulsate-fwd">
                  <SuccessTick />
                </span>
                <h2>Success</h2>
                <p className="text-center">${isOffmarketDepositRunning.data.amount-isOffmarketDepositRunning.data.bonus} is successfully recharged to {gameDetails.name}.
                <br/>
                and ${isOffmarketDepositRunning.data.bonus} is added as a Bonus.
                </p>
                <button
                  className="gradient-btn"
                  style={{ marginTop: "16px" }}
                  onClick={close}
                >
                  Close
                </button>
              </div>
            </div>
            )}

            {/* Offmaket Deposit Failed */}
            { offmarketModalType === "Deposit" && isOffmarketDepositFailed && (
            <div className="modal-content">
              <div
                className="modal-body d-flex flex-column justify-content-center align-items-center"
                style={{ padding: "3.5rem 6rem", gap: "12px" }}
              >
                <span className="pulsate-fwd">
                  <FailedIcon />
                </span>
                <h2>Failed</h2>
                <p className="text-center">Cannot deposit ${amount} to {gameDetails.name}.
                {/* <br/> */}
                {/* and ${isOffmarketDepositRunning.data.bonus} is added as a Bonus. */}
                </p>
                <button
                  className="gradient-btn"
                  style={{ marginTop: "16px" }}
                  onClick={close}
                >
                  Close
                </button>
              </div>
            </div>
            )}

          </div>
        </div>
      </Modal>
    </div>
  );
};

export default OffmarketModal;
