/* eslint-disable react-hooks/exhaustive-deps */
import { Translate } from "localization";
import React from "react";
import Loader from "shared/Loader";
import { createMarkup } from "utils/helper";

const PageFooter = (props) => {
  const { extraPageData } = props;
  const { pageInfo, isPageLoading } = extraPageData;
  return (
    <section className="static-page-section">
      <div className="static-page-wrap">
        {isPageLoading && <Loader />}
        {!isPageLoading && Object.entries(pageInfo).length === 0 && (
          <div className=" noData">
            <p>{Translate.nothingFound}</p>
          </div>
        )}

        {!isPageLoading && Object.entries(pageInfo).length != 0 && (
          <>
            <div className="card-container">
              <div className="header-container">
                <h1 className="title-text">{pageInfo?.title}</h1>
              </div>
              <hr className="header-bottom-border" />
              <div className="body-container">
                <div
                  dangerouslySetInnerHTML={createMarkup(
                    pageInfo?.page_content || ""
                  )}
                  className="text-box"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default PageFooter;
