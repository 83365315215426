import {
  PAGE_INFO_START,
  PAGE_INFO_SUCCESS,
  PAGE_INFO_FAILURE,
} from "../actions/GeneralPage/actionTypes";
import { updateObject } from "./utility";

const initialState = {
  isPageLoading: false,
  pageError: "",
  pageInfo: {},
};

/* Page action */
const pageInfoStart = (state) => {
  return updateObject(state, {
    pageError: null,
    isPageLoading: true,
    pageInfo: {},
  });
};

const pageInfoSuccess = (state, action) => {
  return updateObject(state, {
    pageError: null,
    isPageLoading: false,
    pageInfo: action.data,
  });
};
const pageInfoFail = (state, action) => {
  return updateObject(state, {
    pageError: action.error,
    isPageLoading: false,
    pageInfo: {},
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PAGE_INFO_START:
      return pageInfoStart(state, action);
    case PAGE_INFO_SUCCESS:
      return pageInfoSuccess(state, action);
    case PAGE_INFO_FAILURE:
      return pageInfoFail(state, action);
    default:
      return state;
  }
};

export default reducer;
