export const GET_USER = "[APP] GET USER";
export const SET_USER = "[APP] SET USER";
export const LOGIN_START = "[APP] SUBMIT LOGIN";
export const LOGOUT_USER = "[APP] LOGOUT USER";
export const LOGIN_SUCCESS = "[APP] LOGIN SUCCESS";
export const SIGNUP_ERROR = "[APP] SIGNUP ERROR";
export const LOGIN_ERROR = "[APP] LOGIN ERROR";
export const CHANGE_PASSWORD_START = "[APP] CHANGE PASSWORD START";
export const CHANGE_PASSWORD_SUCCESS = "[APP] CHANGE PASSWORD SUCCESS";
export const CHANGE_PASSWORD_ERROR = "[APP] CHANGE PASSWORD ERROR";
export const SET_CASHBACK_DATA = "[APP] SET CASHBACK DATA";
export const SET_CASHBACK_SUCESS = "[APP] SET CASHBACK SUCESS";
export const DEPOSIT_AMOUNT = "[APP] DEPOSIT AMOUNT";
export const MIN_DEPOSIT_AMOUNT = "[APP] MIN DEPOSIT AMOUNT";
export const CLEAR_MIN_DEPOSIT_AMOUNT = "[APP] CLEAR MIN DEPOSIT AMOUNT";
export const RESET_DEPOSIT_AMOUNT = "[APP] RESET DEPOSIT AMOUNT";
export const SET_ODD_TYPE = "[APP] SET ODD TYPE";
export const DEPOSIT_UNIQUE_KEY = "[APP] DEPOSIT_UNIQUE_KEY";
export const DEPOSIT_SUCCESS = "[APP] DEPOSIT_SUCCESS";
export const SET_WALLET_ADDRESS = "[APP] SET_WALLET_ADDRESS";
export const CLEAR_DEPOSIT_SUCCESS = "[APP] CLEAR DEPOSIT_SUCCESS";
export const WITHDRAW_SUCCESS = "[APP] WITHDRAW_SUCCESS";
export const WITHDRAW_ERROR = "[APP] WITHDRAW_ERROR";
export const DEPOSIT_ERROR = "[APP] DEPOSIT_ERROR";
export const DEPOSIT_MSG = "[APP] DEPOSIT_MSG";
export const REDEEM_COUPON_ERROR = "[APP] REDEEM COUPON ERROR";
export const SIGNUP_START = "[APP] SIGNUP_START";
export const SIGNUP_SUCCESS = "[APP] SIGNUP_SUCCESS";
export const SET_IS_LOGGED_IN = "[APP] SET_IS_LOGGED_IN";
export const DRAWER_SET = "[APP] DRAWER_SET";
export const EDIT_USER_DATA = "[APP] EDIT USER DATA";
export const SEND_EMAIL_OTP = "[APP] SEND EMAIL OTP";
export const FORGOT_PASSWORD_SUCCESS = "[APP] FORGOT PASSWORD SUCCESS";
export const SET_AFFILIATED_PLAYERS = "[APP] SET AFFILIATED PLAYERS";
export const SHOW_SIGNUP = "[APP] SHOW SIGNUP";
export const SET_USER_QUERY = "[APP] SET USER QUERY";
export const OPEN_END_CHAT_DIALOG = "[APP] OPEN_END_CHAT_DIALOG";
export const CLOSE_END_CHAT_DIALOG = "[APP] CLOSE_END_CHAT_DIALOG";
export const SET_NO_AVAILABLE_STAFF = "[APP] SET_NO_AVAILABLE_STAFF";
export const SET_MAIN_DATA = "[APP] SET_MAIN_DATA";
export const CLEAR_SLUG = "[APP] CLEAR_SLUG";
export const SET_CHAT_CONNECTION_DETAILS = "[APP] SET_CHAT_CONNECTION_DETAILS";
export const CLEAR_CHAT_CONNECTION_DETAILS =
  "[APP] CLEAR_CHAT_CONNECTION_DETAILS";
export const SET_CHAT_CONNECTION_STATUS = "[APP] SET_CHAT_CONNECTION_STATUS";
export const RESET_UNREAD_MESSAGES = "[APP] RESET_UNREAD_MESSAGES";
export const SET_HOME_ACTIVE = "[APP] SET_HOME_ACTIVE";
export const SET_CASHAPP_DATA = "[APP] SET_CASHAPP_DATA";
export const SHOW_OTP_MODAL = "[APP] SHOW_OTP_MODAL";
export const SHOW_OTP_MODAL_LOADING =  "[APP]  SHOW_OTP_MODAL_LOADING";
export const SET_CASHAPP_LINK = "[APP] SET_CASHAPP_LINK";
export const IS_CASHAPP_PAYMENT_RUNNING = "[APP] IS_CASHAPP_PAYMENT_RUNNING";
export const IS_CASHAPP_PAYMENT_COMPLETED = "[APP] IS_CASHAPP_PAYMENT_COMPLETED";
