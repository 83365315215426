import React, { useRef } from "react";
import { contactUsValidation as validate } from "utils/validate";
import "./NewLandingPage.css";
import { Field, reduxForm } from "redux-form";
import { renderTextArea, renderTextField } from "utils/formUtils";
import { useDispatch, useSelector } from "react-redux";
import { submitContactForm } from "store/actions/user.actions";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

import SwiperCore, {
  EffectCoverflow,
  Pagination,
  Navigation,
} from "swiper/core";

SwiperCore.use([EffectCoverflow, Pagination, Navigation]);

const games = [
  {
    imgSrc: "/assets/landing/slider-img-1.png",
    name: "Sky Hunters",
  },
  {
    imgSrc: "/assets/landing/slider-img-2.png",
    name: "Piro Pixie",
  },
  {
    imgSrc: "/assets/landing/slider-img-3.png",
    name: "Sky Hunters",
  },
  {
    imgSrc: "/assets/landing/slider-img-1.png",
    name: "Piro Pixie",
  },
  {
    imgSrc: "/assets/landing/slider-img-2.png",
    name: "Sky Hunters",
  },
];

const NewLandingPage = (props) => {
  const { handleSubmit, reset } = props;
  const pageData = useSelector((state) => state.user.pageData);
  const dispatch = useDispatch();
  const submitForm = (formData) => {
    dispatch(submitContactForm(formData));
    reset();
  };
  const contactFormRef = useRef(null);

  const scrollToContactForm = () => {
    contactFormRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const shrinkImgUrl = pageData?.logo?.substring(0, pageData?.logo?.length);
  return (
    <div className="new-landing-page">
      <div className="new-landing-wrap">
        <section className="header-section">
          <a href="#" className="brand-logo m-0">
            <img
              src={shrinkImgUrl}
              style={{height: "140px"}}
              className="img-fluid d-block mx-auto"
              alt="Logo Img"
            />
          </a>
        </section>

        <section className="banner-section">
          <div className="banner-wrap">
            <div className="text-box">
              <h1 className="title-text">
                Welcome to <br /> {pageData.project_name} Off Market
              </h1>
              <button
                className="gradient-btn square-btn"
                onClick={scrollToContactForm}
              >
                {" "}
                {pageData?.msg}{" "}
              </button>
            </div>
            <div className="img-box">
              <img
                src="/assets/landing/landing-banner-img.png"
                className="img-fluid d-block mx-auto w-100"
                alt="Banner Img"
              />
            </div>
          </div>
        </section>

        <section className="offering-section">
          <div className="offering-wrap">
            <div className="section-heading-box">
              <h1 className="section-heading-text">Our Offering</h1>
            </div>
            <div className="slider-box">
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                loop={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 22,
                  depth: 52,
                  modifier: 6,
                  slideShadows: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                  clickable: true,
                }}
                breakpoints={{
                  576: {
                    slidesPerView: 2,
                    coverflowEffect: {
                      rotate: 0,
                      stretch: 22,
                      depth: 52,
                      modifier: 6,
                      slideShadows: false,
                    },
                  },
                  768: {
                    slidesPerView: 2,
                    coverflowEffect: {
                      rotate: 0,
                      stretch: 22,
                      depth: 36,
                      modifier: 8,
                      slideShadows: false,
                    },
                  },
                  992: {
                    slidesPerView: 2,
                    coverflowEffect: {
                      rotate: 0,
                      stretch: 22,
                      depth: 25,
                      modifier: 10,
                      slideShadows: false,
                    },
                  },
                  1727: {
                    slidesPerView: 2,
                    coverflowEffect: {
                      rotate: 0,
                      stretch: 22,
                      depth: 22.75,
                      modifier: 12,
                      slideShadows: false,
                    },
                  },
                }}
                className="mySwiper"
              >
                {games.map((game, index) => (
                  <SwiperSlide>
                    <div className="game-card-box" key={index}>
                      <div className="game-img-box">
                        <img
                          src={game.imgSrc}
                          className="d-block mx-auto w-100"
                          alt="Game Img"
                        />
                      </div>
                      <div className="game-overlay-box">
                        <h3 className="game-name">{game.name}</h3>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}

                <div className="swiper-arrow-btn-box">
                  <button className="swiper-button-prev slider-arrow">
                    <img
                      src="/assets/img/arrow-left.svg"
                      className="img-fluid d-block mx-auto"
                      alt="Arrrow Left"
                    />
                  </button>
                  <button className="swiper-button-next slider-arrow">
                    <img
                      src="/assets/img/arrow-right.svg"
                      className="img-fluid d-block mx-auto"
                      alt="Arrrow Left"
                    />
                  </button>
                </div>
              </Swiper>
            </div>
          </div>
        </section>

        <section className="get-in-touch-section" ref={contactFormRef}>
          <div className="get-in-touch-wrap">
            <h2 className="title-text">Get In Touch With Us</h2>
            <form
              className="form-box"
              onSubmit={handleSubmit((formData) => submitForm(formData))}
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="input-box">
                    <Field
                      type="text"
                      name="firstName"
                      label="First Name"
                      className="form-control"
                      component={renderTextField}
                      placeholder="----------"
                      maxLength={25}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-box">
                    <Field
                      type="text"
                      name="lastName"
                      label="Last Name"
                      className="form-control"
                      component={renderTextField}
                      placeholder="----------"
                      maxLength={25}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-box">
                    <Field
                      type="email"
                      name="email"
                      label="Email Address"
                      className="form-control"
                      component={renderTextField}
                      placeholder="----------"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-box">
                    <Field
                      type="number"
                      name="phone"
                      label="Contact Number"
                      className="form-control"
                      component={renderTextField}
                      placeholder="----------"
                      maxLength={10}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input-box">
                    <Field
                      className="form-control"
                      type="text"
                      name="message"
                      label="Leave a message"
                      component={renderTextArea}
                      placeholder="----------"
                      maxLength={500}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="btn-box">
                    <button
                      type="submit"
                      className="gradient-btn square-btn w-100"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  );
};

export default reduxForm({
  form: "contactUs-form",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  validate,
})(NewLandingPage);
