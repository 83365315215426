import { toastr } from "react-redux-toastr";
import Util from "../../helper/Util";
import jwtService from "../../services/jwtService";
import * as Actions from "./actionTypes";
import { Translate } from "localization/index";
import { address_count, chatRoomRequestTypes } from "config/general.config";

const whitelabel_admin_uuid = localStorage.getItem("whitelabel_admin_uuid");
const agent = localStorage.getItem("slug");
// const redirectToPage = (redirect_url) => {
//   window.open(redirect_url, "_blank");
// };


const redirectToPage = (redirect_url) => {
  const link = document.createElement('a');
  link.href = redirect_url;
  link.target = '_blank';
  link.rel = 'noopener noreferrer'; // Recommended for security reasons
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  console.log("Opening link to the another app")
};


// Post cashapp pay
export const postCashAppPay = (user_cashtag) => {
  return (dispatch) => {
    jwtService.postCashAppPay(user_cashtag)
      .then((data) => {
        dispatch({ type: Actions.SET_CASHAPP_LINK, payload: data });
      })
      .catch((err) => {
        console.log(err);
      })
  }
}

// Get cashapp pay
export const getCashAppPay = (setPaymentStarted) => {
  return (dispatch) => {
    jwtService.getCashAppPay()
      .then((data) => {
        dispatch({ type: Actions.IS_CASHAPP_PAYMENT_RUNNING, payload: data });
        if (data.status === "completed" || data.status === 'failed') {
          setPaymentStarted(false)
          dispatch({ type: Actions.IS_CASHAPP_PAYMENT_COMPLETED, payload: data.status })
          dispatch(getUser());
          setTimeout(() => {
            window.location.reload(); // Refresh the page after 10 seconds
        }, 10000);
        }
      })
      .catch((err) => {
        setPaymentStarted(false)
        console.log(err);
      })
  }
}

//otp signup
export const getOtpSignup = (otpData, agent_name) => {
  return (dispatch, getState) => {
    const whitelabel_admin_uuid =
      getState().user.pageData?.whitelabel_admin_uuid ||
      localStorage.getItem("whitelabel_admin_uuid");
    jwtService.getSignUpOtp(otpData.email, otpData.username, whitelabel_admin_uuid, agent_name)
      .then((data) => {
        dispatch({ type: Actions.SHOW_OTP_MODAL });
      })
      .catch((error) => {
        toastr.error(error.response.data.error)
        dispatch({type: Actions.SHOW_OTP_MODAL_LOADING, payload: false});
        dispatch({ type: Actions.SIGNUP_ERROR, signupError: error.response.data.error})
      })
  }
}

export const getCryptoWalletAddress = () => {
  return (dispatch) => {
    jwtService
      .getCryptoWalletAddress()
      .then((response) => {
        dispatch({ type: Actions.SET_WALLET_ADDRESS, payload: response });
      })
      .catch((err) => {
        console.log(err)
      });
  };
};

//get cashapp
export const getCashAppIdList = () => {
  return (dispatch) => {
    jwtService.getCashAppIdList()
      .then((data) => {
        dispatch({ type: Actions.SET_CASHAPP_DATA, payload: data })
      })
      .catch((err) => {
        console.log(err)
      })
  }
}


//Register user
export const submitSignUp = (obj, toggleSuccess, agent) => {
  return (dispatch, getState) => {
    let language = getState().general.language;
    jwtService
      .signup(obj, language, agent)
      .then((user) => {
        dispatch({ type: Actions.SIGNUP_SUCCESS, signupError: "" });
        toggleSuccess();
        dispatch(submitLogin(obj.username, obj.password, toggleSuccess, agent));
        toastr.success("", "Sign Up Successful");
        sessionStorage.removeItem("affiliate_code");
      })
      .catch((error) => {
        if (
          error.response.status === 400 &&
          error.response.data.non_field_errors
        ) {
          toastr.error(
            error.response.data.non_field_errors
              ? error.response.data.non_field_errors[0]
              : "Something Went Wrong!!"
          );
        } else {
          toastr.error(
            "",
            error?.response?.data?.message || "Something Went Wrong"
          );
        }
        dispatch({
          type: Actions.SIGNUP_ERROR,
          signupError: "Validation error",
        });
        setTimeout(() => {
          dispatch({ type: Actions.SIGNUP_ERROR, signupError: "" });
        }, 1000);
      });
  };
};

// Login user
export const submitLogin = (username, password, toggleSuccess, agent) => {
  return (dispatch, getState) => {
    let language = getState().general.language;
    const whitelabel_admin_uuid =
      getState().user.pageData?.whitelabel_admin_uuid ||
      localStorage.getItem("whitelabel_admin_uuid");
    dispatch({ type: Actions.LOGIN_START });
    jwtService
      .signInWithUsernameAndPassword(
        { username, password, whitelabel_admin_uuid, language },
        agent
      )
      .then((user) => {
        toggleSuccess && toggleSuccess();
        localStorage.setItem("user_id", user.pk);
        localStorage.setItem("username", user.username);
        localStorage.setItem("was_chat_open", false);
        dispatch({ type: Actions.SET_IS_LOGGED_IN, payload: user });
        dispatch(getChatRoomDetails("get_chatroom"));
        dispatch(getChatRoomDetails("message_count"));
        // toastr.success('', 'Logged In successfully!');
        jwtService
          .getUser(language, agent, whitelabel_admin_uuid)
          .then((user) => {
            dispatch({ type: Actions.SET_USER, user });
            // dispatch(getActiveQueries());
          });

        return dispatch({ type: Actions.LOGIN_SUCCESS });
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          toastr.error(error.response?.data.message);
          dispatch(setLoginError(error.response?.data.message));
        } else if (error.response && error.response.status === 401) {
          dispatch(
            setLoginError(
              error.response.data?.non_field_errors &&
              error.response.data?.non_field_errors[0]
            )
          );
          toastr.error(
            "",
            error.response.data?.non_field_errors &&
            error.response.data?.non_field_errors[0]
          );
        } else if (error.response && error.response.status === 404) {
          toastr.error("", error.response.data.detail);
          dispatch(setLoginError(error.response.data.detail));
        }
      });
  };
};

// Set login error
export const setLoginError = (error) => {
  return (dispatch) => {
    dispatch({
      type: Actions.LOGIN_ERROR,
      loginError: error,
    });
  };
};

// Change user password
export const changePassword = (oldPassword, newPassword, successToggle) => {
  return (dispatch, getState) => {
    let language = getState().general.language;
    dispatch({ type: Actions.CHANGE_PASSWORD_START });
    jwtService
      .changeUserPassword(oldPassword, newPassword, language)
      .then((response) => {
        dispatch(setChangePasswordSuccess(true));
        successToggle && successToggle();
        toastr.success("", "Password was changed successfully!");
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
          Util.handleRepeatedLogin(error.response);
        } else {
          dispatch(setChangePasswordError(error.response.data.message));
          toastr.error("", error.response.data.message);
        }
      });
  };
};

//Deposit
export const depositAmount = (amount, phoneNo) => {
  return (dispatch) => {
    dispatch({ type: Actions.DEPOSIT_MSG, depositMsg: "" });
    const userId = localStorage.getItem("user_id");
    jwtService
      .deposit(userId, amount, phoneNo)
      .then((res) => {
        toastr.success("", res[1].message);
        if (res[1].type === "Code") {
          const data = JSON.parse(res[1].value);
          dispatch({ type: Actions.DEPOSIT_MSG, depositMsg: data.ExternalRef });
        } else {
          const value = res[1].value;
          const form = document.createElement("form");
          form.method = "POST";
          form.action = value["URL"];
          for (const property of Object.keys(value)) {
            //redirection code mentioned in orange-money api documentation
            if (property !== "URL" && value.hasOwnProperty(property)) {
              const hiddenField = document.createElement("input");
              hiddenField.type = "hidden";
              hiddenField.name = property;
              hiddenField.value = value[property];
              form.appendChild(hiddenField);
            }
          }
          document.body.appendChild(form);
          form.submit();
        }
        dispatch({ type: Actions.DEPOSIT_ERROR, depositError: "success" });
        setTimeout(() => {
          dispatch({ type: Actions.DEPOSIT_ERROR, depositError: "" });
        }, 1000);
      })
      .catch((error) => {
        toastr.error(error?.response?.data?.message);
        dispatch({
          type: Actions.DEPOSIT_ERROR,
          depositError: error?.response?.data?.message,
        });
        dispatch({ type: Actions.DEPOSIT_MSG, depositMsg: "" });
        setTimeout(() => {
          dispatch({ type: Actions.DEPOSIT_ERROR, depositError: "" });
        }, 1000);
      });
  };
};

export const depositFunds = (
  price_amount,
  pay_currency,
  cryptoAddressField,
  resetValues
) => {
  return (dispatch, getState) => {
    const agent = getState().general.slug;
    const uid = getState().user.pageData?.whitelabel_admin_uuid;
    jwtService
      .depositFunds(price_amount, pay_currency, agent, uid)
      .then((res) => {
        resetValues();
        cryptoAddressField(res);
        dispatch({ type: Actions.DEPOSIT_SUCCESS, success: true });
      })
      .catch((err) => {
        resetValues();
        cryptoAddressField("");
        dispatch({ type: Actions.DEPOSIT_SUCCESS, success: false });
        if (err && err.response.data.non_field_errors)
          toastr.error("", err.response.data.non_field_errors[0]);
        if (err && err.response.data.message)
          toastr.error("", err.response.data.message);
        else toastr.error("", "Deposit Failed.");
      });
  };
};

export const alchemyPayDeposit = (
  depositAmount,
  defaultCountry,
  paymentMethod,
  payWayCode,
  setLoading,
  resetValues
) => {
  return (dispatch, getState) => {
    const agent = getState().general.slug;
    const uid = getState().user.pageData?.whitelabel_admin_uuid;
    jwtService
      .alchemyPayDeposit(
        depositAmount,
        defaultCountry,
        paymentMethod,
        payWayCode,
        agent,
        uid
      )
      .then((response) => {
        resetValues();
        setLoading(false);
        // dispatch({type: Actions.SET_TO_REDIRECT_LINK_VALUE, payload: (response?.data.payUrl) })
        // dispatch({type: Actions.SET_REDIRECTED_LINK_IFRAME_STATE, payload: true })
        redirectToPage(response?.data.payUrl);

        dispatch({ type: Actions.DEPOSIT_SUCCESS, success: true });
      })
      .catch((err) => {
        resetValues();
        setLoading(false);
        toastr.error(
          "",
          err?.response?.data?.returnMsg || err?.response?.data?.error
        );
      });
  };
};

export const depositUsingQR = (price_amount, setLoading, resetValues) => {
  return (dispatch) => {
    jwtService
      .depositUsingQR(price_amount)
      .then((res) => {
        resetValues();
        setLoading(false);
        // dispatch({type: Actions.SET_TO_REDIRECT_LINK_VALUE, payload: (res?.invoice_url) })
        // dispatch({type: Actions.SET_REDIRECTED_LINK_IFRAME_STATE, payload: true })
        redirectToPage(res?.invoice_url);
      })
      .catch((err) => {
        resetValues();
        setLoading(false);
        if (err && err.response.data.non_field_errors)
          toastr.error("", err.response.data.non_field_errors[0]);
        if (err && err.response.data.message)
          toastr.error("", err.response.data.message);
        else toastr.error("", "Deposit Failed.");
      });
  };
};

export const othersWithdraw = (resetValues, amount, method, agent_name) => {
  return (dispatch) => {
    jwtService
      .othersWithdraw(amount, method, agent_name)
      .then((res) => {
        toastr.success(res?.msg);
        resetValues();
        dispatch(getUser());
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export const cashAppWithdraw = (resetValues, amount, cashapp_id, agent) => {
  return (dispatch) => {
    jwtService
      .cashAppWithdraw(amount, cashapp_id, agent)
      .then((res) => {
        resetValues();
        toastr.success(res?.msg);
        dispatch(getUser());
      })
      .catch((err) => {
        console.log(err);
      })
  }
}

export const withdrawFunds = (
  amount,
  address,
  currency,
  agent_name,
  stopLoader,
  resetValues,
  setWalletAddressError,
  WithdrawRequestSendMsg
) => {
  return (dispatch) => {
    jwtService
      .checkValidAddress(address, currency)
      .then((res) => {
        if (res?.data?.is_valid_address == true) {
          jwtService
            .withdrawFunds(amount, address, currency, agent_name)
            .then((res) => {
              stopLoader();
              resetValues();
              toastr.success(res?.msg);
              WithdrawRequestSendMsg(amount, "Crypto");
              dispatch(getUser())
            })
            .catch((err) => {
              stopLoader();
              resetValues();
              if (err.response?.data?.msg) toastr.error(err.response.data.msg);
              else if (err.response?.data?.non_field_errors)
                toastr.error(err.response.data.non_field_errors);
              else toastr.error(err?.response?.data?.message);
            });
        } else {
          setWalletAddressError(Translate.validAddress);
          stopLoader();
        }
      })
      .catch((err) => {
        stopLoader();
        resetValues();
        console.error(err);
        toastr.error("Error validation failed!!");
      });
  };
};

export const setDepositMsg = (msg) => {
  return (dispatch) => {
    dispatch({ type: Actions.DEPOSIT_MSG, depositMsg: msg });
  };
};
export const withdrawAmount = (amount, phoneNo) => {
  return (dispatch) => {
    jwtService
      .withdraw(amount, phoneNo)
      .then((res) => {
        toastr.success("", res.message);
        dispatch({ type: Actions.WITHDRAW_ERROR, withdrawError: "success" });
        dispatch(getUser());
        setTimeout(() => {
          dispatch({ type: Actions.WITHDRAW_ERROR, withdrawError: "" });
        }, 1000);
      })
      .catch((error) => {
        toastr.error("", error?.response?.data?.message);
        dispatch({
          type: Actions.WITHDRAW_ERROR,
          withdrawError: error?.response?.data?.message,
        });

        setTimeout(() => {
          dispatch({ type: Actions.WITHDRAW_ERROR, withdrawError: "" });
        }, 1000);
      });
  };
};

export const resetDepositAmount = () => {
  return (dispatch) => {
    dispatch({ type: Actions.RESET_DEPOSIT_AMOUNT });
  };
};

//Redeem Coupon
export const redeemCoupon = (tokenNo, passCode, phoneNo) => {
  return (dispatch) => {
    jwtService
      .redeemCoupon(tokenNo, passCode, phoneNo)
      .then((res) => {
        toastr.success("", res?.message);
        dispatch({ type: Actions.REDEEM_COUPON_ERROR, couponError: "success" });
        dispatch(getUser());
        setTimeout(() => {
          dispatch({ type: Actions.REDEEM_COUPON_ERROR, couponError: "" });
        }, 1000);
      })
      .catch((error) => {
        toastr.error(`${error?.response?.data?.message}`);
        dispatch({
          type: Actions.REDEEM_COUPON_ERROR,
          couponError: error?.response?.data?.message,
        });
        setTimeout(() => {
          dispatch({ type: Actions.REDEEM_COUPON_ERROR, couponError: "" });
        }, 1000);
      });
  };
};

// Set change password success
export const setChangePasswordSuccess = (value) => {
  return (dispatch) => {
    dispatch({ type: Actions.CHANGE_PASSWORD_SUCCESS, value });
  };
};

// Set change password error
export const setChangePasswordError = (error) => {
  return (dispatch) => {
    dispatch({ type: Actions.CHANGE_PASSWORD_ERROR, error });
  };
};

// Logout user
export const logoutUser = () => {
  return (dispatch) => {
    dispatch(trackUserActivity("logout"));
  };
};

// Set user object data
export const setUser = (user) => {
  return (dispatch) => {
    dispatch({ type: Actions.SET_USER, user });
  };
};

// Get user object data
export const getUser = () => {
  return (dispatch, getState) => {
    let language = getState().general.language;
    const agent = getState().general.slug;
    const uid = getState().user.pageData?.whitelabel_admin_uuid;
    jwtService.getUser(language, agent, uid).then((user) => {
      dispatch(setUser(user));
    });
  };
};

export const trackUserActivity = (status) => {
  return (dispatch) => {
    jwtService
      .trackUserActivity(status)
      .then((response) => {
        if (status === "logout") {
          dispatch({ type: Actions.LOGOUT_USER });
          setTimeout(() => {
            jwtService.logout();
          }, 3000);
        }
      })
      .catch((error) => {
        if (status === "logout") {
          dispatch({ type: Actions.LOGOUT_USER });
          setTimeout(() => {
            jwtService.logout();
          }, 3000);
        }
      });
  };
};

export const getBanners = (payload) => {
  return (dispatch) => {
    jwtService
      .getBanners(payload)
      .then((res) => {
        dispatch({
          type: Actions.GET_BANNER,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getCashbackData = () => {
  return (dispatch) => {
    const userId = localStorage.getItem("user_id");
    jwtService.getCashbackData(userId).then((response) => {
      dispatch(setCashback(response));
    });
  };
};

export const setCashback = (data) => {
  return (dispatch) => {
    dispatch({ type: Actions.SET_CASHBACK_DATA, data });
  };
};

export const getCashbackSucess = () => {
  return (dispatch) => {
    const userId = localStorage.getItem("user_id");
    jwtService
      .setCashback(parseInt(userId))
      .then((response) => {
        dispatch(setCashbackSucess(response));
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const setCashbackSucess = (data) => {
  return (dispatch) => {
    dispatch({ type: Actions.SET_CASHBACK_SUCESS, data });
    toastr.success("", data.message);
    dispatch(getUser());
  };
};

export const setOddType = (oddType) => {
  return (dispatch) => {
    dispatch({ type: Actions.SET_ODD_TYPE, oddType });
  };
};

export const saveEditData = (
  editUserDataSuccess,
  editUserDataError,
  editUserDataLoading
) => {
  return (dispatch) => {
    dispatch({
      type: Actions.EDIT_USER_DATA,
      editUserDataSuccess,
      editUserDataError,
      editUserDataLoading,
    });
  };
};

export const editUserData = (payload) => {
  return (dispatch, getState) => {
    dispatch(saveEditData(null, null, true));
    jwtService
      .editUserData(payload)
      .then((response) => {
        dispatch(getUser());
        dispatch(saveEditData(response?.message, null, false));
        toastr.success("", response?.message);
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          dispatch(saveEditData(null, error?.response?.data?.message, false));
          toastr.error("", error?.response?.data?.message);
        } else {
          dispatch(saveEditData(null, "editUserDataError", false));
          toastr.error("", "editUserDataError");
        }
      });
    //   })
    setTimeout(() => {
      dispatch(saveEditData(null, null, false));
    }, 5000);
  };
};

export const getEmailOtp = (username) => {
  return (dispatch) => {
    jwtService
      .sendEmailOtp(username)
      .then((data) => {
        toastr.success(data?.message);
        dispatch({ type: Actions.SEND_EMAIL_OTP, success: true });
      })
      .catch((err) => {
        toastr.error(err?.data?.error);
        dispatch({ type: Actions.SEND_EMAIL_OTP, success: false });
      });
  };
};

export const resetPassword = (uid, token, password1, password2) => {
  return (dispatch) => {
    jwtService
      .resetPassword(uid, token, password1, password2)
      .then((data) => {
        toastr.success(data?.message);
        dispatch({ type: Actions.SEND_EMAIL_OTP, success: true });
      })
      .catch((err) => {
        console.error(err);
        toastr.error(err?.response?.data?.error);
        dispatch({ type: Actions.SEND_EMAIL_OTP, success: false });
      });
  };
};

export const forgetPassword = (email) => {
  return (dispatch) => {
    jwtService
      .forgetPassword(email)
      .then((data) => {
        toastr.success(data?.message);
        dispatch({ type: Actions.FORGOT_PASSWORD_SUCCESS, success: true });
      })
      .catch((err) => {
        console.error(err);
        toastr.error(err?.data?.error);
        dispatch({ type: Actions.FORGOT_PASSWORD_SUCCESS, success: false });
      });
  };
};

export const submitQuery = (subject, query) => {
  return (dispatch) => {
    jwtService
      .submitQuery(subject, query)
      .then((data) => {
        toastr.success(data?.message);
      })
      .catch((err) => {
        console.error(err);
        toastr.error(err?.data?.error);
      });
  };
};

export const getActiveQueries = () => {
  return (dispatch) => {
    jwtService
      .getActiveQueries()
      .then((data) => {
        localStorage.setItem("Queries", JSON.stringify(data.queries));
        dispatch({ type: Actions.SET_USER_QUERY, payload: data?.queries });
      })
      .catch((err) => {
        console.error(err);
        toastr.error(err?.data?.error);
      });
  };
};

export const getAffiliatedPlayers = (username, setLoading) => {
  return (dispatch) => {
    jwtService
      .getAffiliatedPlayers(username)
      .then((data) => {
        dispatch({ type: Actions.SET_AFFILIATED_PLAYERS, players: data });
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };
};

export const showSignUpForm = () => {
  return (dispatch) => {
    dispatch({ type: Actions.SHOW_SIGNUP, value: true });
  };
};

export const hideSignUpForm = () => {
  return (dispatch) => {
    dispatch({ type: Actions.SHOW_SIGNUP, value: false });
  };
};

export const getMinimumDepositAmount = (currency) => {
  return (dispatch) => {
    jwtService
      .getMinimumAmount(currency)
      .then((data) => {
        dispatch({
          type: Actions.MIN_DEPOSIT_AMOUNT,
          amount: data.fiat_equivalent,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const getRecentWalletAddresses = (currency, setRecentAddress) => {
  return (dispatch) => {
    jwtService
      .getRecentWalletAddresses(currency, address_count)
      .then((res) => {
        setRecentAddress(res?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const elevateAffiliateRequest = (
  deposit_count,
  allDeposits,
  days,
  isLifetime,
  handleBack
) => {
  return (dispatch) => {
    jwtService
      .requestAffiliate(deposit_count, allDeposits, days, isLifetime)
      .then((res) => {
        toastr.success(res?.message);
        handleBack();
      })
      .catch((err) => {
        console.error(err);
        toastr.error("Affiliate request failed!!");
      });
  };
};

export const submitContactForm = (formData) => {
  return (dispatch) => {
    jwtService
      .submitContactForm(formData)
      .then((res) => {
        toastr.success(res?.message);
      })
      .catch((err) => {
        toastr.error(err?.response?.data?.error);
      });
  };
};

export const getDashBoardGames = (project_domain) => {
  return (dispatch) => {
    jwtService
      .getDashBoardGames(project_domain)
      .then((res) => {
        const uid = res?.data.whitelabel_admin_uuid;
        localStorage.setItem("whitelabel_admin_uuid", uid);
        dispatch({ type: Actions.SET_MAIN_DATA, data: res?.data });
        if (res?.data?.logo)
          document.getElementById("favicon").href = res?.data?.logo;
        if (res?.data?.project_name) {
          const titleName =
            res?.data?.project_name.charAt(0).toUpperCase() +
            res?.data?.project_name.slice(1);
          document.title = titleName;
        }
      })
      .catch((err) => {
        toastr.error(err?.response?.data?.error);
      });
  };
};

export const clearSlug = () => {
  return (dispatch) => {
    console.log("Clearing Slug...")
    dispatch({ type: Actions.CLEAR_SLUG });
  };
};

export const getChatRoomDetails = (type, page) => {
  return (dispatch, getState) => {
    const agent = getState().general.slug;
    const uid = getState().user.pageData?.whitelabel_admin_uuid;
    jwtService
      .getChatRoomDetails(type, page, agent, uid)
      .then((res) => {
        dispatch({
          type: Actions.SET_CHAT_CONNECTION_DETAILS,
          detailType: type,
          [chatRoomRequestTypes[type]]: res,
        });
      })
      .catch((err) => {
        toastr.error(err?.response?.data?.detail);
      });
  };
};

export const clearUnreadMsgCount = () => {
  return (dispatch, getState) => {
    const agent = getState().general.slug;
    const uid = getState().user.pageData?.whitelabel_admin_uuid;
    jwtService
      .clearUnreadMsgCount(agent, uid)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
