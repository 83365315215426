import { actions } from "react-redux-toastr";
import * as Actions from "../actions/actionTypes";

const initialState = {
  transactions: [],
  loadingTransactions: true,
  currentPage: 0,
  hasNextPage: false,
  filterParams: {},
  selectedTab: null,
  countBets: 0,
  totalResultCount: 0,
  fetchMore: true,
  getTranscationLoading: false,
};

const transactionReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_TRANSACTIONS: {
      let { currentPage, transactions, countBets } = state;

      if (action.transactions?.results?.length > 0) {
        let count = action.transactions.results.length + countBets;
        let fetchMore = true;
        if (count >= action.transactions.count) {
          fetchMore = false;
          count = 0;
        }
        if (action.firstPage) {
          return {
            ...state,
            transactions: action.transactions.results,
            loadingTransactions: false,
            currentPage: 1,
            countBets: count,
            fetchMore: fetchMore,
            totalResultCount: action.transactions.count,
          };
        } else {
          let page = currentPage + 1;
          let transactionsList = action.transactions.results;
          return {
            ...state,
            transactions: transactionsList,
            loadingTransactions: false,
            currentPage: page,
            countBets: count,
            fetchMore: fetchMore,
            totalResultCount: action.transactions.count,
          };
        }
      } else {
        return {
          ...state,
          transactions: [],
          loadingTransactions: false,
          currentPage: 0,
        };
      }
    }

    case Actions.SET_TRANSACTIONS_FILTER: {
      return {
        ...state,
        filterParams: action.params,
      };
    }

    case Actions.SET_TRANSACTIONS_ERROR: {
      return {
        ...state,
        transactions: [],
        loadingTransactions: false,
        currentPage: 0,
      };
    }

    case Actions.CLEAR_TRANSACTIONS: {
      return {
        ...state,
        transactions: [],
        loadingTransactions: true,
        currentPage: 0,
        filterParams: {},
      };
    }

    case Actions.SET_TRANSACTIONS_TAB: {
      return {
        ...state,
        selectedTab: actions.tab,
        currentpage: 0,
        transactions: [],
      };
    }
    case Actions.GET_TRANSACTIONS_START: {
      return {
        ...state,
        getTranscationLoading: true,
      };
    }
    case Actions.GET_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        getTranscationLoading: false,
      };
    }
    case Actions.GET_TRANSACTIONS_ERROR: {
      return {
        ...state,
        getTranscationLoading: false,
      };
    }

    default:
      return state;
  }
};

export default transactionReducer;
