import React from "react";
import { LoaderConainer } from "./style";
const Loader = () => {
  return (
    <LoaderConainer className="d-flex justify-content-center">
      <div className="spinner-border text-danger" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </LoaderConainer>
  );
};

export default Loader;
