/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Util from "../../helper/Util";
import { Translate } from "../../localization";
import MenuItem from "@material-ui/core/MenuItem";
import styled from "styled-components";
import * as Actions from "store/actions/actionTypes";
import {
  getChatRoomDetails,
  hideSignUpForm,
  logoutUser,
  showSignUpForm,
} from "store/actions/user.actions";
import {
  getDeviceLocation,
  setLanguage,
  getTenentStatus,
} from "../../store/actions/general.actions";

import Login from "../Login";
import SignUp from "../SignUp";
import MyAccount from "../MyAccount";
import { customCurrency } from "../../config";
import { HeaderContainer } from "./style";
import {
  DemoUserIcon,
  LogoutIcon,
  TriangleDownIcon,
  UserIcon,
  WalletFilledIcon,
  BalanceCoin,
  AffiliateIcon,
  DepositeIcon,
  WithdrawIcon,
  TransactionIcon,
  BonusGiftIcon,
  CrossIcon,
  InfoIcon
} from "icons/index";


let unreadMessagesInterval;
const options = ["en", "tr", "de", "es", "fr"];

const BalanceContainer = styled.div`
  background-color: #2f2f2f;
  border-radius: 8px;
  width: 100%;
  padding: 14px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2px 0;
`

const TopMessageBox = styled.div`
  width: 100vw;
  padding: 6px 40px;
  text-align: center;
  background: var(--yellow);
  color: var(--newPrimaryDark);
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  position: relative;
`

const CrossIconMsgBox = styled.span`
  width: 12px;
  height: 12px;
  display: flex;
  cursor: pointer;
  position: absolute;
  right: 24px;
  margin: auto 0px;
  top: 50%;
  transform: translateY(-50%);

  @media (max-width: 480px){
    right: 12px;
  }
`

const TooltipContainer = styled.span`
  width: 18px;
  height: 18px;
  display: inline-block;
  color: #ffffff85;
  position: relative;
  display: flex;
  margin-left: 2px;
`

const TooltipText = styled.span`
  visibility: hidden;
  width: 250px;
  background-color: #242424;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  font-family: "Jost", sans-serif;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -75px;
  z-index: 1;
  padding: 4px;

  ${TooltipContainer}:hover & {
    visibility: visible;
  }

  ::after{
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #242424 transparent;
  }

  @media (max-width: 768px) {
    bottom: -85px;
    left: auto;
    right: 0px;
    transform: translateX(0%);
  }
  `

class Header extends Component {
  constructor(props) {
    super(props);
    this.header = React.createRef();
    this.state = {
      anchorEl: null,
      selectedIndex: 0,
      showMyAccount: false,
      theme: "",
      showLogin: false,
      isScrolled: false,
      drawerOpen: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, false);

    const {
      showSignUpForm,
      language,
      getDeviceLocation,
      isLoggedIn,
      userData,
    } = this.props;
    if (window.location.href.includes("affiliate-invite")) {
      showSignUpForm();
    }
    let userSelectedLang = language;
    let userSelectedLangIndex = options.indexOf(userSelectedLang);
    getDeviceLocation();
    if (userSelectedLangIndex !== this.state.selectedIndex) {
      Translate.setLanguage(userSelectedLang);
      this.setState({
        selectedIndex: userSelectedLangIndex,
      });
    }

    window.addEventListener("scroll", this.toggleHeader, false);
    if (!localStorage.getItem("theme")) {
      document.body.classList.remove("dark-mode");
      localStorage.setItem("theme", "light-mode");
      this.setState({ theme: "light-mode" });
    } else if (
      localStorage.getItem("theme") &&
      localStorage.getItem("theme") === "dark-mode"
    ) {
      document.body.classList.add("dark-mode");
      this.setState({ theme: "dark-mode" });
    }
    if(this.props.isHomeRoute) this.messageInterval();
  }

  componentDidUpdate(prevProps) {
    const { country, isChatConnected, isHomeRoute } = this.props;
    if (
      prevProps.country !== country &&
      country === "NL" &&
      !localStorage.getItem("language")
    ) {
      Translate.setLanguage("nl");
      let userSelectedLangIndex = options.indexOf("nl");
      this.changeLanguage(null, userSelectedLangIndex);
      this.setState({
        selectedIndex: userSelectedLangIndex,
      });
    }
    if ((prevProps.isChatConnected !== isChatConnected) || (prevProps.isHomeRoute !== isHomeRoute)) {
      if (!unreadMessagesInterval) {
        this.messageInterval();
      } else {
        clearInterval(unreadMessagesInterval);
        unreadMessagesInterval = null;
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll, false);

    if (this.header.current)
      this.header.current.classList.remove("header_dark");
    const lastUrl = localStorage.getItem("lastUrl");
    localStorage.setItem("lastUrl", this.props.location.pathname);
    localStorage.setItem("secondLastUrl", lastUrl);
    window.removeEventListener("scroll", this.toggleHeader, false);
    localStorage.setItem("LastUrl", this.props.location.pathname);
    clearInterval(unreadMessagesInterval);
  }

  handleScroll() {
    const scrollTop = window.scrollY;
    if (scrollTop > 0 && !this.state.isScrolled) {
      this.setState({ isScrolled: true });
    } else if (scrollTop === 0 && this.state.isScrolled) {
      this.setState({ isScrolled: false });
    }
  }

  toggleHeader = () => {
    if (this.props.headerClassname === "header_transparent") {
      if (window.pageYOffset > this.header.current.clientHeight) {
        this.header.current.classList.add("header_dark");
      } else {
        this.header.current.classList.remove("header_dark");
      }
    }
  };

  messageInterval = () => {
    const { isLoggedIn, getChatRoomDetails } = this.props;
    if (isLoggedIn) {
      unreadMessagesInterval = setInterval(() => {
        getChatRoomDetails();
      }, 3000);
    }
  };

  handleClickMenu = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  changeLanguage = (event, index) => {
    let language = options[index];
    Translate.setLanguage(language);
    this.props.setLanguage(Translate.getLanguage());
    localStorage.setItem("language", language);

    this.setState({
      anchorEl: null,
      selectedIndex: index,
    });
  };

  changeLanguageMobile = (event) => {
    let index = parseInt(event.target.value);
    let language = options[index];
    Translate.setLanguage(language);
    this.props.setLanguage(Translate.getLanguage());
    localStorage.setItem("language", language);

    this.setState({
      selectedIndex: index,
    });
  };

  handleCloseMenu = () => {
    this.setState({
      anchorEl: null,
    });
  };

  isLoggedIn = () => {
    const { access_token } = Util.getAccessToken();
    const isUserLoggedIn = Util.isAuthTokenValid(access_token);
    return isUserLoggedIn;
  };

  handleToggle = () => {
    if (
      localStorage.getItem("theme") &&
      localStorage.getItem("theme") === "dark-mode"
    ) {
      localStorage.setItem("theme", "light-mode");
      document.body.classList.remove("dark-mode");
      this.setState({ theme: "light-mode" });
    } else if (
      localStorage.getItem("theme") &&
      localStorage.getItem("theme") === "light-mode"
    ) {
      localStorage.setItem("theme", "dark-mode");
      document.body.classList.add("dark-mode");
      this.setState({ theme: "dark-mode" });
    } else {
      localStorage.setItem("theme", "light-mode");
      document.body.classList.remove("dark-mode");
      this.setState({ theme: "light-mode" });
    }
  };

  logOutClick = () => {
    const { logoutUserRequest, history } = this.props;
    logoutUserRequest();
    const checkForAgent = Util.checkForSlug("/off-market");
    history.push(checkForAgent);
  };
  redirectUrl = (urlValue) => {
    const { history } = this.props;
    const checkForAgent = Util.checkForSlug(urlValue);
    if (this.props.isDrawerOpen) this.props.toogleDrawerSet();
    history.push(checkForAgent);
  };

  onShowLogin = () => {
    this.setState({ showLogin: true });
  };

  onHideLogin = () => {
    this.setState({ showLogin: false });
  };
  toggleDrawer = (e) => {
    const { toogleDrawerSet } = this.props;
    toogleDrawerSet();
  };

  handleLogoRedirection = () => {
    const checkForAgent = Util.checkForSlug("/off-market");
    const { history } = this.props;
    history.push(checkForAgent);
  };




  drawerToggleClickHandler = () => {
    this.setState({
      drawerOpen: !this.state.drawerOpen
    })
  }

  backdropClickHandler = () => {
    this.setState({
      drawerOpen: false
    })
  }

  render() {
    let { anchorEl, selectedIndex, showLogin } = this.state;
    let {
      userData,
      pageData,
      isLoggedIn,
      isDrawerOpen,
      showSignUp,
      showSignUpForm,
      hideSignUpForm,
      toggleTopMessageBox,
      isTopMessageBoxOpen,
      footerData
    } = this.props;
    let currency = customCurrency;
    if(userData && userData.main_amount){
      localStorage.setItem('mainAmount',userData.main_amount);
    }else{
      localStorage.removeItem('mainAmount');
    }
    const languageList = options.map((option, index) => (
      <MenuItem
        key={option}
        selected={index === selectedIndex}
        onClick={(event) => this.changeLanguage(event, index)}
        className="text-uppercase"
      >
        {option}
      </MenuItem>
    ));
    let liveCasinoClass = "";
    let casinoClass = "";
    if (userData) {
      liveCasinoClass = userData.is_live_casino_enabled ? "" : "disabled";
      casinoClass = userData.is_casino_enabled ? "" : "disabled";
    }
    let extraMarketPre =
      this.props.location.pathname?.includes("/extra-market");
    let extraMarketLive = this.props.location.pathname?.includes(
      "live-bettingextra-market"
    );

    const { isScrolled } = this.state;
    const navbarStyle = {
      backgroundColor: isScrolled ? 'black' : 'transparent',
      transition: 'background-color 0.4s ease',
    };

    let drawerClasses = 'side-drawer tablet-hidden desktop-hidden'
    if(this.state.drawerOpen) {
       drawerClasses = 'side-drawer tablet-hidden desktop-hidden open'
    }

    let backdrop;
    if(this.state.drawerOpen){
      backdrop = <div onClick={this.backdropClickHandler} className="backdrop tablet-hidden desktop-hidden" />;
      }
    
    return (
      <HeaderContainer>


        <header
          className={`bg-black ${
            !isDrawerOpen ? "sidebar-close" : ""
          }`}
          style={navbarStyle}
          >

            {isTopMessageBoxOpen &&
            <TopMessageBox>
                {Translate.betaVersionMessage}
                <a
                    href={`https://t.me/${footerData?.telegram}`}
                    target="_blank"
                    style={{  color: "var(--newPrimaryDark)", fontSize: "13px", fontWeight: "600"}}
                  >
                    &nbsp;{footerData?.telegram}
                  </a>
                <CrossIconMsgBox>
                  <CrossIcon onClick={toggleTopMessageBox}/>
                </CrossIconMsgBox>
              </TopMessageBox>
            }
          
          <div className="d-flex align-items-center justify-content-center main-nav-wrapper">
            <div className="header-wrap">
              <div className="d-flex align-items-center">
                <a
                  className="brand-logo header-logo"
                  onClick={this.handleLogoRedirection}
                >
                  <img
                    src={pageData?.logo?.substring(0, pageData?.logo?.length)}
                    className="img-fluid d-block mx-auto"
                    alt="Brand-logo"
                  />
                </a>

                <span className="ms-4 d-none d-sm-block">
                  <p className="tiny">Presents</p>
                  <h3 className="animate-charcter mt-1">{localStorage.getItem("slug")}</h3>
                </span>
              </div>


              {/* Navigation menu list from here */}
              <div className="header-navigation-box">
                <div
                  className="mob-promotion-wrap"
                  onClick={() => this.redirectUrl("/promotion")}
                >
                  <img
                    src="/assets/img/promotion-icon.png"
                    alt="Promotion icon"
                  />
                </div>

                <a
                  className="btn btn-secondary web-btn me-2"
                  onClick={() => this.redirectUrl("/promotion")}
                >
                  <img
                    src="/assets/img/promotion-icon.png"
                    alt="Promotion icon"
                    style={{ marginRight: "8px" }}
                  />
                  <span className="promotion-heading">{Translate.promotion}</span>
                </a>



                {isLoggedIn ? (
                  <>
                    {/* balance details button and dropdown */}
                    <div className="dropdown balance-dropdown mobile-hidden">
                      
                      {/* balance button */}
                      <button
                        className="balance-btn"
                        type="button"
                        id="balance_btn"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span style={{width: 24, height:24}}><BalanceCoin/></span>
                        <span className="text">
                          {userData
                            ? userData.credit
                              ? `${currency} ${Util.toFixedDecimal(
                                  userData.main_amount
                                )} `
                              : 0
                            : `${currency} Loading...`}
                        </span>
                      </button>

                      {/* dropdown for main balance and bonus balance  */}
                      <ul
                        className="dropdown-menu dropdown-menu-end"
                        aria-labelledby="balance_btn"
                        style={{minWidth: '145px'}}
                        >
                        <li className="balance-userId-section" style={{  borderBottom: "1px solid rgba(255, 255, 255, 0.15)"}}>
                          <p className="tiny"><span style={{width: 16, height:16, display: "inline-block", marginRight: "6px", marginBottom: "6px"}}><BalanceCoin/></span>{Translate.MainBalance}</p>
                          <h3>
                            {userData
                              ? userData.main_amount
                                ? `${currency} ${Util.toFixedDecimal(
                                    userData.main_amount
                                  )} `
                                : `${currency} 0`
                              : `${currency} Loading...`}
                          </h3>
                        </li>
                        <li className="balance-userId-section">
                          <p className="tiny"><span style={{width: 15, height:15, display: "inline-block", marginRight: "6px", marginBottom: "6px"}}><BonusGiftIcon/></span>{Translate.bonusEarned}</p>
                          <h3 style={{display: 'flex', justifyContent: "space-between"}}>
                            {userData
                              ? userData.game_bonus
                                ? `${currency} ${Util.toFixedDecimal(
                                    userData.game_bonus
                                  )} `
                                : `${currency} 0`
                              : `${currency} Loading...`}

                              <TooltipContainer>
                                <InfoIcon/>
                                <TooltipText>This is the total bonus you've collected so far. This bonus value was applied to your game credit automatically along with the deposit.</TooltipText>
                              </TooltipContainer>
                          </h3>
                        </li>
                      </ul>
                    </div>



                    {/* Profile button */}
                    <div className="dropdown profile-dropdown">
                      <button
                        className="profile-btn"
                        onClick={this.drawerToggleClickHandler}
                        type="button"
                        id="account_btn"
                        data-bs-toggle="dropdown"
                        
                        aria-expanded="false"
                      >
                        <span className="icon">
                          <DemoUserIcon />
                        </span>{" "}
                        {/* <span className="toggle-icon">
                          <TriangleDownIcon />
                        </span> */}
                      </button>


                      {/* menu list/Flyout drawer for mobile devices */}
                      <div className={drawerClasses}>
                        <div>
                          <div className="d-flex align-items-center justify-content-between">
                            <a
                              className="brand-logo header-logo"
                              onClick={this.handleLogoRedirection}
                            >
                              <img
                                src={pageData?.logo?.substring(0, pageData?.logo?.length)}
                                style={{ height: "45px", cursor: "pointer" }}
                                className="img-fluid d-block mx-auto"
                                alt="Brand-logo"
                              />
                            </a>

                            <span style={{width: 16, height: 16, display: "inline-block", cursor: "pointer", color: "#6D6D6D"}}><CrossIcon onClick={this.backdropClickHandler}/></span>
                          </div>

                          <div style={{marginTop:"32px"}}>
                            <p className="tiny">
                                {userData
                                  ? `${Translate.username}`
                                  : `${customCurrency} Loading...`}
                              </p>
                              <h3>{userData?.username}</h3>
                          </div>

                          <div style={{marginTop:"24px"}}>
                            <BalanceContainer>
                              <p className="tiny">
                              <span style={{width: 16, height:16, display: "inline-block", marginRight: "6px"}}><BalanceCoin/></span>{Translate.MainBalance}
                              </p>

                              <h3>
                              {userData
                                ? userData.main_amount
                                  ? `${currency} ${Util.toFixedDecimal(
                                      userData.main_amount
                                    )} `
                                  : `${currency} 0`
                                : `${currency} Loading...`}
                            </h3>
                            </BalanceContainer>
                            <BalanceContainer>
                              <p className="tiny">
                              <span style={{width: 16, height:16, display: "inline-block", marginRight: "6px"}}><BonusGiftIcon/></span>{Translate.bonusEarned}
                              </p>

                              <h3 style={{display: 'flex', justifyContent: "space-between", alignItems: "center"}}>
                            {userData
                              ? userData.game_bonus
                                ? `${currency} ${Util.toFixedDecimal(
                                    userData.game_bonus
                                  )} `
                                : `${currency} 0`
                              : `${currency} Loading...`}

                              <TooltipContainer>
                                <InfoIcon/>
                                <TooltipText>This is the total bonus you've collected so far. This bonus value was applied to your game credit automatically along with the deposit.</TooltipText>
                              </TooltipContainer>
                          </h3>
                            </BalanceContainer>
                          </div>

                          <ul style={{marginTop: "28px"}}>
                          <li>
                            <a
                              className={`dropdown-item mbl ${
                                window.location.pathname ===
                                Util.checkForSlug("/my-account")
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => this.redirectUrl("/my-account")}
                            >
                              <span className="icon">
                                <UserIcon />
                              </span>
                              <span className="text">{Translate.myProfile}</span>
                            </a>
                          </li>
                          {userData?.affiliate_link && (
                            <li>
                              <a
                                className={`dropdown-item mbl ${
                                  window.location.pathname ===
                                  Util.checkForSlug("/affiliates")
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => this.redirectUrl("/affiliates")}
                              >
                                <span className="icon">
                                  <AffiliateIcon/>
                                </span>
                                <span className="text">
                                  {Translate.myAffiliates}
                                </span>
                              </a>
                            </li>
                          )}
                          <li>
                            <a
                              className={`dropdown-item mbl ${
                                window.location.pathname ===
                                Util.checkForSlug("/deposit")
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => this.redirectUrl("/deposit")}
                            >
                              <span className="icon">
                                {/* <img src="/img1/wallet-D-grey.webp" alt="Wallet" /> */}
                                {/* <img src="/img1/wallet-D.webp" alt="Wallet" /> */}
                                <DepositeIcon/>
                              </span>
                              <span className="text">{Translate.deposit}</span>
                            </a>
                          </li>
                          <li>
                            <a
                              className={`dropdown-item mbl ${
                                window.location.pathname ===
                                Util.checkForSlug("/withdrawl")
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => this.redirectUrl("/withdrawl")}
                            >
                              <span className="icon">
                                {/* <img
                                  src="/assets/icons/dollar-grey-icon.svg"
                                  alt="dollar"
                                />
                                <img
                                  src="/assets/icons/dollar-icon.svg"
                                  alt="dollar"
                                /> */}
                                <WithdrawIcon/>
                              </span>
                              <span className="text">{Translate.withdraw}</span>
                            </a>
                          </li>
                          <li>
                            <a
                              className={`dropdown-item mbl ${
                                window.location.pathname ===
                                Util.checkForSlug("/my-account/transactions")
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                this.redirectUrl("/my-account/transactions")
                              }
                            >
                              <span className="icon">
                                {/* <img
                                  src="/img1/Transaction-grey.webp"
                                  alt="Wallet"
                                />
                                <img src="/img1/Transaction.webp" alt="Wallet" /> */}
                                <TransactionIcon/>
                              </span>
                              <span className="text">{Translate.transaction}</span>
                            </a>
                          </li>
                          </ul>
                        </div>


                        <div>
                            <a className="dropdown-item mbl" onClick={this.logOutClick}>
                              <span className="icon">
                                <LogoutIcon />
                              </span>
                              <span className="text">{Translate.logout}</span>
                            </a>
                        </div>
                      </div>
                      {backdrop}


                      {/* menu list dropdown for desktop devices */}
                      <ul
                        className="mobile-hidden dropdown-menu dropdown-menu-end"
                        aria-labelledby="account_btn"
                        >
                        <li className="profile-userId-section">
                          <p className="tiny">
                              {userData
                                ? `${Translate.username}`
                                : `${customCurrency} Loading...`}
                            </p>
                            <h3>{userData?.username}</h3>
                        </li>
                        {userData && (
                          <li className="show_user_details">
                            <div className="dropdown-item user-details">
                              <span className="btn-icon">
                                <WalletFilledIcon
                                  style={{
                                    color: "#000",
                                    height: "15px",
                                    width: "15px",
                                  }}
                                />
                              </span>{" "}
                              <span className="credit-balance">
                                {userData
                                  ? `${customCurrency} ${Util.toFixedDecimal(
                                      userData.credit
                                    )}`
                                  : `${customCurrency} Loading...`}
                              </span>
                            </div>
                          </li>
                        )}
                        <li>
                          <a
                            className={`dropdown-item ${
                              window.location.pathname ===
                              Util.checkForSlug("/my-account")
                                ? "active"
                                : ""
                            }`}
                            onClick={() => this.redirectUrl("/my-account")}
                          >
                            <span className="icon">
                              <UserIcon />
                            </span>
                            <span className="text">{Translate.myProfile}</span>
                          </a>
                        </li>
                        {userData?.affiliate_link && (
                          <li>
                            <a
                              className={`dropdown-item ${
                                window.location.pathname ===
                                Util.checkForSlug("/affiliates")
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => this.redirectUrl("/affiliates")}
                            >
                              <span className="icon">
                                <AffiliateIcon/>
                              </span>
                              <span className="text">
                                {Translate.myAffiliates}
                              </span>
                            </a>
                          </li>
                        )}
                        <li>
                          <a
                            className={`dropdown-item ${
                              window.location.pathname ===
                              Util.checkForSlug("/deposit")
                                ? "active"
                                : ""
                            }`}
                            onClick={() => this.redirectUrl("/deposit")}
                          >
                            <span className="icon">
                              {/* <img src="/img1/wallet-D-grey.webp" alt="Wallet" /> */}
                              {/* <img src="/img1/wallet-D.webp" alt="Wallet" /> */}
                              <DepositeIcon/>
                            </span>
                            <span className="text">{Translate.deposit}</span>
                          </a>
                        </li>
                        <li>
                          <a
                            className={`dropdown-item ${
                              window.location.pathname ===
                              Util.checkForSlug("/withdrawl")
                                ? "active"
                                : ""
                            }`}
                            onClick={() => this.redirectUrl("/withdrawl")}
                          >
                            <span className="icon">
                              {/* <img
                                src="/assets/icons/dollar-grey-icon.svg"
                                alt="dollar"
                              />
                              <img
                                src="/assets/icons/dollar-icon.svg"
                                alt="dollar"
                              /> */}
                              <WithdrawIcon/>
                            </span>
                            <span className="text">{Translate.withdraw}</span>
                          </a>
                        </li>
                        <li>
                          <a
                            className={`dropdown-item ${
                              window.location.pathname ===
                              Util.checkForSlug("/my-account/transactions")
                                ? "active"
                                : ""
                            }`}
                            onClick={() =>
                              this.redirectUrl("/my-account/transactions")
                            }
                          >
                            <span className="icon">
                              {/* <img
                                src="/img1/Transaction-grey.webp"
                                alt="Wallet"
                              />
                              <img src="/img1/Transaction.webp" alt="Wallet" /> */}
                              <TransactionIcon/>
                            </span>
                            <span className="text">{Translate.transaction}</span>
                          </a>
                        </li>

                        <li>
                          <a className="dropdown-item" onClick={this.logOutClick}>
                            <span className="icon">
                              <LogoutIcon />
                            </span>
                            <span className="text">{Translate.logout}</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="auth-btn-box">
                      <button className="btn auth-btn" onClick={showSignUpForm}>
                        {Translate.signup}
                      </button>
                      <button
                        className="btn auth-btn gradient-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#loginModal"
                        onClick={this.onShowLogin}
                      >
                        {Translate.login}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </header>
        {showSignUp && <SignUp hideSignUp={hideSignUpForm} />}
        {showLogin && <Login closeLogin={this.onHideLogin} />}
      </HeaderContainer>
    );
  }
}

Header.propTypes = {
  userData: PropTypes.object,
  setLanguage: PropTypes.func,
  language: PropTypes.string,
  headerClassname: PropTypes.string,

  footerData: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    userData: state.user.data,
    isLoggedIn: state.user.isLoggedIn,
    language: state.general.language,
    country: state.general.country,
    isShowCasino: false, //state.general.isShowCasino,
    isDrawerOpen: state.user.isDrawerOpen,
    showSignUp: state.user.showSignUp,
    pageData: state.user.pageData,
    isChatConnected: state.user.isChatConnected,
    isHomeRoute: state.user.isHomeRoute,
    footerData: state.general.footerData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLanguage: (value) => dispatch(setLanguage(value)),
    getDeviceLocation: () => dispatch(getDeviceLocation()),
    getTenentStatus: () => dispatch(getTenentStatus()),
    logoutUserRequest: () => dispatch(logoutUser()),
    toogleDrawerSet: () => dispatch({ type: Actions.DRAWER_SET }),
    showSignUpForm: () => dispatch(showSignUpForm()),
    hideSignUpForm: () => dispatch(hideSignUpForm()),
    getChatRoomDetails: () => dispatch(getChatRoomDetails("message_count")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
