import styled from "styled-components";

export const StaticPageContainer = styled.div`
  min-height: 300px;
  position: relative;

  .noData {
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-size: 48px !important;
      font-weight: bold;
      color: #fff;
      text-transform: capitalize;
      @media only screen and (max-width: 767px) {
        font-size: 26px !important;
      }
    }
  }

  .static-page-section {
    width: 100%;
    min-height: 100dvh;
    background: url("/assets/landing/landing-bg-img.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    padding: 9.25rem 1.25rem 3.75rem;
    margin: 0 !important;

    .static-page-wrap {
      max-width: 98.313rem;
      background-color: var(--Hamburger-BG);
      border-radius: 1rem;
      padding: 3.75rem 4.125rem;
      margin-left: auto;
      margin-right: auto;
      min-height: 500px;

      .card-container {
        width: 100%;
        max-width: unset;
        margin: 0 auto 0;
      }

      .header-container {
        padding: 0 1.25rem;
      }

      .header-container .title-text {
        background: linear-gradient(
          90deg,
          var(--Gradient-1) 0%,
          var(--Gradient-2) 100%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: "Jost", sans-serif;
        font-size: 2.5rem;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
        text-align: center;
        margin-bottom: 0.625rem;
      }

      .header-container .subtitle-text {
        font-family: "Jost", sans-serif;
        color: var(--White);
        font-size: 1.125rem;
        font-weight: 400;
        line-height: normal;
        text-align: center;
      }

      .body-container {
        padding: 0 1.25rem;

        .text-box {
          padding: 0;
          P {
            color: #fff;
            font-size: 0.875rem;
          }
        }
      }
    }
  }
  @media screen and (max-width: 991.98px) {
    .static-page-wrap {
      padding: 3.75rem 1rem !important;
    }
  }
`;
