import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { renderTextField } from "../../utils/formUtils";
import { simpleLoginValidation as validate } from "utils/validate";
import ButtonLoader from "shared/Loader/buttonLoader";
import { Translate } from "localization/index";
import Util from "helper/Util";
import { useDispatch, useSelector } from "react-redux";
import {
  getFooterData,
} from "store/actions/general.actions";


const LoginForm = (props) => {
  const {
    closeLogin,
    handleSubmit,
    submitLogin,
    history,
    isLoading,
    showForgotPass,
  } = props;


  const { slug, footerData } = useSelector((state) => state.general);
  const agent = localStorage.getItem("slug");
  const dispatch = useDispatch();

  const toggleSuccess = () => {
    const checkSlug = Util.checkForSlug("/off-market");
    closeLogin();
    history.push(checkSlug);
  };
  const simpleLoginPageSubmit = (formData) => {
    submitLogin(
      formData.username,
      formData.password,
      toggleSuccess,
      slug || agent
    );
  };


  useEffect(() => {
    if (!Object.values(footerData)?.length) dispatch(getFooterData());
  }, [dispatch]);

  return (
    <React.Fragment>
      <div
        className="modal login-signup-modal fade show"
        id="modal-display"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={closeLogin}
                aria-label="Close"
              >
                <img
                  src="/assets/img/close-icon.svg"
                  className="img-fluid"
                  alt="CloseImage"
                />
              </button>
              <h1 className="head-title-text">{Translate.login}</h1>
            </div>
            <div className="modal-body">
              <form
                className="form-box"
                onSubmit={handleSubmit((formData) =>
                  simpleLoginPageSubmit(formData)
                )}
              >
                <div className="row">
                  <div className="col-md-12 mx-auto">
                    <div className="input-box">
                      <Field
                        className="form-control"
                        name="username"
                        type="text"
                        label={Translate.username}
                        component={renderTextField}
                        placeholder={Translate.username}
                        maxLength={25}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 mx-auto">
                    <div className="input-box">
                      <Field
                        className="form-control"
                        name="password"
                        type="password"
                        label={Translate.password}
                        component={renderTextField}
                        placeholder={Translate.password}
                        maxLength={25}
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-12 mx-auto">
                    <div className="btn-box">
                      <button
                        type="submit"
                        disabled={isLoading}
                        className="gradient-btn w-100"
                      >
                        {" "}
                        {isLoading ? (
                          <ButtonLoader />
                        ) : (
                          `${Translate.login}`
                        )}{" "}
                      </button>
                    </div>
                  </div>
                  <div className="col-md-12 mx-auto">
                    <div className="forgot-password">
                      <span onClick={showForgotPass}>
                        {" "}
                        {`${Translate.forgotPassword}`}{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <div className="text-box">
                <p className="text">
                  Having issues logging in? <br /> Contact{" "}
                  <a href={`mailto:${footerData?.contact}`}>{footerData?.contact}</a> from <br />
                  your registered email for assistance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default reduxForm({
  form: "login-form",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: false,
  validate,
})(LoginForm);
