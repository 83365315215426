import React, { useEffect, useState } from "react";
import AddTipComment from "./AddTipComment";
import { useDispatch, useSelector } from "react-redux";

const EndChatDialog = ({
  tipAmount,
  setInputValue,
  sendTipCommentMessage,
  setHideSendMessage,
  showTipFeedback,
  setShowTip,
}) => {
  const [isTip, setIsTip] = useState(false);
  const [isComment, setIsComment] = useState(false);
  const noAvailableStaff = useSelector((state) => state.user.noAvailableStaff);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      setShowTip(false);
    };
  }, [setShowTip]);

  return (
    <div className={`tip-wrap ${noAvailableStaff ? "feedback-close" : ""}`}>
      {showTipFeedback && (
        <AddTipComment
          tipAmount={tipAmount}
          setInputValue={setInputValue}
          sendTipCommentMessage={sendTipCommentMessage}
          setHideSendMessage={setHideSendMessage}
          isTip={isTip}
          isComment={isComment}
          setShowTip={setShowTip}
        />
      )}
    </div>
  );
};

export default EndChatDialog;
