import React, {useState} from "react";
import { Route } from "react-router-dom";
// import Header from '../Components/Common/Header';
import DesktopHeader from "../Desktop/Common/Header";
// import Navigator from '../Components/Common/Navigator';
import Footer from "../Desktop/Common/Footer";
// import ChatModule from "Desktop/HelpSection/ChatModule";
import { useSelector } from "react-redux";
function PublicRoute({ component: Component, ...rest }) {
  let isAdminUser = JSON.parse(sessionStorage.getItem("isAdminUser"));
  let isComingSoon = JSON.parse(sessionStorage.getItem("fromComingSoon"));
  let isComingSoonEnabled = JSON.parse(
    sessionStorage.getItem("comingSoonEnabled")
  );
  let authed =
    localStorage.getItem("jwt_access_token") ||
    sessionStorage.getItem("jwt_access_token")
      ? true
      : false;
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  function promptUser() {
    if (!isComingSoonEnabled) window.location.pathname = "/";
    if (!isComingSoon) {
      let secretKey = window.prompt("Enter secret key", "");
      if (secretKey === "area51@2023!" || secretKey === "area51@2023!") {
        sessionStorage.setItem("isAdminUser", true);
        window.location.reload();
      } else {
        sessionStorage.setItem("isAdminUser", false);
        window.location.pathname = "/comingsoon";
      }
    } else {
      sessionStorage.setItem("fromComingSoon", false);
      window.location.pathname = "/comingsoon";
    }
  }

  const [isTopMessageBoxOpen, setIsTopMessageBoxOpen] = useState(true);

  const handleToggleTopMessageBox = () => {
    setIsTopMessageBoxOpen(false);
  };

  const component = (props) => (
    <>
      <DesktopHeader {...props} toggleTopMessageBox={handleToggleTopMessageBox} isTopMessageBoxOpen={isTopMessageBoxOpen}/>

        <div id={`${isTopMessageBoxOpen ? "tempPadding" : ""}`} className="main-wrap" style={{ minHeight: "calc(100vh - 250px)"}}>
          {/*offmarket footer height fix*/}
          <Component {...props} extraMarketChild={rest.extraMarketChild} />
          {/* {(authed || isLoggedIn) && <ChatModule />} */}
        </div>

      <Footer {...props} />
    </>
  );

  if (isAdminUser || !isComingSoonEnabled)
    return <Route {...rest} component={component} />;
  else promptUser();
}

export default PublicRoute;
